/* tslint:disable */
/* @relayHash 35523b668f0fb34578edc42eaa5a6f4c */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type organization_PopularOrganizations_QueryVariables = {
    first: number;
    search?: string | null;
};
export type organization_PopularOrganizations_QueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"PopularOrganizations_query">;
};
export type organization_PopularOrganizations_Query = {
    readonly response: organization_PopularOrganizations_QueryResponse;
    readonly variables: organization_PopularOrganizations_QueryVariables;
};



/*
query organization_PopularOrganizations_Query(
  $first: Int!
  $search: String
) {
  ...PopularOrganizations_query_1UbRgV
}

fragment OrganizationButton_me on User {
  id
}

fragment OrganizationButton_organization on Organization {
  id
  myRoles
  name
}

fragment OrganizationCard_me on User {
  id
  ...OrganizationButton_me
}

fragment OrganizationCard_organization on Organization {
  id
  slug
  name
  ...OrganizationName_organization
  myRoles
  coverImages {
    url
    id
  }
  profileImages {
    url
    id
  }
  ...OrganizationButton_organization
}

fragment OrganizationName_organization on Organization {
  id
  name
  isVerified
}

fragment PopularOrganizations_query_1UbRgV on Query {
  featuredOrganizations(first: $first, search: $search) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      node {
        id
        slug
        ...OrganizationCard_organization
        __typename
      }
      cursor
    }
  }
  organizations(first: $first, search: $search) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      node {
        id
        slug
        ...OrganizationCard_organization
        __typename
      }
      cursor
    }
  }
  me {
    ...OrganizationCard_me
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "search",
    "type": "String",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "search",
    "variableName": "search"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "url",
    "args": null,
    "storageKey": null
  },
  (v2/*: any*/)
],
v4 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "pageInfo",
    "storageKey": null,
    "args": null,
    "concreteType": "PageInfoExtended",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "hasNextPage",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "endCursor",
        "args": null,
        "storageKey": null
      }
    ]
  },
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "edges",
    "storageKey": null,
    "args": null,
    "concreteType": "OrganizationEdge",
    "plural": true,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "node",
        "storageKey": null,
        "args": null,
        "concreteType": "Organization",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "slug",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "name",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "isVerified",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "myRoles",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "coverImages",
            "storageKey": null,
            "args": null,
            "concreteType": "File",
            "plural": false,
            "selections": (v3/*: any*/)
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "profileImages",
            "storageKey": null,
            "args": null,
            "concreteType": "File",
            "plural": false,
            "selections": (v3/*: any*/)
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "__typename",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "cursor",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "organization_PopularOrganizations_Query",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "PopularOrganizations_query",
        "args": (v1/*: any*/)
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "organization_PopularOrganizations_Query",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "featuredOrganizations",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "OrganizationConnection",
        "plural": false,
        "selections": (v4/*: any*/)
      },
      {
        "kind": "LinkedHandle",
        "alias": null,
        "name": "featuredOrganizations",
        "args": (v1/*: any*/),
        "handle": "connection",
        "key": "PopularOrganizations_featuredOrganizations",
        "filters": []
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "organizations",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "OrganizationConnection",
        "plural": false,
        "selections": (v4/*: any*/)
      },
      {
        "kind": "LinkedHandle",
        "alias": null,
        "name": "organizations",
        "args": (v1/*: any*/),
        "handle": "connection",
        "key": "PopularOrganizations_organizations",
        "filters": []
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "organization_PopularOrganizations_Query",
    "id": null,
    "text": "query organization_PopularOrganizations_Query(\n  $first: Int!\n  $search: String\n) {\n  ...PopularOrganizations_query_1UbRgV\n}\n\nfragment OrganizationButton_me on User {\n  id\n}\n\nfragment OrganizationButton_organization on Organization {\n  id\n  myRoles\n  name\n}\n\nfragment OrganizationCard_me on User {\n  id\n  ...OrganizationButton_me\n}\n\nfragment OrganizationCard_organization on Organization {\n  id\n  slug\n  name\n  ...OrganizationName_organization\n  myRoles\n  coverImages {\n    url\n    id\n  }\n  profileImages {\n    url\n    id\n  }\n  ...OrganizationButton_organization\n}\n\nfragment OrganizationName_organization on Organization {\n  id\n  name\n  isVerified\n}\n\nfragment PopularOrganizations_query_1UbRgV on Query {\n  featuredOrganizations(first: $first, search: $search) {\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n    edges {\n      node {\n        id\n        slug\n        ...OrganizationCard_organization\n        __typename\n      }\n      cursor\n    }\n  }\n  organizations(first: $first, search: $search) {\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n    edges {\n      node {\n        id\n        slug\n        ...OrganizationCard_organization\n        __typename\n      }\n      cursor\n    }\n  }\n  me {\n    ...OrganizationCard_me\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '684aa4fb30c23a7bdae834dcae2e25bf';
export default node;
