import React from 'react';
import { Tooltip as AntdTooltip } from 'antd';

import questionIcon from '../../assets/question-circle.svg';

type TooltipProps = {
  title: string;
} & React.HTMLAttributes<HTMLElement>;

const Tooltip: React.FC<TooltipProps> = ({ title, ...props }) => {
  return (
    <AntdTooltip title={title}>
      <img src={questionIcon} alt="Info" {...props} />
    </AntdTooltip>
  );
};

export default Tooltip;
