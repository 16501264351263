import React, { useEffect, useState, useMemo } from 'react';
import { useField, useFormikContext } from 'formik';

import RichTextInput from './RichTextInput';

interface Props {
  name: string;
  placeholder?: string;
}
const RichTextInputFormik = ({ placeholder, name }: Props) => {
  const [field, metadata] = useField(name);
  const [fieldHtml] = useField(`${name}Html`);
  const [touched, setTouched] = useState(false);

  const fieldError = useMemo(() => (touched ? metadata.error : undefined), [metadata.error, touched]);

  const { setFieldValue } = useFormikContext();

  // avoid useLayoutEffect when SSR https://gist.github.com/gaearon/e7d97cdf38a2907924ea12e4ebdf3c85
  const [showEditor, setShowEditor] = useState(false);
  useEffect(() => setShowEditor(true), []);

  return showEditor ? (
    <RichTextInput
      placeholder={placeholder}
      onChange={(raw, html) => {
        setFieldValue(name as never, raw);
        setFieldValue(`${name}Html` as never, html);
      }}
      value={fieldHtml.value || field.value}
      error={fieldError}
      name={name}
      handleBlur={() => {
        field.onBlur(name);
        setTouched(true);
      }}
    />
  ) : null;
};

export default RichTextInputFormik;
