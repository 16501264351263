import React from 'react';
import { Tabs } from 'antd';
import { useRouter } from 'found';

const { TabPane } = Tabs;

const TabsComponent = props => {
  const { options, renderChildren } = props;
  const { match, router } = useRouter();

  return (
    <Tabs activeKey={match.location.pathname} onTabClick={activeKey => router.push(activeKey)}>
      {options.map(op => (
        <TabPane tab={op.label} key={op.link}>
          {renderChildren}
        </TabPane>
      ))}
    </Tabs>
  );
};

export default TabsComponent;
