import common, { Keys as CommonKeys } from './common';
import home, { Keys as HomeKeys } from './home';
import auth, { Keys as AuthKeys } from './auth';
import event, { Keys as EventKeys } from './event';
import eventRaffle, { Keys as EventRaffleKeys } from './eventRaffle';
import login, { Keys as LoginKeys } from './login';
import organization, { Keys as OrganizationKeys } from './organization';
import search, { Keys as SearchKeys } from './search';
import user, { Keys as UserKeys } from './user';
const namespaces = {
  auth,
  common,
  event,
  eventRaffle,
  home,
  login,
  organization,
  search,
  user,
};
export default namespaces;
export type NamespaceKeys = keyof typeof namespaces;
export type MessageKeys =
  | CommonKeys
  | HomeKeys
  | AuthKeys
  | EventKeys
  | LoginKeys
  | OrganizationKeys
  | SearchKeys
  | UserKeys
  | EventRaffleKeys;
