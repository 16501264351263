import React, { AnchorHTMLAttributes } from 'react';
import styled from 'styled-components';

import Loading from './Loading';

const Text = styled.p`
  padding: 0px;
  margin: 10px;
  color: white;
  font-size: 16px;
  ${p => {
    switch (p.type) {
      case 'outline':
        return `
          color: ${p.theme.primaryTitle};
        `;
      default:
        return;
    }
  }}

  ${p => p.css}
`;

const ButtonComponent = styled.button<InterfaceProps>`
  border: none;
  outline: none;
  width: 142px;
  height: 40px;
  padding: 0px;
  background-color: ${p => (p.disabled ? p.theme.disabled : p.theme.primaryTitle)};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  white-space: nowrap;
  ${p => p.css};
  ${p => {
    switch (p.type) {
      case 'outline':
        return `
          background-color: ${p.theme.primaryBackground};
          border: 1px solid ${p.theme.primaryTitle}
        `;
      default:
        return;
    }
  }}
  transition: 0.2s background-color;
  &:hover {
    background-color: ${p => p.theme.strongTitle};
  }
`;

const ButtonAnchorComponent = styled(ButtonComponent.withComponent('a'))`
  text-decoration: none;
`;

interface InterfaceProps extends Omit<Partial<HTMLButtonElement>, 'disabled'> {
  text?: string;
  isLoading?: boolean;
  disabled?: boolean;
  css?: any;
  textCss?: any;
  onClick?: () => any;
  className?: string;
}

const Button = (props: InterfaceProps) => {
  if (props.isLoading) {
    return (
      <ButtonComponent {...props}>
        <Loading type="button" isLoading={true} />
      </ButtonComponent>
    );
  }

  return (
    <ButtonComponent {...props}>
      <Text css={props.textCss} type={props.type}>
        {props.text}
      </Text>
    </ButtonComponent>
  );
};

const ButtonAnchor = ({
  isLoading,
  text,
  textCss,
  ...props
}: InterfaceProps & AnchorHTMLAttributes<HTMLAnchorElement>) => (
  <ButtonAnchorComponent {...props}>
    {isLoading ? <Loading type="button" /> : <Text css={textCss}>{text}</Text>}
  </ButtonAnchorComponent>
);

Button.Anchor = ButtonAnchor;

export default Button;
