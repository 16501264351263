const eventRaffle = {
  CreateNewRaffle: 'Criar novo sorteio',
  NonZero: 'Não pode ser zero',
  NumberOfResults: 'Número de resultados',
  PickAtendees: 'Sortear',
  PreviousResults: 'Histórico',
  Raffle: 'Sorteio',
  RaffleName: 'Nome do sorteio',
  RafflePreferences: 'Preferências',
  Required: 'Obrigatório',
  SaveResultToHistory: 'Salvar sorteio no histórico',
  SendNotificationToSelectedAttendees: 'Enviar notificação aos participantes sorteados',
  ShirtRaffle: 'Ex: Sorteio da camiseta',
  SubmitSuccess: 'Sorteio gerado',
  Submitfail: 'Oops, erro ao gerar o sorteio',
  TeamAdminsCanBeSelectedInTheRaffle: 'Administradores do time podem ser escolhidos no sorteio',
  ThereMustBeAtLeastOneWinner: 'Deve haver no mínimo um ganhador',
};
export default eventRaffle;
export type Keys = keyof typeof eventRaffle;
