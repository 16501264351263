import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';

import RootRef from '@material-ui/core/RootRef';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import { format, eachDayOfInterval, isBefore } from 'date-fns';

import Button from '../common/Button';

// This could be refactored to provide props to enhance component customization
const DatePickerComposer = styled.div`
  box-sizing: border-box;
  position: absolute;
  top: 35px;
  width: 100%;
  overflow-x: scroll;
  padding: 10px;
  background: ${p => p.theme.primaryBackground};
  border-radius: 6px;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14),
    0px 3px 14px 2px rgba(0, 0, 0, 0.12);

  display: flex;
  flex-direction: column;

  @media (max-width: 500px) {
    top: 50px;
  }

  .MuiPickersStaticWrapper-staticWrapperRoot {
    min-width: auto;
  }
  .MuiTypography-body1 {
    font-size: 13px;
  }
  .MuiTypography-caption {
    font-size: 9px;
  }
  .MuiIconButton-root {
    padding: 0;
  }
  .MuiPickersBasePicker-pickerView {
    max-width: 200px;
    min-width: 199px;
    min-height: auto;

    @media (max-width: 500px) {
      min-width: 100%;
    }

    div[role='presentation'] {
      height: 28px;
    }
  }
  .MuiTypography-body2 {
    font-size: 9px;
    @media (max-width: 500px) {
      font-size: 12px;
    }
  }
  .MuiPickersDay-day {
    width: 24px;
    height: 24px;
    margin: 0 2px;
    @media (max-width: 500px) {
      margin: 0 8px;
    }
  }
  .MuiPickersCalendar-transitionContainer {
    min-height: 155px;
    margin: 0;
  }
  .MuiPickersDay-daySelected {
    color: ${p => p.theme.primaryBackground} !important;
    background-color: ${p => p.theme.primaryText};
    &:hover {
      background-color: ${p => p.theme.secondaryText};
    }
  }
`;
const OkButtonDateStyle = css`
  box-sizing: border-box;
  background: transparent;
  max-height: 24px;
  min-height: 24px;
  display: flex;
  justify-content: flex-end;
  padding: 0 10px;
  width: 100%;
  margin: 0;
  @media (max-width: 500px) {
    margin: 10px 0;
    padding: 0px 20px;
  }
`;
const OkButtonDateTextStyle = css`
  color: ${p => p.theme.primaryTitle};
  font-size: 13px;
  margin: 0;
`;

interface Props {
  open: boolean;
  setIsOpen: (isOpen: boolean) => void;
  startDate: Date | null;
  onStartDateChange: (date: Date) => void;
  endDate: Date | null;
  onEndDateChange: (date: Date) => void;
}

const DateRangePicker = (props: Props) => {
  const { open, setIsOpen, startDate, endDate, onStartDateChange: setStartDate, onEndDateChange: setEndDate } = props;

  const [isInSelection, setIsInSelection] = useState(false);

  const handleDateChange = (date: Date | null) => {
    if (date === null) return;

    if (endDate === null && !isInSelection) {
      setIsInSelection(true);
      setStartDate(date);
    }

    if (isInSelection && startDate) {
      setIsInSelection(false);
      if (isBefore(date, startDate)) return;
      setEndDate(date);
    }
  };

  const DatePickerRef = React.createRef<HTMLDivElement>();

  // style assignment for date range
  useEffect(() => {
    try {
      if (!DatePickerRef.current) return;
      const datesBtns = Array.from(DatePickerRef.current.getElementsByClassName('MuiPickersDay-day'));

      if (!datesBtns || !startDate || !endDate) return;
      const daysActive = eachDayOfInterval({ start: startDate, end: endDate }).reduce<number[]>(
        (result, current) => [...result, parseInt(format(current, 'd'))],
        [],
      );

      const activeDateBtns = datesBtns.filter(btn =>
        daysActive.includes(parseInt(btn.children[0].children[0].childNodes[0].textContent as string)),
      );
      activeDateBtns.map(btn => btn.classList.add('MuiPickersDay-daySelected'));
    } catch (err) {
      // eslint-disable-next-line no-console
      if (process.env.NODE_ENV !== 'production') console.log(err);
    }
  }, [DatePickerRef, endDate, startDate]);

  return open ? (
    <DatePickerComposer>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <RootRef rootRef={DatePickerRef}>
          <DatePicker
            value={startDate}
            onChange={handleDateChange}
            variant="static"
            disableToolbar
            animateYearScrolling={false}
            disablePast
            disableFuture={!!endDate}
          />
        </RootRef>
      </MuiPickersUtilsProvider>
      <Button text="OK" css={OkButtonDateStyle} textCss={OkButtonDateTextStyle} onClick={() => setIsOpen(false)} />
    </DatePickerComposer>
  ) : null;
};

export default DateRangePicker;
