import styled from 'styled-components';
import { Icon } from 'antd';

export interface Props {
  variant?: string;
}

const IconComponent = styled(Icon)<Props>`
  ${p => {
    switch (p.variant) {
      case 'bordered':
        return `
          padding: 8px;
          border: 1px solid ${p.theme.primaryTitle};
          border-radius: 50%;
        `;
      default:
        return null;
    }
  }}
`;

export default IconComponent;
