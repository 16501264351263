import React from 'react';
import styled from 'styled-components';
import { Input } from 'antd';

const FlatInput = styled(Input)`
  border-width: 0px !important;
  &:focus {
    border-width: 0px !important;
  }
  .ant-input:focus {
    border-width: 0px !important;
  }
`;

export default FlatInput;
