const auth = {
  ByClickingRegisterYouAgreeWithOur: 'Ao clicar em Registrar você concorda com nossos',
  Confirm: 'Confirmar',
  ConfirmPassword: 'Confirmar senha',
  ConfirmYourEmail: 'Confirme seu email',
  EmailConfirmationMessage:
    'Nós mandamos um email de confirmação para {{email}}, lembre-se de clicar no link para aproveitar tudo o que Confy oferece',
  EmailConfirmed: 'E-mail confirmado',
  EmailIsRequired: 'Email é obrigatório',
  ErrorConfirmingEmail: 'Erro ao confirmar e-mail',
  ErrorRegistering: 'Erro ao registrar',
  ErrorResetingPassword: 'Erro ao resetar sua senha',
  ErrorSendingEmail: 'Erro ao enviar email',
  ErrorSigning: 'Erro ao logar',
  ErrorSigningWithFacebook: 'Erro ao logar com Facebook',
  ErrorSigningWithGithub: 'Erro ao logar com GitHub',
  FirstName: 'Nome',
  ForgotPassword: 'Esqueceu sua senha?',
  LastName: 'Sobrenome',
  LetsExperienceLifeTogether: 'Vamos viver a vida juntos',
  Login: 'Logar',
  MustBeAValidEmail: 'Precisa ser um email válido',
  NameIsRequired: 'Nome é obrigatório',
  NiceMeetingYou: 'Prazer em te conhecer!',
  Or: 'ou',
  Password: 'Senha',
  PasswordIsRequired: 'Senha é obrigatória',
  PasswordMustHaveAtLeastXCharacters: 'Sua senha precisa ter pelo menos {{characters}} caracteres',
  PasswordsDoNotMatch: 'Senhas não batem',
  Register: 'Registrar',
  ResetPassword: 'Resete a senha',
  SuccessRegistering: 'Sucesso ao registrar',
  SuccessResetingPassword: 'Successo ao resetar sua senha',
  SuccessSendingEmail: 'Email enviado com sucesso',
  SuccessSigning: 'Sucesso ao logar',
  SuccessSigningWithFacebook: 'Sucesso ao logar com Facebook',
  SuccessSigningWithGithub: 'Sucesso ao logar com GitHub',
  TermsOfUse: 'Termos de uso',
  WeWillSendAResetEmail: 'Nós vamos te enviar um email para resetar sua senha.',
  WelcomeBack: 'Bem-vindo(a)',
};
export default auth;
export type Keys = keyof typeof auth;
