import React from 'react';

import MenuItem, { MenuItemProps } from '@material-ui/core/MenuItem';

type Props = MenuItemProps;

const SelectorItemComponent = (props: Props) => {
  return <MenuItem {...props} />;
};

export default SelectorItemComponent;
