/* tslint:disable */
/* @relayHash 4a37728eb2e5b956342e976f2f598fb8 */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type event_EventBasicInfos_QueryVariables = {
    slug: string;
};
export type event_EventBasicInfos_QueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"EventBasicInfos_query">;
};
export type event_EventBasicInfos_Query = {
    readonly response: event_EventBasicInfos_QueryResponse;
    readonly variables: event_EventBasicInfos_QueryVariables;
};



/*
query event_EventBasicInfos_Query(
  $slug: ID!
) {
  ...EventBasicInfos_query_20J5Pl
}

fragment EventBasicInfos_query_20J5Pl on Query {
  event(id: $slug) {
    id
    description
    descriptionHtml
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "slug",
    "type": "ID!",
    "defaultValue": null
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "event_EventBasicInfos_Query",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "EventBasicInfos_query",
        "args": [
          {
            "kind": "Variable",
            "name": "slug",
            "variableName": "slug"
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "event_EventBasicInfos_Query",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "event",
        "storageKey": null,
        "args": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "slug"
          }
        ],
        "concreteType": "Event",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "description",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "descriptionHtml",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "event_EventBasicInfos_Query",
    "id": null,
    "text": "query event_EventBasicInfos_Query(\n  $slug: ID!\n) {\n  ...EventBasicInfos_query_20J5Pl\n}\n\nfragment EventBasicInfos_query_20J5Pl on Query {\n  event(id: $slug) {\n    id\n    description\n    descriptionHtml\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '3d3c70d8c505ca4d352d6debb0e81ed6';
export default node;
