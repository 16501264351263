/* tslint:disable */
/* @relayHash cf96cfba977bc9790b58cf9e84368b0d */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type user_OrganizationDetail_QueryVariables = {
    id: string;
};
export type user_OrganizationDetail_QueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"ProfileDetail_query">;
};
export type user_OrganizationDetail_Query = {
    readonly response: user_OrganizationDetail_QueryResponse;
    readonly variables: user_OrganizationDetail_QueryVariables;
};



/*
query user_OrganizationDetail_Query(
  $id: ID!
) {
  ...ProfileDetail_query_1Bmzm5
}

fragment ProfileDetail_query_1Bmzm5 on Query {
  user(id: $id) {
    id
    name
    profileImage {
      __typename
      ... on SocialFile {
        url
        id
      }
      ... on File {
        url
      }
      ... on Node {
        id
      }
    }
    coverImage {
      url
      id
    }
    organizations {
      totalCount
    }
    myEvents {
      totalCount
    }
    likedEvents {
      totalCount
    }
  }
  me {
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "url",
  "args": null,
  "storageKey": null
},
v4 = [
  (v3/*: any*/)
],
v5 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "totalCount",
    "args": null,
    "storageKey": null
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "user_OrganizationDetail_Query",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "ProfileDetail_query",
        "args": (v1/*: any*/)
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "user_OrganizationDetail_Query",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "user",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "name",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "profileImage",
            "storageKey": null,
            "args": null,
            "concreteType": null,
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "__typename",
                "args": null,
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "kind": "InlineFragment",
                "type": "SocialFile",
                "selections": (v4/*: any*/)
              },
              {
                "kind": "InlineFragment",
                "type": "File",
                "selections": (v4/*: any*/)
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "coverImage",
            "storageKey": null,
            "args": null,
            "concreteType": "File",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v2/*: any*/)
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "organizations",
            "storageKey": null,
            "args": null,
            "concreteType": "OrganizationConnection",
            "plural": false,
            "selections": (v5/*: any*/)
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "myEvents",
            "storageKey": null,
            "args": null,
            "concreteType": "EventConnection",
            "plural": false,
            "selections": (v5/*: any*/)
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "likedEvents",
            "storageKey": null,
            "args": null,
            "concreteType": "EventConnection",
            "plural": false,
            "selections": (v5/*: any*/)
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "user_OrganizationDetail_Query",
    "id": null,
    "text": "query user_OrganizationDetail_Query(\n  $id: ID!\n) {\n  ...ProfileDetail_query_1Bmzm5\n}\n\nfragment ProfileDetail_query_1Bmzm5 on Query {\n  user(id: $id) {\n    id\n    name\n    profileImage {\n      __typename\n      ... on SocialFile {\n        url\n        id\n      }\n      ... on File {\n        url\n      }\n      ... on Node {\n        id\n      }\n    }\n    coverImage {\n      url\n      id\n    }\n    organizations {\n      totalCount\n    }\n    myEvents {\n      totalCount\n    }\n    likedEvents {\n      totalCount\n    }\n  }\n  me {\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = 'cd5f0b63163309c4e26df065fc3349f7';
export default node;
