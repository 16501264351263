import * as React from 'react';
import styled from 'styled-components';
import { Input } from 'antd';

import CommonText from '../common/CommonText';
import Space from '../common/Space';
import Tooltip from '../common/Tooltip';

interface MaskOptions {
  showMaskOnHover?: boolean;
  showMaskOnFocus?: boolean;
  greedy?: boolean;
}

export type Props = {
  mask?: string;
  maskOptions?: MaskOptions;
  email?: boolean;
  password?: boolean;
  placeholder?: string;
  hideTitle?: boolean;
  prefix?: JSX.Element | string;
  suffix?: JSX.Element | string;
  tooltip?: string;
  error?: boolean;
  helperText?: string;
} & React.InputHTMLAttributes<any>;

const Wrapper = styled.div`
  width: 100%;
`;

const defaultMaskOptions = {
  showMaskOnHover: false,
};

export default class TextField extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.inputRef = React.createRef();
  }

  async componentDidMount() {
    if (this.props.mask) {
      const element = document.getElementById(`${this.props.mask}--${this.props.placeholder}`);
      const inputMaskLazy = await import('inputmask');
      const inputMask = new inputMaskLazy.default(
        { regex: this.props.mask },
        {
          placeholder: '',
          ...defaultMaskOptions,
          ...this.props.maskOptions,
        },
      );
      inputMask.mask(element);
    }
  }

  async componentWillUnmount() {
    if (this.props.mask) {
      const inputMaskLazy = await import('inputmask');
      this.inputRef && this.inputRef.current && inputMaskLazy.remove(this.inputRef.current);
    }
  }
  render() {
    const {
      label,
      helperText,
      max,
      min,
      placeholder,
      mask,
      className,
      password,
      email,
      multiline,
      hideTitle,
      tooltip,
      ...otherProps
    } = this.props;

    const textFieldProps = {
      ref: this.inputRef,
      helperText: helperText || ' ',
      inputProps: { min, max },
      ...otherProps,
      placeholder: placeholder,
      variant: 'outlined',
      margin: 'normal',
      id: 'outlined-name',
      type: password ? 'password' : email ? 'email' : 'text',
    };

    const uniqueId = `${mask}--${placeholder}`;

    return (
      <Wrapper>
        {hideTitle ? null : (
          <CommonText textSize="subtextSize" color="primaryTitle" weight="600">
            {label || placeholder}
            {tooltip ? <Tooltip title={tooltip} style={{ marginLeft: 10 }} /> : null}
          </CommonText>
        )}
        <Space height={10} />
        {multiline ? <Input.TextArea rows={4} {...textFieldProps} /> : <Input {...textFieldProps} id={uniqueId} />}
        <Space height={4} />
        <CommonText textSize="supportTextSize" color="danger" style={{ opacity: helperText ? 1 : 0 }}>
          {helperText || 'opacity : 0'}
        </CommonText>
      </Wrapper>
    );
  }
}
