import React from 'react';
import styled, { css } from 'styled-components';

import CommonText from './CommonText';

const Wrapper = styled.div`
  width: 100%;
`;

const textCss = css`
  text-align: center;
`;

interface Props {
  searchName?: string;
}

const ListEmptyComponent = (props: Props) => (
  <Wrapper>
    <CommonText textCss={textCss} color="secondaryText" textSize="textSize" weight="bold">
      {props.searchName ? `No ${props.searchName} found` : 'Nothing found'}
    </CommonText>
  </Wrapper>
);

export default ListEmptyComponent;
