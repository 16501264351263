/* tslint:disable */
/* @relayHash d4a7a33b33bb383755aadd8f72fcb35f */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type event_EventAttendeesAttended_QueryVariables = {
    slug: string;
    first: number;
    search?: string | null;
};
export type event_EventAttendeesAttended_QueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"EventAttendeesAttended_query">;
};
export type event_EventAttendeesAttended_Query = {
    readonly response: event_EventAttendeesAttended_QueryResponse;
    readonly variables: event_EventAttendeesAttended_QueryVariables;
};



/*
query event_EventAttendeesAttended_Query(
  $slug: ID!
  $first: Int!
  $search: String
) {
  ...EventAttendeesAttended_query_pniqo
}

fragment EventAttendeesAttended_query_pniqo on Query {
  eventUsers(event: $slug, first: $first, search: $search) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      node {
        ...UserCard_user
        id
        __typename
      }
      cursor
    }
  }
}

fragment UserCard_user on User {
  id
  name
  profileImage {
    __typename
    ... on SocialFile {
      url
      id
    }
    ... on File {
      url
    }
    ... on Node {
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "slug",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "search",
    "type": "String",
    "defaultValue": null
  }
],
v1 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v2 = {
  "kind": "Variable",
  "name": "search",
  "variableName": "search"
},
v3 = [
  {
    "kind": "Variable",
    "name": "event",
    "variableName": "slug"
  },
  (v1/*: any*/),
  (v2/*: any*/)
],
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "__typename",
  "args": null,
  "storageKey": null
},
v6 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "url",
    "args": null,
    "storageKey": null
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "event_EventAttendeesAttended_Query",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "EventAttendeesAttended_query",
        "args": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "kind": "Variable",
            "name": "slug",
            "variableName": "slug"
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "event_EventAttendeesAttended_Query",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "eventUsers",
        "storageKey": null,
        "args": (v3/*: any*/),
        "concreteType": "UserConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "pageInfo",
            "storageKey": null,
            "args": null,
            "concreteType": "PageInfoExtended",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "hasNextPage",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "endCursor",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "UserEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "User",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "name",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "profileImage",
                    "storageKey": null,
                    "args": null,
                    "concreteType": null,
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v4/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "type": "SocialFile",
                        "selections": (v6/*: any*/)
                      },
                      {
                        "kind": "InlineFragment",
                        "type": "File",
                        "selections": (v6/*: any*/)
                      }
                    ]
                  },
                  (v5/*: any*/)
                ]
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "cursor",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      },
      {
        "kind": "LinkedHandle",
        "alias": null,
        "name": "eventUsers",
        "args": (v3/*: any*/),
        "handle": "connection",
        "key": "EventAttendeesAttended_eventUsers",
        "filters": []
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "event_EventAttendeesAttended_Query",
    "id": null,
    "text": "query event_EventAttendeesAttended_Query(\n  $slug: ID!\n  $first: Int!\n  $search: String\n) {\n  ...EventAttendeesAttended_query_pniqo\n}\n\nfragment EventAttendeesAttended_query_pniqo on Query {\n  eventUsers(event: $slug, first: $first, search: $search) {\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n    edges {\n      node {\n        ...UserCard_user\n        id\n        __typename\n      }\n      cursor\n    }\n  }\n}\n\nfragment UserCard_user on User {\n  id\n  name\n  profileImage {\n    __typename\n    ... on SocialFile {\n      url\n      id\n    }\n    ... on File {\n      url\n    }\n    ... on Node {\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = 'b1a2f8c0b33760790c669f8894da9b3c';
export default node;
