import React from 'react';
import { Environment } from 'relay-runtime';
import { RouterState } from 'found';

import { Loading, LoadingWrapper, CommonText, Space, Button } from '@confy/ui-web';

interface RenderRelayComponent extends RouterState {
  Component: React.ComponentType<any> | null;
  environment: Environment;
  error: Error;
  props: {};
  resolving: boolean;
  retry: () => void;
  variables: {};
}

export default function renderRelayComponent({
  Component,
  environment,
  error,
  match,
  props,
  resolving,
  retry,
  variables,
}: RenderRelayComponent) {
  if (error) {
    return (
      <LoadingWrapper>
        <CommonText>{error.toString()}</CommonText>
        <Space width={20} />
        <Button text="Retry" onClick={retry} />
      </LoadingWrapper>
    );
  }

  if (props) {
    return <Component query={props} match={match} />;
  }

  return (
    <LoadingWrapper>
      <Loading />
    </LoadingWrapper>
  );
}
