import React from 'react';

import Popover from '@material-ui/core/Popover';

const PopoverComponent = props => {
  const { open, anchorEl, handleClose } = props;
  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      {props.children}
    </Popover>
  );
};

export default PopoverComponent;
