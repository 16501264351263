import React from 'react';
import styled from 'styled-components';
import { Modal } from 'antd';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

interface FooterlessModalProps {
  scroll?: boolean;
}

const FooterlessModal = styled(Modal)<FooterlessModalProps>`
  .ant-modal-footer {
    display: none;
  }
  .ant-modal-body {
    ${p =>
      p.scroll &&
      `
      height: 400px;
      overflow-y: scroll;
    `}
  }
`;

interface Props {
  isOpen: boolean;
  title: string;
  closeModal: () => void;
  children: React.ReactNode;
  scroll?: boolean;
}

const ModalComponent = ({ isOpen, title, closeModal, children, scroll }: Props) => {
  return (
    <FooterlessModal
      title={title}
      visible={isOpen}
      onOk={closeModal}
      okText={null}
      onCancel={closeModal}
      scroll={scroll}
    >
      <Wrapper>{children}</Wrapper>
    </FooterlessModal>
  );
};
export default ModalComponent;
