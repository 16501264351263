/* tslint:disable */
/* @relayHash 23a6f6ba67a468f5e81ac92b7bb5a545 */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type organization_OrganizationEditRoles_QueryVariables = {
    first: number;
    search?: string | null;
    slug?: string | null;
};
export type organization_OrganizationEditRoles_QueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"OrganizationEditRoles_query">;
};
export type organization_OrganizationEditRoles_Query = {
    readonly response: organization_OrganizationEditRoles_QueryResponse;
    readonly variables: organization_OrganizationEditRoles_QueryVariables;
};



/*
query organization_OrganizationEditRoles_Query(
  $first: Int!
  $search: String
  $slug: String
) {
  ...OrganizationEditRoles_query_pniqo
}

fragment OrganizationEditRoles_query_pniqo on Query {
  organization(slug: $slug) {
    id
    name
    members: users(first: $first, search: $search, filters: {notInRoles: ["OWNER"]}) {
      pageInfo {
        hasNextPage
        endCursor
      }
      totalCount
      edges {
        node {
          id
          ...UserRow_user
          __typename
        }
        cursor
      }
    }
    owners: users(first: $first, filters: {roles: ["OWNER"]}) {
      pageInfo {
        hasNextPage
        endCursor
      }
      totalCount
      edges {
        node {
          id
          ...UserRow_user
          __typename
        }
        cursor
      }
    }
  }
}

fragment UserProfileImage_user on User {
  id
  name
  profileImage {
    __typename
    ... on SocialFile {
      url
      id
    }
    ... on File {
      url
    }
    ... on Node {
      id
    }
  }
}

fragment UserRow_user on User {
  id
  name
  ...UserProfileImage_user
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "search",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "slug",
    "type": "String",
    "defaultValue": null
  }
],
v1 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v2 = {
  "kind": "Variable",
  "name": "search",
  "variableName": "search"
},
v3 = {
  "kind": "Variable",
  "name": "slug",
  "variableName": "slug"
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v6 = [
  "OWNER"
],
v7 = [
  {
    "kind": "Literal",
    "name": "filters",
    "value": {
      "notInRoles": (v6/*: any*/)
    }
  },
  (v1/*: any*/),
  (v2/*: any*/)
],
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "__typename",
  "args": null,
  "storageKey": null
},
v9 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "url",
    "args": null,
    "storageKey": null
  }
],
v10 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "pageInfo",
    "storageKey": null,
    "args": null,
    "concreteType": "PageInfoExtended",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "hasNextPage",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "endCursor",
        "args": null,
        "storageKey": null
      }
    ]
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "totalCount",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "edges",
    "storageKey": null,
    "args": null,
    "concreteType": "UserEdge",
    "plural": true,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "node",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "profileImage",
            "storageKey": null,
            "args": null,
            "concreteType": null,
            "plural": false,
            "selections": [
              (v8/*: any*/),
              (v4/*: any*/),
              {
                "kind": "InlineFragment",
                "type": "SocialFile",
                "selections": (v9/*: any*/)
              },
              {
                "kind": "InlineFragment",
                "type": "File",
                "selections": (v9/*: any*/)
              }
            ]
          },
          (v8/*: any*/)
        ]
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "cursor",
        "args": null,
        "storageKey": null
      }
    ]
  }
],
v11 = [
  {
    "kind": "Literal",
    "name": "filters",
    "value": {
      "roles": (v6/*: any*/)
    }
  },
  (v1/*: any*/)
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "organization_OrganizationEditRoles_Query",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "OrganizationEditRoles_query",
        "args": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/)
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "organization_OrganizationEditRoles_Query",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "organization",
        "storageKey": null,
        "args": [
          (v3/*: any*/)
        ],
        "concreteType": "Organization",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "kind": "LinkedField",
            "alias": "members",
            "name": "users",
            "storageKey": null,
            "args": (v7/*: any*/),
            "concreteType": "UserConnection",
            "plural": false,
            "selections": (v10/*: any*/)
          },
          {
            "kind": "LinkedHandle",
            "alias": "members",
            "name": "users",
            "args": (v7/*: any*/),
            "handle": "connection",
            "key": "OrganizationEditRoles_members",
            "filters": []
          },
          {
            "kind": "LinkedField",
            "alias": "owners",
            "name": "users",
            "storageKey": null,
            "args": (v11/*: any*/),
            "concreteType": "UserConnection",
            "plural": false,
            "selections": (v10/*: any*/)
          },
          {
            "kind": "LinkedHandle",
            "alias": "owners",
            "name": "users",
            "args": (v11/*: any*/),
            "handle": "connection",
            "key": "OrganizationEditRoles_owners",
            "filters": []
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "organization_OrganizationEditRoles_Query",
    "id": null,
    "text": "query organization_OrganizationEditRoles_Query(\n  $first: Int!\n  $search: String\n  $slug: String\n) {\n  ...OrganizationEditRoles_query_pniqo\n}\n\nfragment OrganizationEditRoles_query_pniqo on Query {\n  organization(slug: $slug) {\n    id\n    name\n    members: users(first: $first, search: $search, filters: {notInRoles: [\"OWNER\"]}) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      totalCount\n      edges {\n        node {\n          id\n          ...UserRow_user\n          __typename\n        }\n        cursor\n      }\n    }\n    owners: users(first: $first, filters: {roles: [\"OWNER\"]}) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      totalCount\n      edges {\n        node {\n          id\n          ...UserRow_user\n          __typename\n        }\n        cursor\n      }\n    }\n  }\n}\n\nfragment UserProfileImage_user on User {\n  id\n  name\n  profileImage {\n    __typename\n    ... on SocialFile {\n      url\n      id\n    }\n    ... on File {\n      url\n    }\n    ... on Node {\n      id\n    }\n  }\n}\n\nfragment UserRow_user on User {\n  id\n  name\n  ...UserProfileImage_user\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = 'a939c5e421adb22ec5c43f7ed0d5fcb4';
export default node;
