const common = {
  AnyDate: 'Qualquer data',
  Back: 'Voltar',
  BasicInformation: 'Informações básicas',
  ClickToUpload: 'Clique para fazer upload',
  CopyLink: 'Copiar link',
  CreateAccount: 'Criar Conta',
  CurrentLocation: 'Localização atual',
  Email: 'Email',
  English: 'Inglês',
  Error: 'Erro',
  Events: 'Eventos',
  ImAlreadyUser: 'Já sou usuário',
  JoinConfy: 'Junte-se ao confy',
  Language: 'Idioma',
  LoadMore: 'Carregar mais',
  Logout: 'Deslogar',
  MyProfile: 'Meu Perfil',
  Name: 'Nome',
  NewOrg: 'Nova Org',
  NextWeek: 'Próxima semana',
  NoData: 'Sem dados',
  NumberTooLarge: 'Número muito grande',
  PageDoesNotExist: 'Parece que essa página não existe',
  PageNotFound: 'Página não encontrada',
  PickADate: 'Selecione uma data...',
  Portuguese: 'Português',
  Save: 'Salvar',
  Search: 'Procurar',
  SearchAnything: 'Procure qualquer coisa',
  Success: 'Sucesso',
  TermsConditions: 'Termos & Condições',
  ThisWeek: 'Essa semana',
  Today: 'Hoje',
  Tomorrow: 'Amanhã',
  ViewAll: 'Ver tudo',
  Warning: 'Atenção',
  What: 'O quê',
  When: 'Quando',
  Where: 'Onde',
  YouAreLoggedOut: 'Você está deslogado',
  YouCanUseOutAppAsMuchAsYouWantButYouMustCreateAccount:
    'Você pode utilizar o app o tanto que quiser, mas se você quer confirmar presença em qualquer evento, criar um evento ou uma org você precisa criar uma conta.',
};
export default common;
export type Keys = keyof typeof common;
