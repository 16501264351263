import React from 'react';
import { Icon } from 'antd';

interface LoadingProps {
  type?: string;
}

const Loading = ({ type }: LoadingProps) => <Icon style={{ fontSize: type === 'small' ? 14 : 39 }} type="loading" />;

export default Loading;
