import React from 'react';
import styled, { css } from 'styled-components';

import CommonText from './CommonText';
import Button from './Button';
import Space from './Space';
import FadeImage from './FadeImage';

const Wrapper = styled.div<{ css?: any }>`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  max-width: 900px;
  width: 100%;
  min-width: 300px;
  margin: auto;
  @media (max-width: 1000px) {
    width: calc(100% - 20px);
    padding: 10px;
  }
  ${p => p.css || null}
`;

const buttonCss = css`
  width: 200px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${p => p.justifyContent};
  align-items: center;
  width: 450px;
  @media (max-width: 400px) {
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-top: -70px;
  }
`;

const NotLoggedImg = styled(FadeImage)`
  height: 160px;
  object-fit: cover;
`;

const textCss = css`
  text-align: center;
`;

const pointerCss = css`
  cursor: pointer;
`;

export interface Props {
  imgSrc?: any;
  title?: string;
  text?: string;
  buttonOneText?: string;
  buttonOneClick?: () => void;
  buttonTwoText?: string;
  buttonTwoClick?: () => void;
  wrapperCss?: any;
}

const Empty = ({
  imgSrc,
  title,
  text,
  buttonOneText,
  buttonOneClick,
  buttonTwoText,
  buttonTwoClick,
  wrapperCss,
}: Props) => {
  return (
    <Wrapper css={wrapperCss}>
      <NotLoggedImg src={imgSrc} />
      <Space height={20} />
      {title && (
        <>
          <CommonText color="primaryTitle" textSize="subsectionSize" textCss={textCss} weight="bold">
            {title}
          </CommonText>
          <Space height={20} />
        </>
      )}
      <CommonText color="primaryText" textSize="textSize" textCss={textCss}>
        {text}
      </CommonText>
      <Space height={30} />

      <ButtonsWrapper justifyContent={!buttonOneText || !buttonTwoText ? 'center' : 'space-around'}>
        <Space height={30} />
        <CommonText color="primaryTitle" textSize="textSize" onClick={buttonTwoClick} textCss={pointerCss}>
          {buttonTwoText}
        </CommonText>
        <Space height={20} />
        {buttonOneText && <Button text={buttonOneText} css={buttonCss} onClick={buttonOneClick} />}
      </ButtonsWrapper>
    </Wrapper>
  );
};

export default Empty;
