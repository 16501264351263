import React, { HTMLAttributes } from 'react';
import { useField, useFormikContext } from 'formik';

import TextField, { Props as TextFieldProps } from './TextField';

type Props = {
  name: string;
  placeholder: string;
  helperText?: string;
  type?: string;
  min?: string;
  max?: string;
  keyExpected?: string;
  hideTitle?: boolean;
} & TextFieldProps &
  Omit<Omit<HTMLAttributes<HTMLDivElement>, 'prefix'>, 'suffix'>;

export const CLASSES_OVERRIDE = {
  TEXT_FIELD_MULTILINE: 'text-field-multiline',
  TEXT_FIELD_LABEL_ROOT: 'text-field-label-root',
  TEXT_FIELD_INPUT: 'text-field-input',
};

const TextFieldFormik: React.FC<Props> = (props: Props) => {
  const { name, helperText, type, min, max, keyExpected = 'Enter', ...otherProps } = props;

  const [field, metadata] = useField(name);
  const fieldError = metadata.touched && metadata.error;
  const { setFieldValue, handleSubmit, isValid } = useFormikContext<any>();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = e.target;

    if (type && type.toUpperCase() === 'NUMBER') {
      if (min && Number.parseInt(value) < Number.parseInt(min)) {
        return setFieldValue(name, min);
      }
      if (max && Number.parseInt(value) > Number.parseInt(max)) {
        return setFieldValue(name, max);
      }
    }

    return setFieldValue(name, value);
  };

  const handleKeyDown = ({ key }: React.KeyboardEvent) => {
    if (!keyExpected || key !== keyExpected) return;

    if (isValid) handleSubmit();
  };

  return (
    <TextField
      value={field.value}
      onBlur={field.onBlur}
      error={!!fieldError}
      helperText={fieldError || helperText}
      name={name}
      onChange={handleChange}
      {...otherProps}
      onKeyDown={handleKeyDown}
    />
  );
};

export default TextFieldFormik;
