import React, { useCallback, useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import { useDebounce } from '@confy/hooks';

import buttonArrow from '../../assets/button-arrow.png';

import Loading from './Loading';
import Space from './Space';

const Wrapper = styled.div<{ isOverflowed: boolean }>`
  width: 100%;
  display: flex;
  overflow: scroll;
  justify-content: ${p => (p.isOverflowed ? 'flex-start' : 'center')};
  padding-top: 20px;
  padding-bottom: 20px;
  -webkit-overflow-scrolling: touch;
  align-items: center;
`;

const Scroller = styled.div<{ isVisible: boolean; height: string }>`
  cursor: pointer;
  position: absolute;
  right: 0;
  width: 38px;
  height: ${p => p.height}px;
  z-index: 9;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: 0.6s opacity ease;
  opacity: ${p => (p.isVisible ? 1 : 0)};
  @media (max-width: 600px) {
    display: none;
  }
`;

const LeftScroller = styled.div<{ height: string; isVisible: boolean }>`
  cursor: pointer;
  position: absolute;
  left: 0;
  width: 38px;
  height: ${p => p.height}px;
  z-index: 90;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: 0.6s opacity ease;
  opacity: ${p => (p.isVisible ? 1 : 0)};
  @media (max-width: 600px) {
    display: none;
  }
`;

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SpacerWrapper = styled.div`
  color: white;
`;

const Arrow = styled.img.attrs({
  src: buttonArrow,
})`
  cursor: pointer;

  width: 30px;
  padding: 7px 12px;
  border-radius: 100%;
`;

const LeftArrow = styled.img.attrs({
  src: buttonArrow,
})`
  cursor: pointer;

  width: 30px;
  padding: 7px 12px;
  border-radius: 100%;
  transform: rotate(180deg);
`;

interface Props {
  edges: any[];
  renderItem: (edge: any) => JSX.Element;
  loadMore: () => void;
  hasNextPage: boolean;
  isLoading: boolean;
}

const InfinityHorizontalScroll = ({ edges, renderItem, loadMore, hasNextPage, isLoading }: Props) => {
  const wrapper = useRef<HTMLDivElement | null>(null);
  const [height, setHeight] = useState();
  const [isOverflowed, setOverflowed] = useState();
  const [shouldLeftRender, setShouldLeftRender] = useState(false);
  const [shouldRightRender, setShouldRightRender] = useState(true);

  useEffect(() => {
    if (!wrapper.current) {
      return;
    }
    const isOverflowed = wrapper.current.scrollWidth > wrapper.current.clientWidth;

    setHeight(wrapper.current.clientHeight);
    setOverflowed(isOverflowed);
  }, [wrapper]);

  const handleLoadMore = useDebounce(
    useCallback(() => {
      if (!wrapper.current) {
        return;
      }
      const offset = wrapper.current.clientWidth / 7;
      const visibleArea = wrapper.current.clientWidth + wrapper.current.scrollLeft;
      const toReach = wrapper.current.scrollWidth - offset;

      if (visibleArea >= toReach) {
        setShouldRightRender(false);
        loadMore();
      } else {
        setShouldRightRender(true);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadMore, wrapper]),
    400,
    { leading: false },
  );

  const handleScroll = useCallback(() => {
    if (!wrapper.current) {
      return;
    }
    const offset = 10;
    const visibleArea = wrapper.current.clientWidth + wrapper.current.scrollLeft;
    const toReach = wrapper.current.scrollWidth - offset;

    if (wrapper.current.scrollLeft <= offset) {
      setShouldLeftRender(false);
    } else {
      setShouldLeftRender(true);
    }

    if (visibleArea >= toReach) {
      setShouldRightRender(false);
    } else {
      setShouldRightRender(true);
    }

    handleLoadMore();
  }, [handleLoadMore]);

  const handleLeftArrowClick = useCallback(() => {
    if (!wrapper.current) {
      return;
    }
    wrapper.current.scrollBy({ top: 0, left: -wrapper.current.offsetWidth, behavior: 'smooth' });
    handleScroll();
  }, [handleScroll]);

  const handleRightArrowClick = useCallback(() => {
    if (!wrapper.current) {
      return;
    }

    wrapper.current.scrollBy({ top: 0, left: wrapper.current.offsetWidth - 20, behavior: 'smooth' });
    handleScroll();
  }, [handleScroll]);

  if (!edges) {
    return null;
  }

  return (
    <Wrapper onScroll={handleScroll} ref={wrapper} isOverflowed={isOverflowed}>
      {height && isOverflowed ? (
        <LeftScroller onClick={handleLeftArrowClick} height={height} isVisible={shouldLeftRender}>
          <LeftArrow />
        </LeftScroller>
      ) : null}
      {edges.map(({ node }) => renderItem(node))}
      <LoadingWrapper>
        <Loading isLoading={isLoading} />
      </LoadingWrapper>
      <SpacerWrapper>
        {/*Do not remove this*/ `a`}
        <Space width={60}></Space>
      </SpacerWrapper>
      {height && isOverflowed ? (
        <Scroller onClick={handleRightArrowClick} height={height} isVisible={shouldRightRender || hasNextPage}>
          <Arrow />
        </Scroller>
      ) : null}
    </Wrapper>
  );
};

export default InfinityHorizontalScroll;
