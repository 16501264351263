import React from 'react';

import styled, { css } from 'styled-components';

import { Dropdown, Button as AntButton } from 'antd';

const successStyle = css`
  background-color: #32ce7b !important;
  border-color: #32ce7b !important;
  color: white !important;
  &:hover {
    background-color: #2baf69 !important;
  }
`;

const successOutlineStyle = css`
  background-color: white !important;
  border-color: #32ce7b !important;
  color: #32ce7b !important;
  &:hover {
    background-color: #2baf69 !important;
    color: white !important;
  }
`;

const errorStyle = css`
  background-color: #f5222d !important;
  border-color: #f5222d !important;
  color: white !important;
  &:hover {
    background-color: #cf1322 !important;
    border-color: #cf1322 !important;
  }
`;

const errorOutlineStyle = css`
  background-color: white !important;
  border-color: #f5222d !important;
  color: #f5222d !important;
  &:hover {
    background-color: #cf1322 !important;
    color: white !important;
  }
`;

const accentStyle = css`
  background-color: #276ef1 !important;
  border-color: #276ef1 !important;
  color: white !important;
  &:hover {
    background-color: #2261d3 !important;
    border-color: #2261d3 !important;
  }
`;

const accentOutlineStyle = css`
  background-color: white !important;
  border-color: #276ef1 !important;
  color: #276ef1 !important;
  &:hover {
    background-color: #276ef1 !important;
    color: white !important;
  }
`;

const linkStyle = css`
  color: #363636 !important;
  &:hover {
    color: black !important;
  }
`;

const defaultStyle = css`
  background-color: #363636 !important;
  border-color: #363636 !important;
  color: white !important;
  &:hover {
    background-color: black !important;
  }
`;

const defaultOutlineStyle = css`
  background-color: white !important;
  border-color: #363636 !important;
  color: #363636 !important;
  &:hover {
    background-color: #363636 !important;
    color: white !important;
  }
`;

const DropdownButton = styled(Dropdown.Button)`
  .ant-btn {
    ${p => {
      switch (p.type) {
        case 'green':
          if (p.outline) {
            return successOutlineStyle;
          }
          return successStyle;
        case 'error':
          if (p.outline) {
            return errorOutlineStyle;
          }
          return errorStyle;

        default:
          return defaultStyle;
      }
    }}
  }
`;

const ButtonComponent = styled(AntButton)`
  ${p => {
    switch (p.type) {
      case 'green':
        if (p.outline) {
          return successOutlineStyle;
        }
        return successStyle;
      case 'error':
        if (p.outline) {
          return errorOutlineStyle;
        }
        return errorStyle;
      case 'accent':
        if (p.outline) {
          return accentOutlineStyle;
        }
        return accentStyle;

      case 'link':
        return linkStyle;

      default:
        if (p.outline) {
          return defaultOutlineStyle;
        }
        return defaultStyle;
    }
  }}
`;

const NewButton = props => (props.overlay ? <DropdownButton {...props} /> : <ButtonComponent {...props} />);

export default NewButton;
