import React from 'react';
import styled, { DefaultTheme, ThemeProps, FlattenInterpolation } from 'styled-components';

export interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  css?: FlattenInterpolation<ThemeProps<DefaultTheme>>;
  error?: string;
}

const Input = ({ css, error, ...props }: Props) => (
  <Wrapper>
    <InputComponent {...props} css={css} />
    {!!error && <Error>{error}</Error>}
  </Wrapper>
);

const InputComponent = styled.input<Props>`
  height: 40px;
  border-width: 0px;
  border-bottom: 1.5px solid gray;
  font-size: 18px;
  width: 100%;
  background-color: #fff;
  &:focus {
    outline: 0;
  }
  ::placeholder {
    color: white;
    opacity: 0.8;
  }
  :-ms-input-placeholder {
    color: white;
    opacity: 0.8;
  }
  ::-ms-input-placeholder {
    color: white;
    opacity: 0.8;
  }
  ${p => p.css || null}
`;

const Wrapper = styled.div`
  height: 64px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Error = styled.p`
  color: red;
  font-size: 12px;
  margin: 0px;
  margin-top: 2px;
`;

export default Input;
