import React, { useMemo, useCallback, useRef, useState } from 'react';
import { connect, FormikContext, getIn } from 'formik';
import styled, { css } from 'styled-components';
import get from 'lodash/get';

import { useDebounce } from '@confy/hooks';

import placeholderIcon from '../../assets/placeholder.png';
import placeholderActiveIcon from '../../assets/placeholder-active.png';

import TextField from './TextField';

export interface Props<T> {
  name: string;
  helperText?: string;
  placeholder?: string;
  formik: FormikContext<any>;
  getText(value: T): string | null | undefined;
  getId(value: T): string;
  search(searchString: string, signal: AbortSignal): Promise<T[]>;
}
const optionsBorderColor = '#eee';

const OptionImage = styled.div`
  height: 21px;
  width: 21px;
  background-image: url('${placeholderIcon}');
  background-repeat: no-repeat;
  background-position: 50%;
  margin-right: 12px;
`;

const OptionCommon = css`
  padding: 20px;
  flex: 1;
  display: flex;
  align-items: center;
  color: ${p => p.theme.primaryTitle};
`;

const OptionMessage = styled.div`
  ${OptionCommon}
`;

const OptionButton = styled.div`
  cursor: pointer;
  ${OptionCommon}

  &:hover {
    color: #6100f3;
    ${OptionImage} {
      background-image: url('${placeholderActiveIcon}');
    }
  }

  :not(:last-child) {
    border-bottom: 1px solid ${optionsBorderColor};
  }
`;

const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-top: -27px; /* half the text field size */
  padding-top: 27px; /* half the text field size */
  width: 100%;
  border-right: 1px solid ${optionsBorderColor};
  border-left: 1px solid ${optionsBorderColor};
  border-bottom: 1px solid ${optionsBorderColor};
  border-bottom-left-radius: 27px; /* half the text field size */
  border-bottom-right-radius: 27px; /* half the text field size */
`;

function Option({ value, getText, onSelect }) {
  const text = useMemo(() => getText(value), [value, getText]);
  const handleClick = useCallback(() => {
    onSelect(value);
  }, [onSelect, value]);
  return (
    <OptionButton onClick={handleClick}>
      <OptionImage /> {text}
    </OptionButton>
  );
}
const StyledTextField = styled(TextField)`
  z-index: 5;
`;

function AutoCompleteField<T>({ name, formik, getId, getText, search, helperText, ...otherProps }: Props<T>) {
  const error = useMemo(() => getIn(formik.errors, name), [formik.errors, name]);
  const value: T = useMemo(() => getIn(formik.values, name), [formik.values, name]);
  const wasTouched = useMemo(() => formik.touched && !!get(formik.touched, name), [formik.touched, name]);
  const [textValue, setTextValue] = useState(() => (value && getText && getText(value)) || '');
  const [isOpen, setOpen] = useState(false);
  const [isEmpty, setEmpty] = useState(() => isStringEmpty(textValue));
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState<T[]>();
  const open = useCallback(() => setOpen(true), []);
  const close = useCallback(() => setOpen(false), []);

  const abortRef = useRef<AbortController | null>();

  const triggerNewSearch = useDebounce(
    useCallback(
      (text: string) => {
        const abortController = new AbortController();
        abortRef.current = abortController;
        setLoading(true);
        setEmpty(false);
        search(text, abortController.signal)
          .then(results => {
            if (!abortController.signal.aborted) {
              setResults(results);
            }
          })
          .finally(() => setLoading(false));
      },
      [search],
    ),
    500,
    { leading: false },
  );

  const handleChange = useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      const text = evt.target.value;
      setTextValue(text);
      if (abortRef.current != null) {
        abortRef.current.abort();
        abortRef.current = null;
      }
      if (isStringEmpty(text)) {
        setEmpty(true);
        return;
      }
      triggerNewSearch(text);
    },
    [triggerNewSearch],
  );

  const handleSelectOption = useCallback(
    (value: T) => {
      formik.setFieldValue(name, value);
      formik.setFieldTouched(name);
      setTextValue(getText(value) || '');
      close();
    },
    [formik, name, getText, close],
  );

  return (
    <>
      <StyledTextField
        onChange={handleChange}
        value={textValue}
        error={!!error && wasTouched}
        helperText={wasTouched ? error || helperText : null}
        name={name}
        onFocus={open}
        {...otherProps}
      />
      {isOpen && !isEmpty ? (
        <OptionsContainer>
          {loading ? (
            <OptionMessage>Loading</OptionMessage>
          ) : results == null || results.length === 0 ? (
            <OptionMessage>Nothing found</OptionMessage>
          ) : (
            results.map((result, index) => (
              <Option key={getId(result) || index} value={result} getText={getText} onSelect={handleSelectOption} />
            ))
          )}
        </OptionsContainer>
      ) : null}
    </>
  );
}

const ConnectedAutoCompleteField = (connect(AutoCompleteField) as any) as <T>(
  props: Omit<Props<T>, 'formik'>,
) => React.ReactElement;

export default ConnectedAutoCompleteField;

function isStringEmpty(text: string) {
  return !text || text.trim().length <= 1;
}
