/* tslint:disable */
/* @relayHash 9ae669b45957f073421ab18d405332e0 */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type event_EventEditCreateTicket_QueryVariables = {
    slug: string;
};
export type event_EventEditCreateTicket_QueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"EventEditCreateTicket_query">;
};
export type event_EventEditCreateTicket_Query = {
    readonly response: event_EventEditCreateTicket_QueryResponse;
    readonly variables: event_EventEditCreateTicket_QueryVariables;
};



/*
query event_EventEditCreateTicket_Query(
  $slug: ID!
) {
  ...EventEditCreateTicket_query_20J5Pl
}

fragment EventEditCreateTicket_query_20J5Pl on Query {
  event(id: $slug) {
    tickets {
      edges {
        node {
          id
        }
      }
    }
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "slug",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "event_EventEditCreateTicket_Query",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "EventEditCreateTicket_query",
        "args": [
          {
            "kind": "Variable",
            "name": "slug",
            "variableName": "slug"
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "event_EventEditCreateTicket_Query",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "event",
        "storageKey": null,
        "args": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "slug"
          }
        ],
        "concreteType": "Event",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "tickets",
            "storageKey": null,
            "args": null,
            "concreteType": "EventTicketConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "EventTicketEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "EventTicket",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/)
                    ]
                  }
                ]
              }
            ]
          },
          (v1/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "event_EventEditCreateTicket_Query",
    "id": null,
    "text": "query event_EventEditCreateTicket_Query(\n  $slug: ID!\n) {\n  ...EventEditCreateTicket_query_20J5Pl\n}\n\nfragment EventEditCreateTicket_query_20J5Pl on Query {\n  event(id: $slug) {\n    tickets {\n      edges {\n        node {\n          id\n        }\n      }\n    }\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '5c0e3887285b1fb41215d8f48120b7fc';
export default node;
