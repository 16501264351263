import React from 'react';

import { Row, Col, Divider } from 'antd';

import CommonText from './CommonText';
import Space from './Space';

const InfoBox = props => {
  const { data } = props;
  return (
    <Row type="flex" justify="center" align="middle">
      {data.map((info, index) => (
        <>
          {index > 0 && <Divider type="vertical" />}
          <Space width={15} />
          <Col>
            <CommonText color="primaryTitle" textSize="labelSize" center>
              {info.value}
            </CommonText>

            <CommonText color="disabledText" textSize="labelSize" center>
              {info.label}
            </CommonText>
          </Col>
          <Space width={15} />
        </>
      ))}
    </Row>
  );
};

export default InfoBox;
