import React from 'react';
import styled from 'styled-components';
import { Card } from 'antd';

const FlatCard = styled(Card).attrs({
  bordered: false,
})`
  .ant-card-body {
    padding: 20px !important;
  }
  padding-bottom: 40px !important;
`;

export default FlatCard;
