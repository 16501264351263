import { graphql } from 'react-relay';

import { renderRelayComponent } from '@confy/relay-ssr';

const EventDetailQuery = graphql`
  query event_EventDetail_Query($slug: ID!) {
    ...EventDetail_query @arguments(slug: $slug)
  }
`;

const EventBasicInfosQuery = graphql`
  query event_EventBasicInfos_Query($slug: ID!) {
    ...EventBasicInfos_query @arguments(slug: $slug)
  }
`;

const EventDiscussQuery = graphql`
  query event_EventDiscuss_Query($slug: ID!, $first: Int!) {
    ...EventDiscuss_query @arguments(slug: $slug, first: $first)
  }
`;

const YourEventsQuery = graphql`
  query event_YourEvents_Query($first: Int!, $search: String) {
    ...YourEvents_query @arguments(first: $first, search: $search)
  }
`;

const PopularEventsQuery = graphql`
  query event_PopularEvents_Query($first: Int!, $search: String, $recentFirst: Int!) {
    ...PopularEvents_query @arguments(first: $first, search: $search, recentFirst: $recentFirst)
  }
`;

const EventEditBasicQuery = graphql`
  query event_EventEditBasic_Query($slug: ID!) {
    ...EventEditBasic_query @arguments(slug: $slug)
  }
`;

const EventEditRaffleQuery = graphql`
  query event_EventEditRaffle_Query($slug: ID!) {
    ...EventEditRaffle_query @arguments(slug: $slug)
  }
`;

const EventAttendeesAllQuery = graphql`
  query event_EventAttendeesAll_Query($slug: ID!, $first: Int!, $search: String, $filters: EventUsersFilters) {
    ...EventAttendeesAll_query @arguments(slug: $slug, first: $first, search: $search, filters: $filters)
  }
`;

const EventAttendeesAttendedQuery = graphql`
  query event_EventAttendeesAttended_Query($slug: ID!, $first: Int!, $search: String) {
    ...EventAttendeesAttended_query @arguments(slug: $slug, first: $first, search: $search)
  }
`;

const EventAttendeesLikedQuery = graphql`
  query event_EventAttendeesLiked_Query($slug: ID!, $first: Int!, $search: String) {
    ...EventAttendeesLiked_query @arguments(slug: $slug, first: $first, search: $search)
  }
`;

const EventEditMediaQuery = graphql`
  query event_EventEditMedia_Query($slug: ID!) {
    ...EventEditMedia_query @arguments(slug: $slug)
  }
`;

const EventEditCommunicationQuery = graphql`
  query event_EventEditCommunication_Query($slug: ID!) {
    ...EventEditCommunication_query @arguments(slug: $slug)
  }
`;

const EventParticipantsQuery = graphql`
  query event_EventParticipants_Query($slug: ID!, $first: Int!, $search: String) {
    ...EventParticipants_query @arguments(slug: $slug, first: $first, search: $search)
  }
`;

const EventFormQuery = graphql`
  query event_EventForm_Query($slug: ID!) {
    ...EventForm_query @arguments(slug: $slug)
  }
`;

const EventEditCreateTicketQuery = graphql`
  query event_EventEditCreateTicket_Query($slug: ID!) {
    ...EventEditCreateTicket_query @arguments(slug: $slug)
  }
`;

const EventEditListTicketsQuery = graphql`
  query event_EventEditListTickets_Query($slug: SlugOrId!) {
    ...EventEditListTickets_query @arguments(slug: $slug)
  }
`;

const event = [
  {
    name: 'event.add',
    path: 'event/add',
    getComponent: () => import('../modules/event/EventAdd').then(m => m.default),
  },
  {
    name: 'event',
    path: 'event/:slug',
    getComponent: () => import('../modules/event/EventDetail').then(m => m.default),
    query: EventDetailQuery,
    render: renderRelayComponent,
    children: [
      {
        name: 'event.detail',
        path: '',
        getComponent: () => import('../modules/event/EventBasicInfos').then(m => m.default),
        query: EventBasicInfosQuery,
        render: renderRelayComponent,
      },
      {
        name: 'event.detail.discuss',
        path: 'discuss',
        getComponent: () => import('../modules/event/EventDiscuss').then(m => m.default),
        query: EventDiscussQuery,
        prepareVariables: params => ({ ...params, first: 10 }),
        render: renderRelayComponent,
      },
      {
        name: 'event.detail.confirmed',
        path: 'confirmed',
        getComponent: () => import('../modules/event/EventAttendeesAttended').then(m => m.default),
        query: EventAttendeesAttendedQuery,
        prepareVariables: params => ({ ...params, first: 10 }),
        render: renderRelayComponent,
      },
    ],
  },
  {
    name: 'event.form',
    path: 'event/:slug/form',
    getComponent: () => import('../modules/event/EventForm').then(m => m.default),
    query: EventFormQuery,
    render: renderRelayComponent,
  },
  {
    name: 'event.attendees',
    path: 'event/:slug/attendees',
    getComponent: () => import('../modules/event/EventAttendeesTab').then(m => m.default),
    children: [
      {
        name: 'event.attendees.all',
        path: 'all',
        getComponent: () => import('../modules/event/EventAttendeesAll').then(m => m.default),
        query: EventAttendeesAllQuery,
        prepareVariables: params => ({ ...params, first: 10, filters: { all: true } }),
        render: renderRelayComponent,
      },
      {
        name: 'event.attendees.confirmed',
        path: 'confirmed',
        getComponent: () => import('../modules/event/EventAttendeesAttended').then(m => m.default),
        query: EventAttendeesAttendedQuery,
        prepareVariables: params => ({ ...params, first: 10 }),
        render: renderRelayComponent,
      },
      {
        name: 'event.attendees.interested',
        path: 'interested',
        getComponent: () => import('../modules/event/EventAttendeesLiked').then(m => m.default),
        query: EventAttendeesLikedQuery,
        prepareVariables: params => ({ ...params, first: 10 }),
        render: renderRelayComponent,
      },
    ],
  },
  {
    name: 'event.edit',
    path: 'event/:slug/edit',
    getComponent: () => import('../modules/event/edit/EventEdit').then(m => m.default),
    children: [
      {
        name: 'event.edit.raffle',
        path: 'raffle',
        getComponent: () => import('../modules/event/edit/EventEditRaffle').then(m => m.default),
        query: EventEditRaffleQuery,
        render: renderRelayComponent,
      },
      {
        name: 'event.edit.basic',
        path: 'basic',
        getComponent: () => import('../modules/event/edit/EventEditBasic').then(m => m.default),
        query: EventEditBasicQuery,
        render: renderRelayComponent,
      },
      {
        name: 'event.edit.media',
        path: 'media',
        getComponent: () => import('../modules/event/edit/EventEditMedia').then(m => m.default),
        query: EventEditMediaQuery,
        render: renderRelayComponent,
      },
      {
        name: 'event.edit.participants',
        path: 'participants',
        getComponent: () => import('../modules/event/EventParticipants').then(m => m.default),
        query: EventParticipantsQuery,
        render: renderRelayComponent,
        prepareVariables: params => ({ ...params, first: 10 }),
      },
      {
        name: 'event.edit.tickets',
        path: 'tickets',
        children: [
          {
            name: 'event.edit.tickets.create',
            path: 'create',
            getComponent: () => import('../modules/event/edit/tickets/EventEditCreateTicket').then(m => m.default),
            query: EventEditCreateTicketQuery,
            render: renderRelayComponent,
          },
          {
            name: 'event.edit.tickets.list',
            path: 'list',
            getComponent: () => import('../modules/event/edit/tickets/EventEditListTickets').then(m => m.default),
            query: EventEditListTicketsQuery,
            render: renderRelayComponent,
          },
        ],
      },
      {
        name: 'event.edit.communication',
        path: 'communication',
        getComponent: () => import('../modules/event/edit/EventEditCommunication').then(m => m.default),
        query: EventEditCommunicationQuery,
        render: renderRelayComponent,
      },
      {
        name: 'event.edit.remove',
        path: 'remove',
        getComponent: () => import('../modules/event/EventRemoveModal').then(m => m.default),
      },
    ],
  },
  {
    name: 'events',
    path: 'events',
    getComponent: () => import('../modules/event/Events').then(m => m.default),
    children: [
      {
        name: 'events.popular',
        path: 'popular',
        getComponent: () => import('../modules/event/PopularEvents').then(m => m.default),
        query: PopularEventsQuery,
        prepareVariables: params => ({ ...params, recentFirst: 6, first: 6 }),
        render: renderRelayComponent,
      },
      {
        name: 'events.yours',
        path: 'yours',
        getComponent: () => import('../modules/event/YourEvents').then(m => m.default),
        query: YourEventsQuery,
        prepareVariables: params => ({ ...params, first: 6 }),
        render: renderRelayComponent,
      },
    ],
  },
];

export default event;
