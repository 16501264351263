const eventRaffle = {
  CreateNewRaffle: 'Create new raffle',
  NonZero: 'Non zero',
  NumberOfResults: 'Number of results',
  PickAtendees: 'Run raffle',
  PreviousResults: 'Previous results',
  Raffle: 'Raffle',
  RaffleName: 'Raffle name',
  RafflePreferences: 'Raffle preferences',
  Required: 'Required',
  SaveResultToHistory: 'Save result to history',
  SendNotificationToSelectedAttendees: 'Send notification to selected attendees',
  ShirtRaffle: 'Shirt raffle',
  SubmitSuccess: 'Raffle created',
  Submitfail: 'Oops, an error occured creating this raffle',
  TeamAdminsCanBeSelectedInTheRaffle: 'Team admins can be selected in the raffle',
  ThereMustBeAtLeastOneWinner: 'There must be at least one winner',
};
export default eventRaffle;
export type Keys = keyof typeof eventRaffle;
