/* tslint:disable */
/* @relayHash 72d6613fabbebb334ea2fe322da25b12 */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type event_EventParticipants_QueryVariables = {
    slug: string;
    first: number;
    search?: string | null;
};
export type event_EventParticipants_QueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"EventParticipants_query">;
};
export type event_EventParticipants_Query = {
    readonly response: event_EventParticipants_QueryResponse;
    readonly variables: event_EventParticipants_QueryVariables;
};



/*
query event_EventParticipants_Query(
  $slug: ID!
  $first: Int!
  $search: String
) {
  ...EventParticipants_query_pniqo
}

fragment EventExportParticipantsButton_event on Event {
  title
  eventForm {
    questions
    id
  }
}

fragment EventParticipants_query_pniqo on Query {
  eventUsers(event: $slug, first: $first, search: $search) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      node {
        id
        name
        email
        hasAttended(event: $slug)
        hasLiked(event: $slug)
        eventUser(event: $slug) {
          id
          answers
          ...ParticipantRow_eventUser
        }
        ...ParticipantRow_user
        __typename
      }
      cursor
    }
  }
  event(id: $slug) {
    eventForm {
      questions
      id
    }
    ...ParticipantRow_event
    ...EventExportParticipantsButton_event
    id
  }
}

fragment ParticipantRow_event on Event {
  eventForm {
    questions
    id
  }
}

fragment ParticipantRow_eventUser on EventUser {
  id
  answers
}

fragment ParticipantRow_user on User {
  id
  name
  profileImage {
    __typename
    ... on SocialFile {
      url
      id
    }
    ... on File {
      url
    }
    ... on Node {
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "slug",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "search",
    "type": "String",
    "defaultValue": null
  }
],
v1 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v2 = {
  "kind": "Variable",
  "name": "search",
  "variableName": "search"
},
v3 = {
  "kind": "Variable",
  "name": "event",
  "variableName": "slug"
},
v4 = [
  (v3/*: any*/),
  (v1/*: any*/),
  (v2/*: any*/)
],
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v6 = [
  (v3/*: any*/)
],
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "__typename",
  "args": null,
  "storageKey": null
},
v8 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "url",
    "args": null,
    "storageKey": null
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "event_EventParticipants_Query",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "EventParticipants_query",
        "args": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "kind": "Variable",
            "name": "slug",
            "variableName": "slug"
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "event_EventParticipants_Query",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "eventUsers",
        "storageKey": null,
        "args": (v4/*: any*/),
        "concreteType": "UserConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "pageInfo",
            "storageKey": null,
            "args": null,
            "concreteType": "PageInfoExtended",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "hasNextPage",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "endCursor",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "UserEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "User",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "name",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "email",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "hasAttended",
                    "args": (v6/*: any*/),
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "hasLiked",
                    "args": (v6/*: any*/),
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "eventUser",
                    "storageKey": null,
                    "args": (v6/*: any*/),
                    "concreteType": "EventUser",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "answers",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "profileImage",
                    "storageKey": null,
                    "args": null,
                    "concreteType": null,
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      (v5/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "type": "SocialFile",
                        "selections": (v8/*: any*/)
                      },
                      {
                        "kind": "InlineFragment",
                        "type": "File",
                        "selections": (v8/*: any*/)
                      }
                    ]
                  },
                  (v7/*: any*/)
                ]
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "cursor",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      },
      {
        "kind": "LinkedHandle",
        "alias": null,
        "name": "eventUsers",
        "args": (v4/*: any*/),
        "handle": "connection",
        "key": "EventParticipants_eventUsers",
        "filters": []
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "event",
        "storageKey": null,
        "args": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "slug"
          }
        ],
        "concreteType": "Event",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "eventForm",
            "storageKey": null,
            "args": null,
            "concreteType": "EventForm",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "questions",
                "args": null,
                "storageKey": null
              },
              (v5/*: any*/)
            ]
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "title",
            "args": null,
            "storageKey": null
          },
          (v5/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "event_EventParticipants_Query",
    "id": null,
    "text": "query event_EventParticipants_Query(\n  $slug: ID!\n  $first: Int!\n  $search: String\n) {\n  ...EventParticipants_query_pniqo\n}\n\nfragment EventExportParticipantsButton_event on Event {\n  title\n  eventForm {\n    questions\n    id\n  }\n}\n\nfragment EventParticipants_query_pniqo on Query {\n  eventUsers(event: $slug, first: $first, search: $search) {\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n    edges {\n      node {\n        id\n        name\n        email\n        hasAttended(event: $slug)\n        hasLiked(event: $slug)\n        eventUser(event: $slug) {\n          id\n          answers\n          ...ParticipantRow_eventUser\n        }\n        ...ParticipantRow_user\n        __typename\n      }\n      cursor\n    }\n  }\n  event(id: $slug) {\n    eventForm {\n      questions\n      id\n    }\n    ...ParticipantRow_event\n    ...EventExportParticipantsButton_event\n    id\n  }\n}\n\nfragment ParticipantRow_event on Event {\n  eventForm {\n    questions\n    id\n  }\n}\n\nfragment ParticipantRow_eventUser on EventUser {\n  id\n  answers\n}\n\nfragment ParticipantRow_user on User {\n  id\n  name\n  profileImage {\n    __typename\n    ... on SocialFile {\n      url\n      id\n    }\n    ... on File {\n      url\n    }\n    ... on Node {\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = 'd359e7d8a783a69c746456b5adbc8b37';
export default node;
