import React from 'react';
import styled from 'styled-components';

import close from '../../assets/ion-ios-close.png';

const CloseImage = styled.img.attrs({
  src: close,
})`
  width: 40px;
  height: 40px;
`;

interface Props {
  text?: string;
  isLoading?: boolean;
  disabled?: boolean;
  css?: any;
  textCss?: any;
  onClick?: () => any;
}

const CloseButton = (props: Props) => {
  return (
    <Wrapper {...props}>
      <CloseImage />
    </Wrapper>
  );
};

const Wrapper = styled.div<Props>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 30px;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  box-shadow: ${p => p.theme.shadow};
  background-color: white;
  margin-top: -8px;
  margin-right: -8px;
  ${p => p.css};
`;

export default CloseButton;
