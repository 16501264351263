import queryMiddleware from 'farce/lib/queryMiddleware';
import createRender from 'found/lib/createRender';
import { graphql } from 'react-relay';

import { renderRelayComponent } from '@confy/relay-ssr';

import auth from './auth';
import home from './home';
import organization from './organization';
import event from './event';
import user from './user';
import search from './search';
import redirect from './redirect';
import notFound from './notFound';

export const historyMiddlewares = [queryMiddleware];

const AppQuery = graphql`
  query router_App_Query {
    ...App_query
  }
`;

export const routeConfig = [
  {
    name: 'root',
    path: '/',
    getComponent: () => import('../App').then(m => m.default),
    query: AppQuery,
    children: [...home, ...search, ...organization, ...event, ...user, ...auth, ...redirect, ...notFound],
    render: renderRelayComponent,
  },
];

export const render = createRender({});
