import React from 'react';
import { Divider, Col, Row, Icon } from 'antd';

import CommonText from './CommonText';
import Space from './Space';

interface Props {
  icon?: string;
  title: string;
}

const SectionTitle: React.FC<Props> = ({ icon, title, children }) => {
  return (
    <Col>
      <Row type="flex" justify="flex-start" align="middle">
        {icon ? <Icon type={icon} /> : children}
        <Space width={10} />
        <CommonText textSize="sectionSize" color="accent" weight="600">
          {title}
        </CommonText>
      </Row>
      <Divider />
    </Col>
  );
};

export default SectionTitle;
