import React, { useState } from 'react';
import styled, { DefaultTheme, ThemeProps, FlattenInterpolation } from 'styled-components';
import { Icon, Input } from 'antd';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

const InputComponent = styled(Input)`
  background: #f8f8f8;
  border-width: 0px !important;
  color: #363636;
  padding-left: 10px;
  min-width: 90px;
  width: 100%;
  :active,
  :focus {
    border-width: 0px !important;
  }
  ::placeholder {
    color: #d6d6d6;
  }
  :-ms-input-placeholder {
    color: #d6d6d6;
  }
  ::-ms-input-placeholder {
    color: #d6d6d6;
  }
  font-size: 14px;
  background: ${p => p.theme.primaryBackground};
`;

export interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  wrapperCss?: FlattenInterpolation<ThemeProps<DefaultTheme>>;
  iconCss?: FlattenInterpolation<ThemeProps<DefaultTheme>>;
  inputCss?: FlattenInterpolation<ThemeProps<DefaultTheme>>;
}

const Search = ({ ...props }: Props) => {
  const [hasOutline, setHasOutline] = useState(false);
  return (
    <Wrapper>
      <Icon type="search" style={{ color: '#D6D6D6' }} />{' '}
      <InputComponent
        {...props}
        onKeyUp={e => e.which === 9 /* tab */ && setHasOutline(true)}
        hasOutline={hasOutline}
      />
    </Wrapper>
  );
};

export default Search;
