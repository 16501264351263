const auth = {
  ByClickingRegisterYouAgreeWithOur: 'By clicking Register you agree with our',
  Confirm: 'Confirm',
  ConfirmPassword: 'Confirm Password',
  ConfirmYourEmail: 'Confirm your email',
  EmailConfirmationMessage:
    'We sent you a confirmation email at {{email}}, be sure to click on the link to enjoy everything that Confy offers',
  EmailConfirmed: 'E-mail confirmed',
  EmailIsRequired: 'Email is required',
  ErrorConfirmingEmail: 'Error confirming e-mail',
  ErrorRegistering: 'Error registering',
  ErrorResetingPassword: 'Error resetting password',
  ErrorSendingEmail: 'Error sending email',
  ErrorSigning: 'Error signing',
  ErrorSigningWithFacebook: 'Error signing with Facebook',
  ErrorSigningWithGithub: 'Error signing with GitHub',
  FirstName: 'First name',
  ForgotPassword: 'Forgot your password?',
  LastName: 'Last name',
  LetsExperienceLifeTogether: 'Let’s experience life together',
  Login: 'Login',
  MustBeAValidEmail: 'Must be a valid email',
  NameIsRequired: 'Name is required',
  NiceMeetingYou: 'Nice meeting you!',
  Or: 'or',
  Password: 'Password',
  PasswordIsRequired: 'Password is required',
  PasswordMustHaveAtLeastXCharacters: 'Your password must have at least {{characters}} characters',
  PasswordsDoNotMatch: 'Passwords do not match',
  Register: 'Register',
  ResetPassword: 'Reset password',
  SuccessRegistering: 'Success registering',
  SuccessResetingPassword: 'Success resetting password',
  SuccessSendingEmail: 'Success sending email',
  SuccessSigning: 'Success signing',
  SuccessSigningWithFacebook: 'Success signing with Facebook',
  SuccessSigningWithGithub: 'Success signing with GitHub',
  TermsOfUse: 'Terms of use',
  WeWillSendAResetEmail: 'We will send you a reset email.',
  WelcomeBack: 'Welcome back',
};
export default auth;
export type Keys = keyof typeof auth;
