import React, { useState, useCallback } from 'react';
import { useDebounce } from '@confy/hooks';

import { refetch } from '@confy/relay-web';
import { Input, Empty } from 'antd';
import { RelayProp } from 'react-relay';

import Space from './Space';

import InfinityVerticalScroll from './InfinityVerticalScroll';

import CommonText from './CommonText';

const TOTAL_REFETCH_ITEMS = 6;
export interface InfinityScrollSectionProps<TItem = any> {
  title?: string;
  query: any;
  loadMore?: () => any;
  relay: RelayProp;
  vertical?: boolean;
  placeholder?: boolean;
  flatItems?: boolean;
  column?: boolean;
  spaced?: boolean;
  loadMoreOnClick?: boolean;
  renderItem: (item: TItem) => JSX.Element;
  loadMoreText?: string;
  emptyText?: string;
  innerColumnCss?: any;
}

const InfinityScrollSection = ({
  title,
  query,
  loadMore,
  renderItem,
  relay,
  loadMoreOnClick,
  placeholder,
  column,
  flatItems,
  loadMoreText,
  emptyText,
  spaced = true,
  innerColumnCss,
}: InfinityScrollSectionProps) => {
  const [searchValue, setSearchValue] = useState('');
  const [isRefetching, setRefetching] = useState(false);
  const { edges, pageInfo } = query;
  const { hasNextPage } = pageInfo;

  const handleSearchDebounced = useDebounce(
    useCallback(
      search => {
        setRefetching(true);
        refetch(relay, { search }, () => setRefetching(false));
      },
      [relay],
    ),
    500,
    { leading: false },
  );

  const handleSearch = useCallback(
    e => {
      setSearchValue(e.target.value);
      handleSearchDebounced(e.target.value);
    },
    [handleSearchDebounced],
  );

  const handleRefetch = query => {
    const first = query.edges.length + TOTAL_REFETCH_ITEMS;
    const { endCursor: after, hasNextPage } = query.pageInfo;
    if (!hasNextPage) {
      return;
    }
    setRefetching(true);
    refetch(relay, { first, after, search: searchValue }, () => setRefetching(false));
  };

  return (
    <>
      {placeholder && (
        <>
          <Input.Search placeholder="Search a name" onChange={handleSearch} value={searchValue} size="large" />

          <Space height={60} />
        </>
      )}
      {title && (
        <>
          <Space height={94} />
          <CommonText textSize="textSize" color="primaryTitle" weight="bold" underline center>
            {title}
          </CommonText>
          <Space height={24} />
        </>
      )}
      {spaced && <Space height={14} />}
      {edges && edges[0] ? (
        <InfinityVerticalScroll
          edges={edges}
          innerColumnCss={innerColumnCss}
          loadMore={() => (loadMore ? loadMore() : handleRefetch(query))}
          renderItem={renderItem}
          hasNextPage={hasNextPage}
          isLoading={isRefetching}
          loadMoreOnClick={loadMoreOnClick}
          column={column}
          flatItems={flatItems}
          loadMoreText={loadMoreText}
        />
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={emptyText} />
      )}
    </>
  );
};
export default InfinityScrollSection;
