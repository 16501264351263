/* tslint:disable */
/* @relayHash be9359794f2ff9e138398ae4d6cb6ecb */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type event_YourEvents_QueryVariables = {
    first: number;
    search?: string | null;
};
export type event_YourEvents_QueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"YourEvents_query">;
};
export type event_YourEvents_Query = {
    readonly response: event_YourEvents_QueryResponse;
    readonly variables: event_YourEvents_QueryVariables;
};



/*
query event_YourEvents_Query(
  $first: Int!
  $search: String
) {
  ...YourEvents_query_1UbRgV
}

fragment EventCard_event on Event {
  id
  slug
  title
  date
  coverImages {
    url
    id
  }
  address {
    shortAddress
    complement
    timezone
  }
}

fragment YourEvents_query_1UbRgV on Query {
  me {
    upcomingEvents: myEvents(first: $first, search: $search, filters: {future: true}) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          ...EventCard_event
          __typename
        }
        cursor
      }
    }
    likedEvents(first: $first) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          ...EventCard_event
          __typename
        }
        cursor
      }
    }
    pastEvents: myEvents(first: $first, search: $search, filters: {past: true}) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          ...EventCard_event
          __typename
        }
        cursor
      }
    }
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "search",
    "type": "String",
    "defaultValue": null
  }
],
v1 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v2 = {
  "kind": "Variable",
  "name": "search",
  "variableName": "search"
},
v3 = [
  {
    "kind": "Literal",
    "name": "filters",
    "value": {
      "future": true
    }
  },
  (v1/*: any*/),
  (v2/*: any*/)
],
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v5 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "pageInfo",
    "storageKey": null,
    "args": null,
    "concreteType": "PageInfoExtended",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "hasNextPage",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "endCursor",
        "args": null,
        "storageKey": null
      }
    ]
  },
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "edges",
    "storageKey": null,
    "args": null,
    "concreteType": "EventEdge",
    "plural": true,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "node",
        "storageKey": null,
        "args": null,
        "concreteType": "Event",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "slug",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "title",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "date",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "coverImages",
            "storageKey": null,
            "args": null,
            "concreteType": "File",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "url",
                "args": null,
                "storageKey": null
              },
              (v4/*: any*/)
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "address",
            "storageKey": null,
            "args": null,
            "concreteType": "Address",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "shortAddress",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "complement",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "timezone",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "__typename",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "cursor",
        "args": null,
        "storageKey": null
      }
    ]
  }
],
v6 = [
  (v1/*: any*/)
],
v7 = [
  {
    "kind": "Literal",
    "name": "filters",
    "value": {
      "past": true
    }
  },
  (v1/*: any*/),
  (v2/*: any*/)
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "event_YourEvents_Query",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "YourEvents_query",
        "args": [
          (v1/*: any*/),
          (v2/*: any*/)
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "event_YourEvents_Query",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": "upcomingEvents",
            "name": "myEvents",
            "storageKey": null,
            "args": (v3/*: any*/),
            "concreteType": "EventConnection",
            "plural": false,
            "selections": (v5/*: any*/)
          },
          {
            "kind": "LinkedHandle",
            "alias": "upcomingEvents",
            "name": "myEvents",
            "args": (v3/*: any*/),
            "handle": "connection",
            "key": "YourEvents_upcomingEvents",
            "filters": []
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "likedEvents",
            "storageKey": null,
            "args": (v6/*: any*/),
            "concreteType": "EventConnection",
            "plural": false,
            "selections": (v5/*: any*/)
          },
          {
            "kind": "LinkedHandle",
            "alias": null,
            "name": "likedEvents",
            "args": (v6/*: any*/),
            "handle": "connection",
            "key": "YourEvents_likedEvents",
            "filters": []
          },
          {
            "kind": "LinkedField",
            "alias": "pastEvents",
            "name": "myEvents",
            "storageKey": null,
            "args": (v7/*: any*/),
            "concreteType": "EventConnection",
            "plural": false,
            "selections": (v5/*: any*/)
          },
          {
            "kind": "LinkedHandle",
            "alias": "pastEvents",
            "name": "myEvents",
            "args": (v7/*: any*/),
            "handle": "connection",
            "key": "YourEvents_pastEvents",
            "filters": []
          },
          (v4/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "event_YourEvents_Query",
    "id": null,
    "text": "query event_YourEvents_Query(\n  $first: Int!\n  $search: String\n) {\n  ...YourEvents_query_1UbRgV\n}\n\nfragment EventCard_event on Event {\n  id\n  slug\n  title\n  date\n  coverImages {\n    url\n    id\n  }\n  address {\n    shortAddress\n    complement\n    timezone\n  }\n}\n\nfragment YourEvents_query_1UbRgV on Query {\n  me {\n    upcomingEvents: myEvents(first: $first, search: $search, filters: {future: true}) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          id\n          ...EventCard_event\n          __typename\n        }\n        cursor\n      }\n    }\n    likedEvents(first: $first) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          id\n          ...EventCard_event\n          __typename\n        }\n        cursor\n      }\n    }\n    pastEvents: myEvents(first: $first, search: $search, filters: {past: true}) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          id\n          ...EventCard_event\n          __typename\n        }\n        cursor\n      }\n    }\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = 'a55d300fdb2800e44d75132e855f6a51';
export default node;
