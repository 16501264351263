import React from 'react';
import { Button, Icon, Menu, Dropdown, notification } from 'antd';
import { FacebookShareButton, WhatsappShareButton, TwitterShareButton } from 'react-share';
import copy from 'copy-text-to-clipboard';

export interface Props {
  link: string;
  copyLinkText: string;
}

const ShareButton = ({ link = 'fotontech.io', copyLinkText, ...props }: Props) => {
  const menu = (
    <Menu>
      <Menu.Item key="1">
        <WhatsappShareButton url={link}>
          <Icon type="phone" />
          {` `}
          Whatsapp
        </WhatsappShareButton>
      </Menu.Item>
      <Menu.Item key="2">
        <FacebookShareButton url={link}>
          <Icon type="facebook" />
          {` `}
          Facebook
        </FacebookShareButton>
      </Menu.Item>
      <Menu.Item key="3">
        <TwitterShareButton url={link}>
          <Icon type="twitter" />
          {` `}
          Twitter
        </TwitterShareButton>
      </Menu.Item>
      <Menu.Item
        key="4"
        onClick={() => {
          copy(link);
          notification['success']({
            message: 'Success',
            description: 'You copied your organization link, feel free to share it.',
          });
        }}
      >
        <Icon type="link" />
        {copyLinkText}
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu}>
      <Button icon="share-alt" />
    </Dropdown>
  );
};

export default ShareButton;
