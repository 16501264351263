import React from 'react';

import { graphql } from 'react-relay';

import { renderRelayComponent } from '@confy/relay-ssr';

const ProfileDetailQuery = graphql`
  query user_OrganizationDetail_Query($id: ID!) {
    ...ProfileDetail_query @arguments(id: $id)
  }
`;

const ProfileEventsQuery = graphql`
  query user_ProfileEvents_Query($first: Int!, $last: Int, $id: ID!) {
    ...ProfileEvents_query @arguments(id: $id, first: $first, last: $last)
  }
`;

const ProfileOrganizationsQuery = graphql`
  query user_ProfileOrganizations_Query($first: Int!, $last: Int, $id: ID!, $search: String) {
    ...ProfileOrganizations_query @arguments(id: $id, first: $first, last: $last, search: $search)
  }
`;

const ProfileEditBasicQuery = graphql`
  query user_ProfileEditBasicQuery_Query {
    ...ProfileEditBasic_query
  }
`;

const ProfileEditMediaQuery = graphql`
  query user_ProfileEditMediaQuery_Query {
    ...ProfileEditMedia_query
  }
`;

const user = [
  {
    name: 'profile',
    path: 'profile/:id',
    getComponent: () => import('../modules/user/ProfileDetail').then(m => m.default),
    query: ProfileDetailQuery,
    render: renderRelayComponent,
    children: [
      {
        name: 'profile.events',
        path: 'events',
        getComponent: () => import('../modules/user/ProfileEvents').then(m => m.default),
        query: ProfileEventsQuery,
        prepareVariables: params => ({ ...params, first: 10 }),
        render: renderRelayComponent,
      },
      {
        name: 'profile.organizations',
        path: 'organizations',
        getComponent: () => import('../modules/user/ProfileOrganizations').then(m => m.default),
        query: ProfileOrganizationsQuery,
        prepareVariables: params => ({ ...params, first: 10 }),
        render: renderRelayComponent,
      },
    ],
  },
  {
    name: 'profile.edit',
    path: 'profile/:id/edit',
    getComponent: () => import('../modules/user/ProfileEdit').then(m => m.default),
    children: [
      {
        name: 'profile.edit.details',
        path: 'details',
        getComponent: () => import('../modules/user/ProfileEditBasic').then(m => m.default),
        query: ProfileEditBasicQuery,
        render: renderRelayComponent,
      },
      {
        name: 'profile.edit.media',
        path: 'media',
        getComponent: () => import('../modules/user/ProfileEditMedia').then(m => m.default),
        query: ProfileEditMediaQuery,
        render: renderRelayComponent,
      },
    ],
  },
];

export default user;
