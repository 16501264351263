const common = {
  AnyDate: 'Any date',
  Back: 'Back',
  BasicInformation: 'BasicInformation',
  ClickToUpload: 'Click to upload',
  CopyLink: 'Copy link',
  CreateAccount: 'Create Account',
  CurrentLocation: 'Current location',
  Email: 'Email',
  English: 'English',
  Error: 'Error',
  Events: 'Events',
  ImAlreadyUser: 'I’m already a user',
  JoinConfy: 'Join confy',
  Language: 'Language',
  LoadMore: 'Load more',
  Logout: 'Logout',
  MyProfile: 'My Profile',
  Name: 'Name',
  NewOrg: 'New Org',
  NextWeek: 'Next week',
  NoData: 'No data',
  NumberTooLarge: 'Number too large',
  PageDoesNotExist: 'Looks like this page does not exist',
  PageNotFound: 'Page not found',
  PickADate: 'Pick a date...',
  Portuguese: 'Portuguese',
  Save: 'Save',
  Search: 'Search',
  SearchAnything: 'Search anything',
  Success: 'Success',
  TermsConditions: 'Terms & Conditions',
  ThisWeek: 'This week',
  Today: 'Today',
  Tomorrow: 'Tomorrow',
  ViewAll: 'View all',
  Warning: 'Warning',
  What: 'What',
  When: 'When',
  Where: 'Where',
  YouAreLoggedOut: 'You are logged out',
  YouCanUseOutAppAsMuchAsYouWantButYouMustCreateAccount:
    'You can use out app as much as you want, but if you want to attend to any event, create an event or an org you must create an account.',
};
export default common;
export type Keys = keyof typeof common;
