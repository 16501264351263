/* tslint:disable */
/* @relayHash cc494b708609a60470d1a2d90a7e0920 */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type event_EventEditRaffle_QueryVariables = {
    slug: string;
};
export type event_EventEditRaffle_QueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"EventEditRaffle_query">;
};
export type event_EventEditRaffle_Query = {
    readonly response: event_EventEditRaffle_QueryResponse;
    readonly variables: event_EventEditRaffle_QueryVariables;
};



/*
query event_EventEditRaffle_Query(
  $slug: ID!
) {
  ...EventEditRaffle_query_20J5Pl
}

fragment EventEditRaffle_query_20J5Pl on Query {
  id
  event(id: $slug) {
    id
    slug
    title
    description
    descriptionHtml
    date
    address {
      fullAddress
      complement
    }
    maxAttendees
    ...RaffleHistory_event
  }
}

fragment RaffleHistory_event on Event {
  raffles(first: 5) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      node {
        id
        name
        createdAt
        winners {
          ...UserRow_user
          id
        }
        __typename
      }
      cursor
    }
  }
}

fragment UserProfileImage_user on User {
  id
  name
  profileImage {
    __typename
    ... on SocialFile {
      url
      id
    }
    ... on File {
      url
    }
    ... on Node {
      id
    }
  }
}

fragment UserRow_user on User {
  id
  name
  ...UserProfileImage_user
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "slug",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 5
  }
],
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "__typename",
  "args": null,
  "storageKey": null
},
v5 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "url",
    "args": null,
    "storageKey": null
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "event_EventEditRaffle_Query",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "EventEditRaffle_query",
        "args": [
          {
            "kind": "Variable",
            "name": "slug",
            "variableName": "slug"
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "event_EventEditRaffle_Query",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      (v1/*: any*/),
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "event",
        "storageKey": null,
        "args": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "slug"
          }
        ],
        "concreteType": "Event",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "slug",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "title",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "description",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "descriptionHtml",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "date",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "address",
            "storageKey": null,
            "args": null,
            "concreteType": "Address",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "fullAddress",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "complement",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "maxAttendees",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "raffles",
            "storageKey": "raffles(first:5)",
            "args": (v2/*: any*/),
            "concreteType": "EventRaffleConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "pageInfo",
                "storageKey": null,
                "args": null,
                "concreteType": "PageInfoExtended",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "hasNextPage",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "endCursor",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "EventRaffleEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "EventRaffle",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      (v3/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "createdAt",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "winners",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "User",
                        "plural": true,
                        "selections": [
                          (v1/*: any*/),
                          (v3/*: any*/),
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "profileImage",
                            "storageKey": null,
                            "args": null,
                            "concreteType": null,
                            "plural": false,
                            "selections": [
                              (v4/*: any*/),
                              (v1/*: any*/),
                              {
                                "kind": "InlineFragment",
                                "type": "SocialFile",
                                "selections": (v5/*: any*/)
                              },
                              {
                                "kind": "InlineFragment",
                                "type": "File",
                                "selections": (v5/*: any*/)
                              }
                            ]
                          }
                        ]
                      },
                      (v4/*: any*/)
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "cursor",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          },
          {
            "kind": "LinkedHandle",
            "alias": null,
            "name": "raffles",
            "args": (v2/*: any*/),
            "handle": "connection",
            "key": "RaffleHistory_raffles",
            "filters": []
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "event_EventEditRaffle_Query",
    "id": null,
    "text": "query event_EventEditRaffle_Query(\n  $slug: ID!\n) {\n  ...EventEditRaffle_query_20J5Pl\n}\n\nfragment EventEditRaffle_query_20J5Pl on Query {\n  id\n  event(id: $slug) {\n    id\n    slug\n    title\n    description\n    descriptionHtml\n    date\n    address {\n      fullAddress\n      complement\n    }\n    maxAttendees\n    ...RaffleHistory_event\n  }\n}\n\nfragment RaffleHistory_event on Event {\n  raffles(first: 5) {\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n    edges {\n      node {\n        id\n        name\n        createdAt\n        winners {\n          ...UserRow_user\n          id\n        }\n        __typename\n      }\n      cursor\n    }\n  }\n}\n\nfragment UserProfileImage_user on User {\n  id\n  name\n  profileImage {\n    __typename\n    ... on SocialFile {\n      url\n      id\n    }\n    ... on File {\n      url\n    }\n    ... on Node {\n      id\n    }\n  }\n}\n\nfragment UserRow_user on User {\n  id\n  name\n  ...UserProfileImage_user\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '54c0087e8723dfc8922c33eee52a2e95';
export default node;
