import * as React from 'react';
import { Checkbox as AntCheckbox } from 'antd';
import { CheckboxProps } from 'antd/lib/checkbox';

export type Props = CheckboxProps;

const Checkbox = (props: Props) => {
  return <AntCheckbox {...props} />;
};

export default Checkbox;
