/* tslint:disable */
/* @relayHash 8bbe9d7a3b4b6fcce8aa86469b9fc8ab */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type user_ProfileEditBasicQuery_QueryVariables = {};
export type user_ProfileEditBasicQuery_QueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"ProfileEditBasic_query">;
};
export type user_ProfileEditBasicQuery_Query = {
    readonly response: user_ProfileEditBasicQuery_QueryResponse;
    readonly variables: user_ProfileEditBasicQuery_QueryVariables;
};



/*
query user_ProfileEditBasicQuery_Query {
  ...ProfileEditBasic_query
}

fragment ProfileEditBasic_query on Query {
  me {
    id
    name
    email
  }
}
*/

const node: ConcreteRequest = {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "user_ProfileEditBasicQuery_Query",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "ProfileEditBasic_query",
        "args": null
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "user_ProfileEditBasicQuery_Query",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "name",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "email",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "user_ProfileEditBasicQuery_Query",
    "id": null,
    "text": "query user_ProfileEditBasicQuery_Query {\n  ...ProfileEditBasic_query\n}\n\nfragment ProfileEditBasic_query on Query {\n  me {\n    id\n    name\n    email\n  }\n}\n",
    "metadata": {}
  }
};
(node as any).hash = 'f14b65a5f09c442bc38ec6935b05b7bc';
export default node;
