const user = {
  BasicDetails: 'Basic details',
  ChangeCoverImage: 'Change cover image',
  ChangeProfileImage: 'Change profile image',
  EditProfile: 'Edit Profile',
  EmailIsRequired: 'Email is required',
  ErrorEditingCoverImage: 'Error editing cover image',
  ErrorEditingProfile: 'Error editing profile',
  ErrorEditingProfileImage: 'Error editing profile image',
  Events: 'Eventos',
  Help: 'Help',
  LastName: 'Last name',
  LastNameIsRequired: 'Last name is required',
  Liked: 'Liked',
  LimitOfXCharacters: 'Limit of {{characters}} characters',
  ManageProfile: 'Manage profile',
  Media: 'Media',
  Name: 'Name',
  NameIsRequired: 'Name is required',
  Occupation: 'Occupation',
  OccupationMustBeChanged: 'Occupation must be changed',
  Participants: 'Participants',
  Participating: 'Participating',
  Past: 'Past',
  Return: 'Return',
  SaveChanges: 'Save changes',
  SuccessEditingCoverImage: 'Success editing cover image',
  SuccessEditingProfile: 'Success editing profile',
  SuccessEditingProfileImage: 'Success editing profile image',
  Upcoming: 'Upcoming',
};
export default user;
export type Keys = keyof typeof user;
