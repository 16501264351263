/* tslint:disable */
/* @relayHash 561317f53113eb740bf8f43743e3fbc7 */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type home_Home_QueryVariables = {
    first: number;
    isMounted: boolean;
};
export type home_Home_QueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"Home_query">;
};
export type home_Home_Query = {
    readonly response: home_Home_QueryResponse;
    readonly variables: home_Home_QueryVariables;
};



/*
query home_Home_Query(
  $first: Int!
  $isMounted: Boolean!
) {
  ...Home_query_3vr9hU
}

fragment EventCard_event on Event {
  id
  slug
  title
  date
  coverImages {
    url
    id
  }
  address {
    shortAddress
    complement
    timezone
  }
}

fragment EventList_query_4m9VGA on Query {
  __typename
  id
  recentEvents(first: $first) @include(if: $isMounted) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      node {
        id
        ...EventCard_event
        __typename
      }
      cursor
    }
  }
}

fragment Home_query_3vr9hU on Query {
  __typename
  id
  ...OrganizationList_query_3vr9hU
  ...EventList_query_4m9VGA
}

fragment OrganizationButton_me on User {
  id
}

fragment OrganizationButton_organization on Organization {
  id
  myRoles
  name
}

fragment OrganizationCard_me on User {
  id
  ...OrganizationButton_me
}

fragment OrganizationCard_organization on Organization {
  id
  slug
  name
  ...OrganizationName_organization
  myRoles
  coverImages {
    url
    id
  }
  profileImages {
    url
    id
  }
  ...OrganizationButton_organization
}

fragment OrganizationList_query_3vr9hU on Query {
  __typename
  id
  me {
    id
    ...OrganizationCard_me
  }
  featuredOrganizations(first: $first) @include(if: $isMounted) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      node {
        id
        ...OrganizationCard_organization
        __typename
      }
      cursor
    }
  }
}

fragment OrganizationName_organization on Organization {
  id
  name
  isVerified
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "isMounted",
    "type": "Boolean!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "__typename",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v4 = [
  (v1/*: any*/)
],
v5 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "pageInfo",
  "storageKey": null,
  "args": null,
  "concreteType": "PageInfoExtended",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "hasNextPage",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "endCursor",
      "args": null,
      "storageKey": null
    }
  ]
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "slug",
  "args": null,
  "storageKey": null
},
v7 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "url",
    "args": null,
    "storageKey": null
  },
  (v3/*: any*/)
],
v8 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "coverImages",
  "storageKey": null,
  "args": null,
  "concreteType": "File",
  "plural": false,
  "selections": (v7/*: any*/)
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "cursor",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "home_Home_Query",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "Home_query",
        "args": [
          (v1/*: any*/),
          {
            "kind": "Variable",
            "name": "isMounted",
            "variableName": "isMounted"
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "home_Home_Query",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      (v2/*: any*/),
      (v3/*: any*/),
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          (v3/*: any*/)
        ]
      },
      {
        "kind": "Condition",
        "passingValue": true,
        "condition": "isMounted",
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "featuredOrganizations",
            "storageKey": null,
            "args": (v4/*: any*/),
            "concreteType": "OrganizationConnection",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "OrganizationEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Organization",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v6/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "name",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "isVerified",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "myRoles",
                        "args": null,
                        "storageKey": null
                      },
                      (v8/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "profileImages",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "File",
                        "plural": false,
                        "selections": (v7/*: any*/)
                      },
                      (v2/*: any*/)
                    ]
                  },
                  (v9/*: any*/)
                ]
              }
            ]
          },
          {
            "kind": "LinkedHandle",
            "alias": null,
            "name": "featuredOrganizations",
            "args": (v4/*: any*/),
            "handle": "connection",
            "key": "OrganizationList_featuredOrganizations",
            "filters": []
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "recentEvents",
            "storageKey": null,
            "args": (v4/*: any*/),
            "concreteType": "EventConnection",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "EventEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Event",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v6/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "title",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "date",
                        "args": null,
                        "storageKey": null
                      },
                      (v8/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "address",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Address",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "shortAddress",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "complement",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "timezone",
                            "args": null,
                            "storageKey": null
                          }
                        ]
                      },
                      (v2/*: any*/)
                    ]
                  },
                  (v9/*: any*/)
                ]
              }
            ]
          },
          {
            "kind": "LinkedHandle",
            "alias": null,
            "name": "recentEvents",
            "args": (v4/*: any*/),
            "handle": "connection",
            "key": "EventList_recentEvents",
            "filters": []
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "home_Home_Query",
    "id": null,
    "text": "query home_Home_Query(\n  $first: Int!\n  $isMounted: Boolean!\n) {\n  ...Home_query_3vr9hU\n}\n\nfragment EventCard_event on Event {\n  id\n  slug\n  title\n  date\n  coverImages {\n    url\n    id\n  }\n  address {\n    shortAddress\n    complement\n    timezone\n  }\n}\n\nfragment EventList_query_4m9VGA on Query {\n  __typename\n  id\n  recentEvents(first: $first) @include(if: $isMounted) {\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n    edges {\n      node {\n        id\n        ...EventCard_event\n        __typename\n      }\n      cursor\n    }\n  }\n}\n\nfragment Home_query_3vr9hU on Query {\n  __typename\n  id\n  ...OrganizationList_query_3vr9hU\n  ...EventList_query_4m9VGA\n}\n\nfragment OrganizationButton_me on User {\n  id\n}\n\nfragment OrganizationButton_organization on Organization {\n  id\n  myRoles\n  name\n}\n\nfragment OrganizationCard_me on User {\n  id\n  ...OrganizationButton_me\n}\n\nfragment OrganizationCard_organization on Organization {\n  id\n  slug\n  name\n  ...OrganizationName_organization\n  myRoles\n  coverImages {\n    url\n    id\n  }\n  profileImages {\n    url\n    id\n  }\n  ...OrganizationButton_organization\n}\n\nfragment OrganizationList_query_3vr9hU on Query {\n  __typename\n  id\n  me {\n    id\n    ...OrganizationCard_me\n  }\n  featuredOrganizations(first: $first) @include(if: $isMounted) {\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n    edges {\n      node {\n        id\n        ...OrganizationCard_organization\n        __typename\n      }\n      cursor\n    }\n  }\n}\n\nfragment OrganizationName_organization on Organization {\n  id\n  name\n  isVerified\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = 'a427085084c25c68c9d80a05cec46a86';
export default node;
