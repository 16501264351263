const organization = {
  About: 'Sobre',
  Addadmin: 'Adicionar administrador',
  Admins: 'Administradores',
  Back: 'Voltar',
  BasicInformation: 'Informações Básicas',
  CoverImage: 'Imagem de capa',
  Create: 'Criar',
  CreateOrg: 'Criar Org',
  Description: 'Descrição',
  DescriptionIsRequired: 'Descrição é obrigatória',
  Details: 'Detalhes',
  EditAdmins: 'Editar administradores',
  ErrorAddingOrganizationAdmin: 'Erro ao adicionar um administrador à organização',
  ErrorCreatingOrganization: 'Erro ao criar Organização',
  ErrorEditingOrganization: 'Erro ao editar organização',
  ErrorEditingOrganizationCoverImage: 'Erro ao editar a imagem de capa da organização',
  ErrorEditingOrganizationProfilePicture: 'Erro ao editar a imagem de perfil da organização',
  ErrorJoiningOrganization: 'Erro ao entrar na organização',
  ErrorLeavingOrganization: 'Erro ao sair da organização',
  ErrorRemoveOrganizationAdmin: 'Erro ao remover um administrador à organização',
  ErrorRemovingOrganizationMember: 'Erro ao remover um membro da organização',
  FeaturedOrgs: 'Orgs Destaque',
  InitialInformation: 'Informações iniciais',
  JoinOrg: 'Entrar na Org',
  LeaveOrg: 'Sair da Org',
  LimitOfXCharacters: 'Limite de {{characters}} caracteres',
  ManageMembers: 'Gerenciar membros',
  ManageOrg: 'Administrar org',
  MediaAndSEO: 'Mídia & SEO',
  Members: 'Membros',
  MembersAndAdmins: 'Membros e administradores',
  Name: 'Nome',
  NameIsRequired: 'Nome é obrigatório',
  NewEvent: 'Novo evento',
  NewOrgs: 'Novas Orgs',
  OrgsImMember: 'Orgs que sou membro',
  OrgsImOwner: 'Orgs que sou dono',
  Past: 'Passados',
  PastEvents: 'Eventos Passados',
  RemoveMember: 'Remover Membro',
  Save: 'Salvar',
  SearchForMembers: 'Procure por membros',
  SuccessAddingOrganizationAdmin: 'Successo ao adicionar um administrador à organização',
  SuccessEditingOrganization: 'Sucesso ao editar organização',
  SuccessEditingOrganizationCoverImage: 'Sucesso ao editar a imagem de capa da organização',
  SuccessEditingOrganizationProfilePicture: 'Sucesso ao editar a imagem de perfil da organização',
  SuccessRemoveOrganizationAdmin: 'Successo ao remover um administrador à organização',
  SuccessRemovingOrganizationMember: 'Successo ao remover um membro da organização',
  Upcoming: 'Próximos',
  UpcomingEvents: 'Próximos Eventos',
  WeSentAnEmailToConfirmYourOrg: 'Nós enviamos um email para {{email}} para confirmar sua Org',
  YouLeftOrgName: 'Você saiu de {{name}}',
  YouSuccessfullyJoinedOrgName: 'Você agora faz parte de {{name}}',
  Yours: 'Seu',
};
export default organization;
export type Keys = keyof typeof organization;
