/* tslint:disable */
/* @relayHash 544351d577b6bd72e86a4b0e21fcf2e4 */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type DirectionEnum = "ASC" | "DESC" | "%future added value";
export type OrganizationsFilters = {
    readonly orderBy?: ReadonlyArray<OrganizationsOrderByFilter | null> | null;
};
export type OrganizationsOrderByFilter = {
    readonly sort?: string | null;
    readonly direction?: DirectionEnum | null;
};
export type search_Organizations_QueryVariables = {
    first: number;
    search?: string | null;
    filters?: OrganizationsFilters | null;
};
export type search_Organizations_QueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"Organizations_query">;
};
export type search_Organizations_Query = {
    readonly response: search_Organizations_QueryResponse;
    readonly variables: search_Organizations_QueryVariables;
};



/*
query search_Organizations_Query(
  $first: Int!
  $search: String
  $filters: OrganizationsFilters
) {
  ...Organizations_query_2XNRim
}

fragment OrganizationButton_me on User {
  id
}

fragment OrganizationButton_organization on Organization {
  id
  myRoles
  name
}

fragment OrganizationCard_me on User {
  id
  ...OrganizationButton_me
}

fragment OrganizationCard_organization on Organization {
  id
  slug
  name
  ...OrganizationName_organization
  myRoles
  coverImages {
    url
    id
  }
  profileImages {
    url
    id
  }
  ...OrganizationButton_organization
}

fragment OrganizationName_organization on Organization {
  id
  name
  isVerified
}

fragment Organizations_query_2XNRim on Query {
  me {
    ...OrganizationCard_me
    id
  }
  organizations(first: $first, search: $search, filters: $filters) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      node {
        ...OrganizationCard_organization
        id
        __typename
      }
      cursor
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "search",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "filters",
    "type": "OrganizationsFilters",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "filters",
    "variableName": "filters"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "search",
    "variableName": "search"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "url",
    "args": null,
    "storageKey": null
  },
  (v2/*: any*/)
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "search_Organizations_Query",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "Organizations_query",
        "args": (v1/*: any*/)
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "search_Organizations_Query",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "organizations",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "OrganizationConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "pageInfo",
            "storageKey": null,
            "args": null,
            "concreteType": "PageInfoExtended",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "hasNextPage",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "endCursor",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "OrganizationEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "Organization",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "slug",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "name",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "isVerified",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "myRoles",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "coverImages",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "File",
                    "plural": false,
                    "selections": (v3/*: any*/)
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "profileImages",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "File",
                    "plural": false,
                    "selections": (v3/*: any*/)
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "__typename",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "cursor",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      },
      {
        "kind": "LinkedHandle",
        "alias": null,
        "name": "organizations",
        "args": (v1/*: any*/),
        "handle": "connection",
        "key": "Organizations_organizations",
        "filters": [
          "search",
          "filters"
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "search_Organizations_Query",
    "id": null,
    "text": "query search_Organizations_Query(\n  $first: Int!\n  $search: String\n  $filters: OrganizationsFilters\n) {\n  ...Organizations_query_2XNRim\n}\n\nfragment OrganizationButton_me on User {\n  id\n}\n\nfragment OrganizationButton_organization on Organization {\n  id\n  myRoles\n  name\n}\n\nfragment OrganizationCard_me on User {\n  id\n  ...OrganizationButton_me\n}\n\nfragment OrganizationCard_organization on Organization {\n  id\n  slug\n  name\n  ...OrganizationName_organization\n  myRoles\n  coverImages {\n    url\n    id\n  }\n  profileImages {\n    url\n    id\n  }\n  ...OrganizationButton_organization\n}\n\nfragment OrganizationName_organization on Organization {\n  id\n  name\n  isVerified\n}\n\nfragment Organizations_query_2XNRim on Query {\n  me {\n    ...OrganizationCard_me\n    id\n  }\n  organizations(first: $first, search: $search, filters: $filters) {\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n    edges {\n      node {\n        ...OrganizationCard_organization\n        id\n        __typename\n      }\n      cursor\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '9d940c8a43f38c9798d68611412b2cca';
export default node;
