import React, { useState, useRef, useEffect } from 'react';
import styled, { css } from 'styled-components';
import useRouter from 'found/lib/useRouter';

import theme from '../../utils/theme';

import search from '../../assets/search-header.svg';
import darkSearch from '../../assets/search-dark.svg';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 20px;
`;

const Input = styled.input<{ isExpanded: boolean; hasOutline: boolean }>`
  border-width: 0px;
  color: ${p => (p.isDark ? theme.primaryBackground : theme.primaryTitle)};
  background-color: transparent;
  font-size: 16px;
  font-weight: 600;
  padding-left: 10px;
  width: ${p => (p.isExpanded ? 180 : 0)}px;
  opacity: ${p => (p.isExpanded ? 1 : 0)};
  transition-property: opacity, width, color;
  transition-duration: 0.5s, 0.3s;
  :focus {
    outline: ${props => (props.hasOutline ? '' : 'none')};
  }
`;

const iconStyle = css`
  width: 23px;
  height: 23px;
  cursor: pointer;
  @media (min-width: ${theme.breakpoint_800}) {
    width: 15px;
    height: 15px;
  }
`;

const WhiteIcon = styled.img.attrs({
  src: search,
})`
  ${iconStyle}
  fill: red;
`;

const DarkIcon = styled.img.attrs({
  src: darkSearch,
})`
  ${iconStyle}
`;

const ExpandableSearch = ({ isOver, transparent, ...props }) => {
  const [hasOutline, setHasOutline] = useState(false);
  const [isExpanded, setExpanded] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const { match } = useRouter();

  const handleBlur = () => {
    const { pathname } = match.location;
    if (pathname.match(/\/search\//i)) {
      return;
    }
    setExpanded(false);
  };

  useEffect(() => {
    const { pathname } = match.location;
    if (!pathname.match(/\/search\//i)) {
      setExpanded(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.location.pathname]);

  const Icon = isOver && transparent ? WhiteIcon : DarkIcon;

  return (
    <Wrapper>
      <Icon
        onClick={() => {
          if (!isExpanded) {
            inputRef.current && inputRef.current.focus();
          }
          setExpanded(!isExpanded);
        }}
      />
      <Input
        isExpanded={isExpanded}
        {...props}
        onBlur={handleBlur}
        onKeyUp={e => e.which === 9 /* tab */ && setHasOutline(true)}
        hasOutline={hasOutline}
        ref={inputRef}
        isDark={isOver && transparent}
      />
    </Wrapper>
  );
};

export default ExpandableSearch;
