import i18n, { InitOptions } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { Middleware } from 'koa';

import englishTranslation from './locales/en';
import portugueseTranslation from './locales/pt';
const options: InitOptions = {
  resources: {
    en: englishTranslation,
    pt: portugueseTranslation,
  },
  preload: ['en', 'pt'],
  fallbackLng: 'en',
  detection: {
    order: ['cookie'],
    lookupCookie: 'lang',
  },
  nsSeparator: '::',
  whitelist: ['en', 'pt'],
  nonExplicitWhitelist: true,
};

if (!i18n.isInitialized) i18n.use(LanguageDetector).init(options);

export default i18n;

export const i18nMiddleware: Middleware = async (context, next) => {
  const lang = context.cookies.get('lang');
  if (lang) await i18n.changeLanguage(lang);

  return next();
};
