const organization = {
  About: 'About',
  Addadmin: 'Add admin',
  Admins: 'Admins',
  Back: 'Back',
  BasicInformation: 'Basic Information',
  CoverImage: 'Cover image',
  Create: 'Create',
  CreateOrg: 'Create Org',
  Description: 'Description',
  DescriptionIsRequired: 'Description is required',
  Details: 'Details',
  EditAdmins: 'Edit admins',
  ErrorAddingOrganizationAdmin: 'Error adding organization admin',
  ErrorCreatingOrganization: 'Error creating Organization',
  ErrorEditingOrganization: 'Error editing Organization',
  ErrorEditingOrganizationCoverImage: 'Error editing organization cover image',
  ErrorEditingOrganizationProfilePicture: 'Error editing organization profile picture',
  ErrorJoiningOrganization: 'Error joining organization',
  ErrorLeavingOrganization: 'Error leaving organization',
  ErrorRemoveOrganizationAdmin: 'Error removing organization admin',
  ErrorRemovingOrganizationMember: 'Error removing organization member',
  FeaturedOrgs: 'Featured Orgs',
  InitialInformation: 'Initial information',
  JoinOrg: 'Join Org',
  LeaveOrg: 'Leave Org',
  LimitOfXCharacters: 'Limit of {{characters}} characters',
  ManageMembers: 'Manage members',
  ManageOrg: 'Manage org',
  MediaAndSEO: 'Media & SEO',
  Members: 'Members',
  MembersAndAdmins: 'Members and admins',
  Name: 'Name',
  NameIsRequired: 'Name is required',
  NewEvent: 'New event',
  NewOrgs: 'New Orgs',
  OrgsImMember: 'Orgs I’m member',
  OrgsImOwner: 'Orgs I’m owner',
  Past: 'Past',
  PastEvents: 'Past Events',
  RemoveMember: 'Remove Member',
  Save: 'Save',
  SearchForMembers: 'Search for members',
  SuccessAddingOrganizationAdmin: 'Success adding organization admin',
  SuccessEditingOrganization: 'Success editing Organization',
  SuccessEditingOrganizationCoverImage: 'Success editing organization cover image',
  SuccessEditingOrganizationProfilePicture: 'Success editing organization profile picture',
  SuccessRemoveOrganizationAdmin: 'Success removing organization admin',
  SuccessRemovingOrganizationMember: 'Success removing organization member',
  Upcoming: 'Upcoming',
  UpcomingEvents: 'Upcoming Events',
  WeSentAnEmailToConfirmYourOrg: 'We sent an email to {{email}} to confirm your Org',
  YouLeftOrgName: 'You left {{name}}',
  YouSuccessfullyJoinedOrgName: 'You successfully joined {{name}}',
  Yours: 'Yours',
};
export default organization;
export type Keys = keyof typeof organization;
