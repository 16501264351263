const events = {
  About: 'About',
  AbsorbFees: 'Absorb {{percentage}}% fees (Ticketing fees are deducted from your ticket revenue)',
  AddAReply: 'Add a reply',
  AddPost: 'Add Post',
  Address: 'Address',
  All: 'All',
  AllDataAndInformationForThisEventWillBePermanentlyDeleted:
    'All data and information for this event will be permanently deleted.',
  AttendEvent: 'Attend event',
  Attendees: 'Attendees',
  Back: 'Back',
  BasicInfos: 'Basic infos',
  BeforeAttendingWventAnswerQuestionsCreatedByStaff:
    'Before attending the event, you need to answer a few questions created by the Staff',
  BuyerTotal: 'Buyer total: ',
  Cancel: 'Cancel',
  CancelAttend: 'Cancel attend',
  ChooseADay: 'Choose a day',
  Communication: 'Communication',
  Complement: 'Complement',
  Confirmed: 'Confirmed',
  CoverImage: 'Cover image',
  Create: 'Create',
  CreateEvent: 'Create Event',
  CreateOrg: 'Create Org',
  CreateTicket: 'Create ticket',
  CreatedBy: 'Created By',
  CustomQuestions: 'Custom questions',
  Date: 'Date',
  DateAndTime: 'Date and Time',
  DateFormat: 'Date (mm/dd/yyyy)',
  DateIsRequired: 'Date is required',
  DeleteEvent: 'Delete Event',
  DeleteTicket: 'Delete ticket',
  Description: 'Description',
  DescriptionIsRequired: 'Description is required',
  Discuss: 'Discuss',
  DoYouWantToDeleteEvent: 'Do you want to delete event?',
  EditTicket: 'Edit ticket',
  EnableLocation: 'Enable location',
  ErrorAttendingEvent: 'Error attending event',
  ErrorCancelingEventAttend: 'Error canceling event attend',
  ErrorCreatingEvent: 'Error creating event',
  ErrorCreatingTicket: 'Error creating ticket',
  ErrorDeletingEvent: 'Error deleting event',
  ErrorEditingEvent: 'Error editing event',
  ErrorEditingEventForm: 'Error editing event form',
  ErrorEditingEventMedia: 'Error editing event media',
  EventQuestions: 'Event questions',
  EventWasDeleted: 'Event was deleted',
  EventsImInterested: 'Events I’m interested',
  ExportAllParticipantsAsCSV: 'Export all participants as .CSV',
  FreeTicket: 'Free ticket',
  GetsVisibleWhenSalesStarts: 'Gets visible when sales start',
  HasNoEnd: 'Has no end',
  Hidden: 'Hidden',
  HiddenEvenAfterSalesStart: 'Hidden even after sales start',
  IfNotProvidedDefaultWillBeX: 'If not provided, default will be {{x}}',
  InitialInformation: 'Initial information',
  Interested: 'Interested',
  IsARequiredField: 'is a required field',
  IsNotInCorrectFormat: '{{field}} is not in the correct format',
  ItCantBeBiggerThanMaxAttendeesAndTicketsAvailable:
    'It can’t be bigger than max attendees and amount of tickets available',
  JoinWaitlist: 'Join waitlist',
  LeaveWaitlist: 'Leave waitlist',
  LimitOfXCharacters: 'Limit of {{characters}} characters',
  ListAll: 'List all',
  LiveNewExperiencesWithoutLeavingYourCity:
    'Live new experiences without leaving your city! Share your location so we can search the best events around you.',
  ManageEvent: 'Manage Event',
  MaxAttendees: 'Max attendees',
  MaxTicketsPerOrder: 'Max tickets per order',
  MediaAndSEO: 'Media & SEO',
  MinTicketsPerOrder: 'Min tickets per order',
  MinimumOfXCharacters: 'Minimum of {{characters}} characters',
  MoneyPrefix: '$',
  MustBeAtLeastXCharacters: 'must be at least {{characters}} characters',
  Name: 'Name',
  NameIsRequired: 'Name is required',
  NearEvents: 'Near Events',
  NewComment: 'New comment...',
  NewEvents: 'New Events',
  OopsPleaseTryAgainLater: 'Oops, please try again later',
  PaidTicket: 'Paid ticket',
  Participants: 'Participants',
  PastEvents: 'Past Events',
  PopularEvents: 'Popular Events',
  Pricing: 'Pricing',
  Question: 'Question',
  Replies: 'replie(s)',
  Sales: 'Sales',
  SalesEnds: 'Sales ends',
  SalesStarts: 'Sales starts',
  Save: 'Save',
  SearchEvents: 'Search events',
  SearchForAnAttendee: 'Search for an attendee',
  SearchForAttendees: 'Search for attendees',
  SelectATicket: 'Select a ticket',
  ShowActions: 'Show actions',
  SoldOut: 'SOLD OUT',
  SoldTotal: 'Sold/Total',
  SorryThereIsntEventsNearYouYet: 'Sorry! There’s no events near you yet...',
  Status: 'Status',
  SuccessAttendingEvent: 'Success attending event',
  SuccessCreatingEvent: 'Success creating event',
  SuccessCreatingTicket: 'Success creating ticket',
  SuccessEditingEvent: 'Success editing event',
  SuccessEditingEventForm: 'Success editing event form',
  SuccessEditingEventMedia: 'Success editing event media',
  SuccessOnAddingNewComment: 'Success on adding new comment',
  SuccessOnReplying: 'Success on replying',
  ThanksForHavingInterest: 'Thanks for having interest',
  TheCurrentDate: 'the current date',
  TheDateOfTheEvent: 'the date of the event',
  TicketInfos: 'Ticket infos',
  TicketPrice: 'Ticket price',
  Tickets: 'Tickets',
  TimeFormat: 'Time (24h)',
  TimeIsRequired: 'Time is required',
  Title: 'Title',
  TitleIsRequired: 'Title is required',
  TooShort: 'Too short',
  TotalQuantity: 'Total quantity',
  TotalQuantityIsRequired: 'Total quantitiy is required',
  UpcomingEvents: 'Upcoming Events',
  Visible: 'Visible',
  WhatsHappeningAroundYou: 'What’s happening around you?',
  WhenSalesEndFor: 'When sales end for...',
  Where: 'Where',
  YouCanCreateFirstEventNearYou: 'You can create the first event near you or search events with different filters!',
  YouCancelledEventAttend: 'You cancelled event attend',
  YouCancelledYourEventInterest: 'You cancelled your event interest',
  Yours: 'Yours',
};
export default events;
export type Keys = keyof typeof events;
