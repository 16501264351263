const search = {
  Alphabetical: 'Alfabética (A-Z)',
  ErrorAttendingEvent: 'Erro ao confirmar presença no evento',
  ErrorCancelingEventAttend: 'Erro ao cancelar presença no evento',
  ErrorJoiningOrganization: 'Erro ao entrar na organização',
  ErrorLeavingOrganization: 'Erro ao sair da organização',
  Events: 'Eventos',
  Going: 'irão',
  ImLookingFor: 'Estou procurando por',
  Join: 'Entrar',
  Leave: 'Sair',
  Members: 'membros',
  MostPopular: 'Mais popular',
  MostRecent: 'Mais recente',
  NewEvent: 'Novo evento',
  OrderBy: 'Ordem',
  SearchSomething: 'Procure alguma coisa',
};
export default search;
export type Keys = keyof typeof search;
