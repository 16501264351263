import React from 'react';
import styled from 'styled-components';

import Select, { SelectProps } from '@material-ui/core/Select';

const Composer = styled.div<{ width?: number; isValid?: boolean; disabled?: boolean }>`
  display: flex;
  justify-content: center;
  border: 1px solid ${p => (p.isValid ? p.theme.primaryTitle : p.theme.disabled)};
  border-radius: 30px;
  min-width: ${p => p.width || 180}px;
  background: ${p => (p.isValid ? p.theme.primaryTitle : p.theme.primaryBackground)};

  .MuiSelect-selectMenu {
    color: ${p => {
      if (p.isValid) {
        return p.disabled ? 'rgba(255,255,255,0.6)' : p.theme.primaryBackground;
      } else {
        return p.disabled ? 'rgba(0,0,0,0.6)' : p.theme.primaryTitle;
      }
    }};
  }
  .MuiSelect-icon {
    color: ${p => (p.isValid ? 'rgba(255,255,255,0.54)' : 'rgba(0,0,0,0.54)')};
  }
`;

const Selector = styled(Select)`
  margin-left: 10px;
`;

interface Props extends SelectProps {
  width?: number;
}

const SelectorComponent = (props: Props) => {
  return (
    <Composer width={props.width} isValid={!!props.value} disabled={props.disabled}>
      <Selector disableUnderline displayEmpty {...props} />
    </Composer>
  );
};

export default SelectorComponent;
