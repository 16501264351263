/* tslint:disable */
/* @relayHash fcae5e2cfb6f69a87f1bb0817ea4c235 */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type event_EventEditBasic_QueryVariables = {
    slug: string;
};
export type event_EventEditBasic_QueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"EventEditBasic_query">;
};
export type event_EventEditBasic_Query = {
    readonly response: event_EventEditBasic_QueryResponse;
    readonly variables: event_EventEditBasic_QueryVariables;
};



/*
query event_EventEditBasic_Query(
  $slug: ID!
) {
  ...EventEditBasic_query_20J5Pl
}

fragment EventEditBasic_query_20J5Pl on Query {
  id
  me {
    id
    ...EventRemoveModal_me
  }
  event(id: $slug) {
    id
    slug
    title
    description
    descriptionHtml
    date
    address {
      fullAddress
      complement
      timezone
      location {
        lng
        lat
      }
    }
    maxAttendees
    ...EventRemoveModal_event
  }
}

fragment EventRemoveModal_event on Event {
  id
  organization {
    id
    slug
  }
}

fragment EventRemoveModal_me on User {
  id
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "slug",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "slug",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "event_EventEditBasic_Query",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "EventEditBasic_query",
        "args": [
          {
            "kind": "Variable",
            "name": "slug",
            "variableName": "slug"
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "event_EventEditBasic_Query",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      (v1/*: any*/),
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          (v1/*: any*/)
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "event",
        "storageKey": null,
        "args": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "slug"
          }
        ],
        "concreteType": "Event",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "title",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "description",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "descriptionHtml",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "date",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "address",
            "storageKey": null,
            "args": null,
            "concreteType": "Address",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "fullAddress",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "complement",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "timezone",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "location",
                "storageKey": null,
                "args": null,
                "concreteType": "Location",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "lng",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "lat",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "maxAttendees",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "organization",
            "storageKey": null,
            "args": null,
            "concreteType": "Organization",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "event_EventEditBasic_Query",
    "id": null,
    "text": "query event_EventEditBasic_Query(\n  $slug: ID!\n) {\n  ...EventEditBasic_query_20J5Pl\n}\n\nfragment EventEditBasic_query_20J5Pl on Query {\n  id\n  me {\n    id\n    ...EventRemoveModal_me\n  }\n  event(id: $slug) {\n    id\n    slug\n    title\n    description\n    descriptionHtml\n    date\n    address {\n      fullAddress\n      complement\n      timezone\n      location {\n        lng\n        lat\n      }\n    }\n    maxAttendees\n    ...EventRemoveModal_event\n  }\n}\n\nfragment EventRemoveModal_event on Event {\n  id\n  organization {\n    id\n    slug\n  }\n}\n\nfragment EventRemoveModal_me on User {\n  id\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '318f8bdfb54144d0749038957d570ddd';
export default node;
