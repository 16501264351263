/* tslint:disable */
/* @relayHash 38772fae6d51dc2a806afd5a4692ebbf */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type user_ProfileEvents_QueryVariables = {
    first: number;
    last?: number | null;
    id: string;
};
export type user_ProfileEvents_QueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"ProfileEvents_query">;
};
export type user_ProfileEvents_Query = {
    readonly response: user_ProfileEvents_QueryResponse;
    readonly variables: user_ProfileEvents_QueryVariables;
};



/*
query user_ProfileEvents_Query(
  $first: Int!
  $last: Int
  $id: ID!
) {
  ...ProfileEvents_query_16otCK
}

fragment EventCard_event on Event {
  id
  slug
  title
  date
  coverImages {
    url
    id
  }
  address {
    shortAddress
    complement
    timezone
  }
}

fragment ProfileEvents_query_16otCK on Query {
  user(id: $id) {
    likedEvents(first: $first, last: $last) {
      pageInfo {
        hasNextPage
        endCursor
        hasPreviousPage
        startCursor
      }
      edges {
        node {
          id
          ...EventCard_event
          __typename
        }
        cursor
      }
    }
    myFutureEvents: myEvents(first: $first, last: $last, filters: {future: true}) {
      pageInfo {
        hasNextPage
        endCursor
        hasPreviousPage
        startCursor
      }
      edges {
        node {
          id
          ...EventCard_event
          __typename
        }
        cursor
      }
    }
    myPastEvents: myEvents(first: $first, last: $last, filters: {past: true}) {
      pageInfo {
        hasNextPage
        endCursor
        hasPreviousPage
        startCursor
      }
      edges {
        node {
          id
          ...EventCard_event
          __typename
        }
        cursor
      }
    }
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "last",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v2 = {
  "kind": "Variable",
  "name": "id",
  "variableName": "id"
},
v3 = {
  "kind": "Variable",
  "name": "last",
  "variableName": "last"
},
v4 = [
  (v1/*: any*/),
  (v3/*: any*/)
],
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v6 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "pageInfo",
    "storageKey": null,
    "args": null,
    "concreteType": "PageInfoExtended",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "hasNextPage",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "endCursor",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "hasPreviousPage",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "startCursor",
        "args": null,
        "storageKey": null
      }
    ]
  },
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "edges",
    "storageKey": null,
    "args": null,
    "concreteType": "EventEdge",
    "plural": true,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "node",
        "storageKey": null,
        "args": null,
        "concreteType": "Event",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "slug",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "title",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "date",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "coverImages",
            "storageKey": null,
            "args": null,
            "concreteType": "File",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "url",
                "args": null,
                "storageKey": null
              },
              (v5/*: any*/)
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "address",
            "storageKey": null,
            "args": null,
            "concreteType": "Address",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "shortAddress",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "complement",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "timezone",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "__typename",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "cursor",
        "args": null,
        "storageKey": null
      }
    ]
  }
],
v7 = [
  {
    "kind": "Literal",
    "name": "filters",
    "value": {
      "future": true
    }
  },
  (v1/*: any*/),
  (v3/*: any*/)
],
v8 = [
  {
    "kind": "Literal",
    "name": "filters",
    "value": {
      "past": true
    }
  },
  (v1/*: any*/),
  (v3/*: any*/)
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "user_ProfileEvents_Query",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "ProfileEvents_query",
        "args": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/)
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "user_ProfileEvents_Query",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "user",
        "storageKey": null,
        "args": [
          (v2/*: any*/)
        ],
        "concreteType": "User",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "likedEvents",
            "storageKey": null,
            "args": (v4/*: any*/),
            "concreteType": "EventConnection",
            "plural": false,
            "selections": (v6/*: any*/)
          },
          {
            "kind": "LinkedHandle",
            "alias": null,
            "name": "likedEvents",
            "args": (v4/*: any*/),
            "handle": "connection",
            "key": " ProfileEvents_likedEvents",
            "filters": []
          },
          {
            "kind": "LinkedField",
            "alias": "myFutureEvents",
            "name": "myEvents",
            "storageKey": null,
            "args": (v7/*: any*/),
            "concreteType": "EventConnection",
            "plural": false,
            "selections": (v6/*: any*/)
          },
          {
            "kind": "LinkedHandle",
            "alias": "myFutureEvents",
            "name": "myEvents",
            "args": (v7/*: any*/),
            "handle": "connection",
            "key": " ProfileEvents_myFutureEvents",
            "filters": []
          },
          {
            "kind": "LinkedField",
            "alias": "myPastEvents",
            "name": "myEvents",
            "storageKey": null,
            "args": (v8/*: any*/),
            "concreteType": "EventConnection",
            "plural": false,
            "selections": (v6/*: any*/)
          },
          {
            "kind": "LinkedHandle",
            "alias": "myPastEvents",
            "name": "myEvents",
            "args": (v8/*: any*/),
            "handle": "connection",
            "key": " ProfileEvents_myPastEvents",
            "filters": []
          },
          (v5/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "user_ProfileEvents_Query",
    "id": null,
    "text": "query user_ProfileEvents_Query(\n  $first: Int!\n  $last: Int\n  $id: ID!\n) {\n  ...ProfileEvents_query_16otCK\n}\n\nfragment EventCard_event on Event {\n  id\n  slug\n  title\n  date\n  coverImages {\n    url\n    id\n  }\n  address {\n    shortAddress\n    complement\n    timezone\n  }\n}\n\nfragment ProfileEvents_query_16otCK on Query {\n  user(id: $id) {\n    likedEvents(first: $first, last: $last) {\n      pageInfo {\n        hasNextPage\n        endCursor\n        hasPreviousPage\n        startCursor\n      }\n      edges {\n        node {\n          id\n          ...EventCard_event\n          __typename\n        }\n        cursor\n      }\n    }\n    myFutureEvents: myEvents(first: $first, last: $last, filters: {future: true}) {\n      pageInfo {\n        hasNextPage\n        endCursor\n        hasPreviousPage\n        startCursor\n      }\n      edges {\n        node {\n          id\n          ...EventCard_event\n          __typename\n        }\n        cursor\n      }\n    }\n    myPastEvents: myEvents(first: $first, last: $last, filters: {past: true}) {\n      pageInfo {\n        hasNextPage\n        endCursor\n        hasPreviousPage\n        startCursor\n      }\n      edges {\n        node {\n          id\n          ...EventCard_event\n          __typename\n        }\n        cursor\n      }\n    }\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = 'a9faac882aba35c79888ac4a0f6ab449';
export default node;
