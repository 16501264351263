import React from 'react';
import styled, { css } from 'styled-components';

import useRouter from 'found/lib/useRouter';

import notlogged from '../../assets/not-logged.jpg';

import CommonText from './CommonText';
import FadeImage from './FadeImage';
import Button from './Button';
import Space from './Space';
import Link from './Link';

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  max-width: 900px;
  width: 900px;
  margin: auto;
  @media (max-width: 1000px) {
    width: calc(100% - 20px);
    padding: 10px;
  }
`;

const buttonCss = css`
  width: 200px;
`;

const NotLoggedImg = styled(FadeImage)`
  height: 200px;
  object-fit: cover;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  align-items: center;
  @media (max-width: 500px) {
    flex-direction: column-reverse;
    align-items: center;
  }
`;

const textCss = css`
  text-align: center;
`;

interface Props {
  titleText: string;
  detailedText: string;
  alreadyUserText: string;
  createAccountText: string;
}

const NotLogged = ({ titleText, detailedText, alreadyUserText, createAccountText }: Props) => {
  const { match } = useRouter();

  return (
    <Wrapper>
      <NotLoggedImg src={notlogged} />
      <Space height={20} />
      <CommonText color="primaryTitle" textSize="subsectionSize" textCss={textCss} weight="bold">
        {titleText}
      </CommonText>
      <Space height={20} />
      <CommonText color="primaryText" textSize="textSize" textCss={textCss}>
        {detailedText}
      </CommonText>
      <Space height={30} />
      <ButtonsWrapper>
        <Link to={`/auth/login?r=${match.location.pathname}`}>
          <CommonText color="primaryTitle" textSize="textSize">
            {alreadyUserText}
          </CommonText>
        </Link>
        <Space width={40} />
        <Space height={20} />
        <Link to={`/auth/register?r=${match.location.pathname}`}>
          <Button text={createAccountText} css={buttonCss} />
        </Link>
      </ButtonsWrapper>
    </Wrapper>
  );
};

export default NotLogged;
