import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import mixpanel from 'mixpanel-browser';

import notFound from '../../assets/404.jpg';

import CommonText from './CommonText';
import Space from './Space';
import FadeImage from './FadeImage';

const Wrapper = styled.div<{ css?: any }>`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;
  ${p => p.css || null}
`;

const NotLoggedImg = styled(FadeImage)`
  height: 200px;
  object-fit: cover;
`;

const textCss = css`
  text-align: center;
`;

interface Props {
  pageNotFoundText: string;
  pageDoesNotExistText: string;
}

const NotFound = ({ pageNotFoundText, pageDoesNotExistText }: Props) => {
  useEffect(() => {
    try {
      mixpanel.track('Not found');
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('Mixpanel error:', err);
    }
    amplitude.getInstance().logEvent('Not found');
  }, []);

  return (
    <Wrapper>
      <NotLoggedImg src={notFound} />
      <Space height={20} />
      <CommonText color="primaryTitle" textSize="subsectionSize" textCss={textCss} weight="bold">
        {pageNotFoundText}
      </CommonText>
      <Space height={20} />
      <CommonText color="primaryText" textSize="textSize" textCss={textCss}>
        {pageDoesNotExistText}
      </CommonText>
    </Wrapper>
  );
};

export default NotFound;
