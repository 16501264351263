import React, { useState } from 'react';
import styled from 'styled-components';

import { SelectProps } from '@material-ui/core/Select';

import { differenceInDays, addDays, addWeeks, lastDayOfWeek, startOfWeek, format } from 'date-fns';

import DateRangePicker from './DateRangePicker';
import Selector from './Selector';
import SelectorItem from './SelectorItem';

const Wrapper = styled.div<{ disabled?: boolean }>`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: relative;
  ${p => p.disabled && 'opacity: 0.6;'}
`;

export type PreDefinedDates = 'any-date' | 'today' | 'tomorrow' | 'week' | 'next-week' | 'pick';
export function isPreDefinedDates(value: PreDefinedDates | unknown): value is PreDefinedDates {
  return (
    (value as PreDefinedDates) === 'any-date' ||
    (value as PreDefinedDates) === 'next-week' ||
    (value as PreDefinedDates) === 'pick' ||
    (value as PreDefinedDates) === 'today' ||
    (value as PreDefinedDates) === 'tomorrow' ||
    (value as PreDefinedDates) === 'week'
  );
}

interface Props extends SelectProps {
  initialPreDefinedDate?: PreDefinedDates;
  startDate: Date | null;
  setStartDate: (date: Date | null) => void;
  endDate: Date | null;
  setEndDate: (date: Date | null) => void;
  afterChange?: (value: PreDefinedDates, startDate: Date | null, endDate: Date | null) => void;
  anyDateText: string;
  todayText: string;
  tomorrowText: string;
  thisWeekText: string;
  nextWeekText: string;
  pickADateText: string;
}

const DateSelector = ({
  initialPreDefinedDate,
  setStartDate,
  setEndDate,
  startDate,
  endDate,
  afterChange,
  anyDateText,
  todayText,
  tomorrowText,
  thisWeekText,
  nextWeekText,
  pickADateText,
  ...props
}: Props) => {
  const [preDefinedDate, setPreDefinedDate] = useState<PreDefinedDates>(initialPreDefinedDate || 'any-date');

  const [openDatePicker, setOpenDatePicker] = useState(false);

  const [pickDatePlaceholder, setDatePlaceholder] = useState(pickADateText);

  const handleDateSelectorChange = ({
    target: { value },
  }: React.ChangeEvent<{
    name?: string | undefined;
    value: PreDefinedDates | unknown;
  }>) => {
    if (!isPreDefinedDates(value)) return;

    setPreDefinedDate(value);
    setOpenDatePicker(false);
    setStartDate(null);
    setEndDate(null);
    switch (value) {
      case 'any-date':
        setOpenDatePicker(false);
        afterChange && afterChange(value, null, null);
        break;
      case 'today':
        setStartDate(new Date());
        afterChange && afterChange(value, new Date(), null);
        break;
      case 'tomorrow':
        setStartDate(addDays(new Date(), 1));
        afterChange && afterChange(value, addDays(new Date(), 1), null);
        break;
      case 'week':
        const endOfTheWeek = addDays(new Date(), differenceInDays(lastDayOfWeek(new Date()), new Date()));
        setStartDate(new Date());
        setEndDate(endOfTheWeek);
        afterChange && afterChange(value, new Date(), endOfTheWeek);
        break;
      case 'next-week':
        const startOfNextWeek = addWeeks(startOfWeek(new Date()), 1);
        const endOfNextWeek = addDays(startOfNextWeek, differenceInDays(addWeeks(startOfNextWeek, 1), startOfNextWeek));
        setStartDate(startOfNextWeek);
        setEndDate(endOfNextWeek);
        afterChange && afterChange(value, startOfNextWeek, endOfNextWeek);
        break;
      default:
        setOpenDatePicker(true);
        break;
    }
  };

  return (
    <Wrapper disabled={props.disabled}>
      <Selector value={preDefinedDate} onChange={handleDateSelectorChange} {...props} width={220}>
        <SelectorItem value="any-date">{anyDateText}</SelectorItem>
        <SelectorItem value="today">{todayText}</SelectorItem>
        <SelectorItem value="tomorrow">{tomorrowText}</SelectorItem>
        <SelectorItem value="week">{thisWeekText}</SelectorItem>
        <SelectorItem value="next-week">{nextWeekText}</SelectorItem>
        <SelectorItem value="pick">{pickDatePlaceholder}</SelectorItem>
      </Selector>
      <DateRangePicker
        open={openDatePicker}
        setIsOpen={setOpenDatePicker}
        startDate={startDate}
        onStartDateChange={date => {
          setStartDate(date);
          setDatePlaceholder(format(date, 'MMM d'));
          afterChange && afterChange('pick', date, null);
        }}
        endDate={endDate}
        onEndDateChange={date => {
          setEndDate(date);
          setDatePlaceholder(`${pickDatePlaceholder} - ${format(date, 'MMM d')}`);
          afterChange && afterChange('pick', startDate, date);
        }}
      />
    </Wrapper>
  );
};

export default DateSelector;
