/* tslint:disable */
/* @relayHash cad87a7865836f40b2709a42965f8f31 */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type event_EventEditListTickets_QueryVariables = {
    slug: unknown;
};
export type event_EventEditListTickets_QueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"EventEditListTickets_query">;
};
export type event_EventEditListTickets_Query = {
    readonly response: event_EventEditListTickets_QueryResponse;
    readonly variables: event_EventEditListTickets_QueryVariables;
};



/*
query event_EventEditListTickets_Query(
  $slug: SlugOrId!
) {
  ...EventEditListTickets_query_20J5Pl
}

fragment EventEditListTickets_query_20J5Pl on Query {
  eventTicket(slugOrId: $slug) {
    batches {
      id
      name
      numberOfTickets
      soldTickets
      isActive
    }
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "slug",
    "type": "SlugOrId!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "event_EventEditListTickets_Query",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "EventEditListTickets_query",
        "args": [
          {
            "kind": "Variable",
            "name": "slug",
            "variableName": "slug"
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "event_EventEditListTickets_Query",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "eventTicket",
        "storageKey": null,
        "args": [
          {
            "kind": "Variable",
            "name": "slugOrId",
            "variableName": "slug"
          }
        ],
        "concreteType": "EventTicket",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "batches",
            "storageKey": null,
            "args": null,
            "concreteType": "EventTicketBatch",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "name",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "numberOfTickets",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "soldTickets",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "isActive",
                "args": null,
                "storageKey": null
              }
            ]
          },
          (v1/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "event_EventEditListTickets_Query",
    "id": null,
    "text": "query event_EventEditListTickets_Query(\n  $slug: SlugOrId!\n) {\n  ...EventEditListTickets_query_20J5Pl\n}\n\nfragment EventEditListTickets_query_20J5Pl on Query {\n  eventTicket(slugOrId: $slug) {\n    batches {\n      id\n      name\n      numberOfTickets\n      soldTickets\n      isActive\n    }\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '4f4faef2aa0e443d22221f59c9390caf';
export default node;
