import React from 'react';
import styled from 'styled-components';

import { useFormikContext } from 'formik';

import Button from '../common/NewButton';
import Space from '../common/Space';

const FixedActionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0px;
  height: 75px;
  width: 100vw;
  position: fixed;
  background: ${p => p.theme.primaryBackground};
  box-shadow: ${p => p.theme.shadow};
  bottom: 0px;
  transition: opacity 0.2s cubic-bezier(0.55, 0, 0.55, 0.2);
  opacity: ${p => (p.isVisible ? 1 : 0)};
  @media (max-width: 600px) {
    margin-left: -10px;
  }
  z-index: 99999;
`;

const BottomSaveButtonFormik = ({ saveText }) => {
  // const [field, metadata] = useField(name);
  // const fieldError = metadata.touched && metadata.error;
  const { handleSubmit, isValid, dirty, isSubmitting } = useFormikContext<any>();

  return (
    <FixedActionWrapper isVisible={dirty}>
      <Space width={20} />
      <Button
        type="accent"
        size="large"
        onClick={handleSubmit}
        loading={isSubmitting}
        disabled={!isValid || isSubmitting || !dirty}
      >
        {saveText}
      </Button>
    </FixedActionWrapper>
  );
};

export default BottomSaveButtonFormik;
