/* tslint:disable */
/* @relayHash 391796c1b76c3bcca018b85e5d427f5a */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type event_EventDiscuss_QueryVariables = {
    slug: string;
    first: number;
};
export type event_EventDiscuss_QueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"EventDiscuss_query">;
};
export type event_EventDiscuss_Query = {
    readonly response: event_EventDiscuss_QueryResponse;
    readonly variables: event_EventDiscuss_QueryVariables;
};



/*
query event_EventDiscuss_Query(
  $slug: ID!
  $first: Int!
) {
  ...EventDiscuss_query_3vEpsK
}

fragment EventDiscussCardReplies_post_20J5Pl on EventSocialPost {
  comments(first: 10) {
    edges {
      node {
        id
        ...EventDiscussReply_comment
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}

fragment EventDiscussCard_me on User {
  id
  ...UserProfileImage_user
}

fragment EventDiscussCard_post_20J5Pl on EventSocialPost {
  id
  body
  event {
    id
  }
  createdAt
  author {
    id
    name
    ...UserProfileImage_user
  }
  postComments: comments(first: 1) {
    totalCount
    edges {
      node {
        id
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
  ...EventDiscussCardReplies_post_20J5Pl
}

fragment EventDiscussReply_comment on EventSocialComment {
  id
  body
  event {
    id
  }
  createdAt
  author {
    id
    name
    ...UserProfileImage_user
  }
}

fragment EventDiscuss_query_3vEpsK on Query {
  me {
    id
    ...EventDiscussCard_me
    ...UserProfileImage_user
  }
  event(id: $slug) {
    id
    title
    posts(first: $first) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          ...EventDiscussCard_post_20J5Pl
          __typename
        }
        cursor
      }
    }
  }
}

fragment UserProfileImage_user on User {
  id
  name
  profileImage {
    __typename
    ... on SocialFile {
      url
      id
    }
    ... on File {
      url
    }
    ... on Node {
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "slug",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "__typename",
  "args": null,
  "storageKey": null
},
v4 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "url",
    "args": null,
    "storageKey": null
  }
],
v5 = [
  (v2/*: any*/),
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "name",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "profileImage",
    "storageKey": null,
    "args": null,
    "concreteType": null,
    "plural": false,
    "selections": [
      (v3/*: any*/),
      (v2/*: any*/),
      {
        "kind": "InlineFragment",
        "type": "SocialFile",
        "selections": (v4/*: any*/)
      },
      {
        "kind": "InlineFragment",
        "type": "File",
        "selections": (v4/*: any*/)
      }
    ]
  }
],
v6 = [
  (v1/*: any*/)
],
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "hasNextPage",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "endCursor",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "body",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "event",
  "storageKey": null,
  "args": null,
  "concreteType": "Event",
  "plural": false,
  "selections": [
    (v2/*: any*/)
  ]
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "createdAt",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "author",
  "storageKey": null,
  "args": null,
  "concreteType": "User",
  "plural": false,
  "selections": (v5/*: any*/)
},
v13 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1
  }
],
v14 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "cursor",
  "args": null,
  "storageKey": null
},
v15 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "pageInfo",
  "storageKey": null,
  "args": null,
  "concreteType": "PageInfoExtended",
  "plural": false,
  "selections": [
    (v8/*: any*/),
    (v7/*: any*/)
  ]
},
v16 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "event_EventDiscuss_Query",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "EventDiscuss_query",
        "args": [
          (v1/*: any*/),
          {
            "kind": "Variable",
            "name": "slug",
            "variableName": "slug"
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "event_EventDiscuss_Query",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": (v5/*: any*/)
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "event",
        "storageKey": null,
        "args": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "slug"
          }
        ],
        "concreteType": "Event",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "title",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "posts",
            "storageKey": null,
            "args": (v6/*: any*/),
            "concreteType": "EventSocialPostConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "pageInfo",
                "storageKey": null,
                "args": null,
                "concreteType": "PageInfoExtended",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v8/*: any*/)
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "EventSocialPostEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "EventSocialPost",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v9/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": "postComments",
                        "name": "comments",
                        "storageKey": "comments(first:1)",
                        "args": (v13/*: any*/),
                        "concreteType": "EventSocialCommentConnection",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "totalCount",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "edges",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "EventSocialCommentEdge",
                            "plural": true,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "node",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "EventSocialComment",
                                "plural": false,
                                "selections": [
                                  (v2/*: any*/),
                                  (v3/*: any*/)
                                ]
                              },
                              (v14/*: any*/)
                            ]
                          },
                          (v15/*: any*/)
                        ]
                      },
                      {
                        "kind": "LinkedHandle",
                        "alias": "postComments",
                        "name": "comments",
                        "args": (v13/*: any*/),
                        "handle": "connection",
                        "key": "EventDiscussCard_postComments",
                        "filters": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "comments",
                        "storageKey": "comments(first:10)",
                        "args": (v16/*: any*/),
                        "concreteType": "EventSocialCommentConnection",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "edges",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "EventSocialCommentEdge",
                            "plural": true,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "node",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "EventSocialComment",
                                "plural": false,
                                "selections": [
                                  (v2/*: any*/),
                                  (v9/*: any*/),
                                  (v10/*: any*/),
                                  (v11/*: any*/),
                                  (v12/*: any*/),
                                  (v3/*: any*/)
                                ]
                              },
                              (v14/*: any*/)
                            ]
                          },
                          (v15/*: any*/)
                        ]
                      },
                      {
                        "kind": "LinkedHandle",
                        "alias": null,
                        "name": "comments",
                        "args": (v16/*: any*/),
                        "handle": "connection",
                        "key": "EventDiscussCardReplies_comments",
                        "filters": null
                      },
                      (v3/*: any*/)
                    ]
                  },
                  (v14/*: any*/)
                ]
              }
            ]
          },
          {
            "kind": "LinkedHandle",
            "alias": null,
            "name": "posts",
            "args": (v6/*: any*/),
            "handle": "connection",
            "key": "EventDiscuss_posts",
            "filters": null
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "event_EventDiscuss_Query",
    "id": null,
    "text": "query event_EventDiscuss_Query(\n  $slug: ID!\n  $first: Int!\n) {\n  ...EventDiscuss_query_3vEpsK\n}\n\nfragment EventDiscussCardReplies_post_20J5Pl on EventSocialPost {\n  comments(first: 10) {\n    edges {\n      node {\n        id\n        ...EventDiscussReply_comment\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment EventDiscussCard_me on User {\n  id\n  ...UserProfileImage_user\n}\n\nfragment EventDiscussCard_post_20J5Pl on EventSocialPost {\n  id\n  body\n  event {\n    id\n  }\n  createdAt\n  author {\n    id\n    name\n    ...UserProfileImage_user\n  }\n  postComments: comments(first: 1) {\n    totalCount\n    edges {\n      node {\n        id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  ...EventDiscussCardReplies_post_20J5Pl\n}\n\nfragment EventDiscussReply_comment on EventSocialComment {\n  id\n  body\n  event {\n    id\n  }\n  createdAt\n  author {\n    id\n    name\n    ...UserProfileImage_user\n  }\n}\n\nfragment EventDiscuss_query_3vEpsK on Query {\n  me {\n    id\n    ...EventDiscussCard_me\n    ...UserProfileImage_user\n  }\n  event(id: $slug) {\n    id\n    title\n    posts(first: $first) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      edges {\n        node {\n          id\n          ...EventDiscussCard_post_20J5Pl\n          __typename\n        }\n        cursor\n      }\n    }\n  }\n}\n\nfragment UserProfileImage_user on User {\n  id\n  name\n  profileImage {\n    __typename\n    ... on SocialFile {\n      url\n      id\n    }\n    ... on File {\n      url\n    }\n    ... on Node {\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '0f7504577a978204ddee4d9c6c40a22a';
export default node;
