import { DependencyList, useEffect } from 'react';

function useAsyncEffect<Result = any, Args extends any[] = any[]>(
  callback: (...args: Args | []) => Promise<Result>,
  deps: DependencyList,
): void;
function useAsyncEffect<Result = any, Args extends any[] = any[]>(
  callback: (...args: Args | []) => Promise<Result>,
  deps: DependencyList,
  cleanup: (...args: Args | []) => any,
): void;
function useAsyncEffect<Result = any, Args extends any[] = any[]>(
  callback: (...args: Args | []) => Promise<Result>,
  deps: DependencyList = [],
  cleanup?: (...args: Args | []) => any,
) {
  useEffect(() => {
    // eslint-disable-next-line no-console
    callback().catch(e => console.error('useAsyncEffect error', e));
    return cleanup;
  }, [callback, cleanup]);
}

export default useAsyncEffect;
