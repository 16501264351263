/* tslint:disable */
/* @relayHash 14c7fc794249e54c3cc2adc472df99f8 */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type event_PopularEvents_QueryVariables = {
    first: number;
    search?: string | null;
    recentFirst: number;
};
export type event_PopularEvents_QueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"PopularEvents_query">;
};
export type event_PopularEvents_Query = {
    readonly response: event_PopularEvents_QueryResponse;
    readonly variables: event_PopularEvents_QueryVariables;
};



/*
query event_PopularEvents_Query(
  $first: Int!
  $search: String
  $recentFirst: Int!
) {
  ...PopularEvents_query_19mswn
}

fragment EventCard_event on Event {
  id
  slug
  title
  date
  coverImages {
    url
    id
  }
  address {
    shortAddress
    complement
    timezone
  }
}

fragment PopularEvents_query_19mswn on Query {
  recentEvents(first: $recentFirst, search: $search) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      node {
        id
        ...EventCard_event
        __typename
      }
      cursor
    }
  }
  featuredEvents(first: $first, search: $search) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      node {
        id
        ...EventCard_event
        __typename
      }
      cursor
    }
  }
  events(first: $first, search: $search) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      node {
        id
        ...EventCard_event
        __typename
      }
      cursor
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "search",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "recentFirst",
    "type": "Int!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v2 = {
  "kind": "Variable",
  "name": "search",
  "variableName": "search"
},
v3 = [
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "recentFirst"
  },
  (v2/*: any*/)
],
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v5 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "pageInfo",
    "storageKey": null,
    "args": null,
    "concreteType": "PageInfoExtended",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "hasNextPage",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "endCursor",
        "args": null,
        "storageKey": null
      }
    ]
  },
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "edges",
    "storageKey": null,
    "args": null,
    "concreteType": "EventEdge",
    "plural": true,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "node",
        "storageKey": null,
        "args": null,
        "concreteType": "Event",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "slug",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "title",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "date",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "coverImages",
            "storageKey": null,
            "args": null,
            "concreteType": "File",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "url",
                "args": null,
                "storageKey": null
              },
              (v4/*: any*/)
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "address",
            "storageKey": null,
            "args": null,
            "concreteType": "Address",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "shortAddress",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "complement",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "timezone",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "__typename",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "cursor",
        "args": null,
        "storageKey": null
      }
    ]
  }
],
v6 = [
  (v1/*: any*/),
  (v2/*: any*/)
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "event_PopularEvents_Query",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "PopularEvents_query",
        "args": [
          (v1/*: any*/),
          {
            "kind": "Variable",
            "name": "recentFirst",
            "variableName": "recentFirst"
          },
          (v2/*: any*/)
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "event_PopularEvents_Query",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "recentEvents",
        "storageKey": null,
        "args": (v3/*: any*/),
        "concreteType": "EventConnection",
        "plural": false,
        "selections": (v5/*: any*/)
      },
      {
        "kind": "LinkedHandle",
        "alias": null,
        "name": "recentEvents",
        "args": (v3/*: any*/),
        "handle": "connection",
        "key": "PopularEvents_recentEvents",
        "filters": []
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "featuredEvents",
        "storageKey": null,
        "args": (v6/*: any*/),
        "concreteType": "EventConnection",
        "plural": false,
        "selections": (v5/*: any*/)
      },
      {
        "kind": "LinkedHandle",
        "alias": null,
        "name": "featuredEvents",
        "args": (v6/*: any*/),
        "handle": "connection",
        "key": "PopularEvents_featuredEvents",
        "filters": []
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "events",
        "storageKey": null,
        "args": (v6/*: any*/),
        "concreteType": "EventConnection",
        "plural": false,
        "selections": (v5/*: any*/)
      },
      {
        "kind": "LinkedHandle",
        "alias": null,
        "name": "events",
        "args": (v6/*: any*/),
        "handle": "connection",
        "key": "PopularEvents_events",
        "filters": []
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "event_PopularEvents_Query",
    "id": null,
    "text": "query event_PopularEvents_Query(\n  $first: Int!\n  $search: String\n  $recentFirst: Int!\n) {\n  ...PopularEvents_query_19mswn\n}\n\nfragment EventCard_event on Event {\n  id\n  slug\n  title\n  date\n  coverImages {\n    url\n    id\n  }\n  address {\n    shortAddress\n    complement\n    timezone\n  }\n}\n\nfragment PopularEvents_query_19mswn on Query {\n  recentEvents(first: $recentFirst, search: $search) {\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n    edges {\n      node {\n        id\n        ...EventCard_event\n        __typename\n      }\n      cursor\n    }\n  }\n  featuredEvents(first: $first, search: $search) {\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n    edges {\n      node {\n        id\n        ...EventCard_event\n        __typename\n      }\n      cursor\n    }\n  }\n  events(first: $first, search: $search) {\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n    edges {\n      node {\n        id\n        ...EventCard_event\n        __typename\n      }\n      cursor\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = 'ad043ef405becedccf6c9fa4c4a68195';
export default node;
