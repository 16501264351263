import React from 'react';
import styled from 'styled-components';
import { Drawer } from 'antd';

const ScrollDrawer = styled(Drawer)`
  .ant-drawer-content {
    overflow-y: scroll !important;
  }
`;

export default ScrollDrawer;
