import React from 'react';
import styled from 'styled-components';
import { useField, useFormikContext } from 'formik';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

import { CommonText, Space } from '@confy/ui-web';

import Checkbox, { Props as CheckboxProps } from './Checkbox';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

interface Props extends Omit<CheckboxProps, 'name'> {
  name: string;
  title?: string;
}

const CheckboxFormik: React.FC<Props> = props => {
  const { name, checked, title, ...otherProps } = props;

  const [field, metadata] = useField<boolean>(name);
  const fieldError = metadata.touched && metadata.error;
  const { setFieldValue } = useFormikContext<any>();

  const handleChange = (e: CheckboxChangeEvent) => {
    const { checked } = e.target;
    return setFieldValue(name, checked);
  };

  const titleColor = otherProps.disabled ? 'disabled' : field.value ? 'accent' : 'primaryText';

  return (
    <Wrapper>
      <Checkbox
        checked={field.value}
        value={field.value}
        onBlur={field.onBlur}
        error={!!fieldError}
        name={name}
        onChange={handleChange}
        css={`
          align-self: center;
        `}
        {...otherProps}
      />
      <Space width={6} />
      {!!title && (
        <CommonText textSize="subtextSize" color={titleColor}>
          {title}
        </CommonText>
      )}
    </Wrapper>
  );
};

export default CheckboxFormik;
