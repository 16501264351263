import React from 'react';
import styled from 'styled-components';

import plus from '../../assets/plus.png';

const Wrapper = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 30px;
  background-color: ${p => p.theme.disabled};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  cursor: pointer;
  ${p => p.css};
`;

const Image = styled.img`
  width: 22px;
`;

const Button = props => {
  return (
    <Wrapper {...props}>
      <Image src={plus} />
    </Wrapper>
  );
};

export default Button;
