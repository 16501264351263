import { graphql } from 'react-relay';

import { Location } from 'found';

import { renderRelayComponent } from '@confy/relay-ssr';

const OrganizationsQuery = graphql`
  query search_Organizations_Query($first: Int!, $search: String, $filters: OrganizationsFilters) {
    ...Organizations_query @arguments(first: $first, search: $search, filters: $filters)
  }
`;

const EventsQuery = graphql`
  query search_Events_Query($first: Int!, $search: String, $location: LocationInput, $filters: EventFilters) {
    ...Events_query @arguments(first: $first, search: $search, location: $location, filters: $filters)
  }
`;

function mapSearchEventVariables(location: Location) {
  let orderBy = location.query.orderBy
    ? [
        location.query.orderBy === 'alphabetical' ? { sort: 'title', direction: 'ASC' } : null,
        location.query.orderBy === 'most-recent' ? { sort: 'createdAt', direction: 'DESC' } : null,
        location.query.orderBy === 'most-popular' ? { sort: 'attendees', direction: 'DESC' } : null,
      ]
    : [];
  orderBy = orderBy.filter(el => el !== null);

  return {
    search: location.query.q,
    location: { lat: parseFloat(location.query.lat), lng: parseFloat(location.query.lng) },
    filters: {
      startDate: location.query.startDate,
      endDate: location.query.endDate,
      orderBy,
    },
  };
}

function mapSearchOrganizationVariables(location: Location) {
  let orderBy = location.query.orderBy
    ? [
        location.query.orderBy === 'alphabetical' ? { sort: 'name', direction: 'ASC' } : null,
        location.query.orderBy === 'most-recent' ? { sort: 'createdAt', direction: 'DESC' } : null,
        location.query.orderBy === 'most-popular' ? { sort: 'members', direction: 'DESC' } : null,
      ]
    : [];
  orderBy = orderBy.filter(el => el !== null);

  return {
    search: location.query.q,
    filters: {
      orderBy,
    },
  };
}

const search = [
  {
    name: 'search',
    path: 'search',
    getComponent: () => import('../modules/search/Search').then(m => m.default),
    children: [
      {
        name: 'search.organizations',
        path: 'organizations',
        getComponent: () => import('../modules/search/Organizations').then(m => m.default),
        query: OrganizationsQuery,
        prepareVariables: (params, { location }) => ({
          ...params,
          first: 10,
          ...mapSearchOrganizationVariables(location),
        }),
        render: renderRelayComponent,
      },
      {
        name: 'search.events',
        path: 'events',
        getComponent: () => import('../modules/search/Events').then(m => m.default),
        query: EventsQuery,
        prepareVariables: (params, { location }) => ({
          ...params,
          first: 10,
          ...mapSearchEventVariables(location),
        }),
        render: renderRelayComponent,
      },
    ],
  },
];

export default search;
