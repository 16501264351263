import React from 'react';
import styled from 'styled-components';
import { Menu, Button, Divider, Icon, Layout } from 'antd';
import { useRouter } from 'found';

import Space from './Space';
const { SubMenu } = Menu;

const MyMenu = styled(Menu)`
  .ant-menu-item-selected {
    background-color: rgba(39, 110, 241, 0.1) !important;
    color: #363636 !important;
  }
`;

const MenuItem = styled(Menu.Item)`
  color: #363636 !important;
`;

export type OptionItem = { label: string; link: string };
export type SubMenuItem = { subMenuLabel: string; subMenuItems: OptionItem[] };
const renderOption = (op: OptionItem) => <MenuItem key={op.link}>{op.label}</MenuItem>;
const renderSubMenu = (op: SubMenuItem) => (
  <SubMenu
    key={op.subMenuLabel}
    title={
      <span>
        <span>{op.subMenuLabel}</span>
      </span>
    }
  >
    {op.subMenuItems.map(item => renderOption(item))}
  </SubMenu>
);

// @ts-ignore
const isSubMenuItem = (input: OptionItem | SubMenuItem): input is SubMenuItem => !!input.subMenuLabel;
const unwrapOptions = (options: Array<OptionItem | SubMenuItem>) => {
  const rendered: JSX.Element[] = [];
  for (const option of options) {
    if (isSubMenuItem(option)) {
      rendered.push(renderSubMenu(option));
    } else {
      rendered.push(renderOption(option));
    }
  }
  return rendered;
};

const HoverLayout = styled(Layout)`
  aside {
    z-index: 999 !important;
    @media (max-width: 767px) {
      box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.2) !important;
    }
    @media (min-width: 576px) {
      flex: 0 0 100% !important;
      max-width: 100% !important;
      min-width: 0px !important;
      width: 100% !important;
    }
  }
`;

interface SideMenuProps {
  options: (OptionItem | SubMenuItem)[];
  onClickBack: () => void;
  backText: string;
}

const SideMenu: React.FC<SideMenuProps> = props => {
  const { options, onClickBack, backText } = props;
  const { match, router } = useRouter();

  return (
    <HoverLayout>
      <Layout.Sider
        // collapsed={collapsed}
        breakpoint="md"
        collapsedWidth="0"
      >
        <MyMenu
          mode="inline"
          onSelect={({ key }) => {
            router.push(key);
          }}
          selectedKeys={[match.location.pathname]}
        >
          <Space height={24} />
          <Button type="link" onClick={onClickBack}>
            <Icon type="left" />
            {backText}
          </Button>
          <Divider />
          {unwrapOptions(options)}
        </MyMenu>
      </Layout.Sider>
    </HoverLayout>
  );
};

export default SideMenu;
