/* tslint:disable */
/* @relayHash 822b49a2321035cdcc9c22ad6a2b142b */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type event_EventEditMedia_QueryVariables = {
    slug: string;
};
export type event_EventEditMedia_QueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"EventEditMedia_query">;
};
export type event_EventEditMedia_Query = {
    readonly response: event_EventEditMedia_QueryResponse;
    readonly variables: event_EventEditMedia_QueryVariables;
};



/*
query event_EventEditMedia_Query(
  $slug: ID!
) {
  ...EventEditMedia_query_20J5Pl
}

fragment EventEditMedia_query_20J5Pl on Query {
  event(id: $slug) {
    id
    title
    slug
    coverImages {
      url
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "slug",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "event_EventEditMedia_Query",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "EventEditMedia_query",
        "args": [
          {
            "kind": "Variable",
            "name": "slug",
            "variableName": "slug"
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "event_EventEditMedia_Query",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "event",
        "storageKey": null,
        "args": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "slug"
          }
        ],
        "concreteType": "Event",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "title",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "slug",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "coverImages",
            "storageKey": null,
            "args": null,
            "concreteType": "File",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "url",
                "args": null,
                "storageKey": null
              },
              (v1/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "event_EventEditMedia_Query",
    "id": null,
    "text": "query event_EventEditMedia_Query(\n  $slug: ID!\n) {\n  ...EventEditMedia_query_20J5Pl\n}\n\nfragment EventEditMedia_query_20J5Pl on Query {\n  event(id: $slug) {\n    id\n    title\n    slug\n    coverImages {\n      url\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '71e263ec979e59b0d493f2621b5e0dc1';
export default node;
