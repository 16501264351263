/* tslint:disable */
/* @relayHash dcf5f60aa1bfe2b588af422dc37c44d7 */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type user_ProfileEditMediaQuery_QueryVariables = {};
export type user_ProfileEditMediaQuery_QueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"ProfileEditMedia_query">;
};
export type user_ProfileEditMediaQuery_Query = {
    readonly response: user_ProfileEditMediaQuery_QueryResponse;
    readonly variables: user_ProfileEditMediaQuery_QueryVariables;
};



/*
query user_ProfileEditMediaQuery_Query {
  ...ProfileEditMedia_query
}

fragment ProfileEditMedia_query on Query {
  me {
    id
    name
    occupation
    profileImage {
      __typename
      ... on SocialFile {
        url
        id
      }
      ... on File {
        url
      }
      ... on Node {
        id
      }
    }
    coverImage {
      url
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "url",
  "args": null,
  "storageKey": null
},
v2 = [
  (v1/*: any*/)
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "user_ProfileEditMediaQuery_Query",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "ProfileEditMedia_query",
        "args": null
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "user_ProfileEditMediaQuery_Query",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "name",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "occupation",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "profileImage",
            "storageKey": null,
            "args": null,
            "concreteType": null,
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "__typename",
                "args": null,
                "storageKey": null
              },
              (v0/*: any*/),
              {
                "kind": "InlineFragment",
                "type": "SocialFile",
                "selections": (v2/*: any*/)
              },
              {
                "kind": "InlineFragment",
                "type": "File",
                "selections": (v2/*: any*/)
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "coverImage",
            "storageKey": null,
            "args": null,
            "concreteType": "File",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v0/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "user_ProfileEditMediaQuery_Query",
    "id": null,
    "text": "query user_ProfileEditMediaQuery_Query {\n  ...ProfileEditMedia_query\n}\n\nfragment ProfileEditMedia_query on Query {\n  me {\n    id\n    name\n    occupation\n    profileImage {\n      __typename\n      ... on SocialFile {\n        url\n        id\n      }\n      ... on File {\n        url\n      }\n      ... on Node {\n        id\n      }\n    }\n    coverImage {\n      url\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = 'b653a238114e3600a504d70b5b80cf3e';
export default node;
