import React, { useState } from 'react';
import styled from 'styled-components';

const Image = styled.img`
  transition: opacity 0.5s;
  opacity: ${p => (p.isLoaded ? 1 : 0)};
`;

const StaleImage = styled.div``;

const FadeImage = props => {
  const [isLoaded, setLoaded] = useState(false);

  const handleImageLoad = () => setLoaded(true);

  if (!props.src) {
    return <StaleImage {...props} />;
  }

  return (
    <Image
      {...props}
      onLoad={() => handleImageLoad()}
      isLoaded={isLoaded}
      ref={input => {
        // onLoad replacement for SSR
        if (!input) {
          return;
        }
        const img = input;

        const updateFunc = () => {
          setLoaded(true);
        };
        img.onload = updateFunc;
        if (img.complete) {
          updateFunc();
        }
      }}
    />
  );
};

export default FadeImage;
