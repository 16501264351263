/* tslint:disable */
/* @relayHash d76851eb5e17925b147883f176aea8dd */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type organization_YourOrganizations_QueryVariables = {
    first: number;
    search?: string | null;
};
export type organization_YourOrganizations_QueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"YourOrganizations_query">;
};
export type organization_YourOrganizations_Query = {
    readonly response: organization_YourOrganizations_QueryResponse;
    readonly variables: organization_YourOrganizations_QueryVariables;
};



/*
query organization_YourOrganizations_Query(
  $first: Int!
  $search: String
) {
  ...YourOrganizations_query_1UbRgV
}

fragment OrganizationButton_me on User {
  id
}

fragment OrganizationButton_organization on Organization {
  id
  myRoles
  name
}

fragment OrganizationCard_me on User {
  id
  ...OrganizationButton_me
}

fragment OrganizationCard_organization on Organization {
  id
  slug
  name
  ...OrganizationName_organization
  myRoles
  coverImages {
    url
    id
  }
  profileImages {
    url
    id
  }
  ...OrganizationButton_organization
}

fragment OrganizationName_organization on Organization {
  id
  name
  isVerified
}

fragment YourOrganizations_query_1UbRgV on Query {
  me {
    id
    ...OrganizationCard_me
    orgsIAmMember: organizations(first: $first, search: $search, filters: {member: true}) {
      pageInfo {
        hasNextPage
        endCursor
      }
      totalCount
      edges {
        node {
          id
          ...OrganizationCard_organization
          __typename
        }
        cursor
      }
    }
    orgsIAmOwner: organizations(first: $first, search: $search, filters: {owner: true}) {
      pageInfo {
        hasNextPage
        endCursor
      }
      totalCount
      edges {
        node {
          id
          ...OrganizationCard_organization
          __typename
        }
        cursor
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "search",
    "type": "String",
    "defaultValue": null
  }
],
v1 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v2 = {
  "kind": "Variable",
  "name": "search",
  "variableName": "search"
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v4 = [
  {
    "kind": "Literal",
    "name": "filters",
    "value": {
      "member": true
    }
  },
  (v1/*: any*/),
  (v2/*: any*/)
],
v5 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "url",
    "args": null,
    "storageKey": null
  },
  (v3/*: any*/)
],
v6 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "pageInfo",
    "storageKey": null,
    "args": null,
    "concreteType": "PageInfoExtended",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "hasNextPage",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "endCursor",
        "args": null,
        "storageKey": null
      }
    ]
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "totalCount",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "edges",
    "storageKey": null,
    "args": null,
    "concreteType": "OrganizationEdge",
    "plural": true,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "node",
        "storageKey": null,
        "args": null,
        "concreteType": "Organization",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "slug",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "name",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "isVerified",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "myRoles",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "coverImages",
            "storageKey": null,
            "args": null,
            "concreteType": "File",
            "plural": false,
            "selections": (v5/*: any*/)
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "profileImages",
            "storageKey": null,
            "args": null,
            "concreteType": "File",
            "plural": false,
            "selections": (v5/*: any*/)
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "__typename",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "cursor",
        "args": null,
        "storageKey": null
      }
    ]
  }
],
v7 = [
  {
    "kind": "Literal",
    "name": "filters",
    "value": {
      "owner": true
    }
  },
  (v1/*: any*/),
  (v2/*: any*/)
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "organization_YourOrganizations_Query",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "YourOrganizations_query",
        "args": [
          (v1/*: any*/),
          (v2/*: any*/)
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "organization_YourOrganizations_Query",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "kind": "LinkedField",
            "alias": "orgsIAmMember",
            "name": "organizations",
            "storageKey": null,
            "args": (v4/*: any*/),
            "concreteType": "OrganizationConnection",
            "plural": false,
            "selections": (v6/*: any*/)
          },
          {
            "kind": "LinkedHandle",
            "alias": "orgsIAmMember",
            "name": "organizations",
            "args": (v4/*: any*/),
            "handle": "connection",
            "key": "YourOrganizations_orgsIAmMember",
            "filters": []
          },
          {
            "kind": "LinkedField",
            "alias": "orgsIAmOwner",
            "name": "organizations",
            "storageKey": null,
            "args": (v7/*: any*/),
            "concreteType": "OrganizationConnection",
            "plural": false,
            "selections": (v6/*: any*/)
          },
          {
            "kind": "LinkedHandle",
            "alias": "orgsIAmOwner",
            "name": "organizations",
            "args": (v7/*: any*/),
            "handle": "connection",
            "key": "YourOrganizations_orgsIAmOwner",
            "filters": []
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "organization_YourOrganizations_Query",
    "id": null,
    "text": "query organization_YourOrganizations_Query(\n  $first: Int!\n  $search: String\n) {\n  ...YourOrganizations_query_1UbRgV\n}\n\nfragment OrganizationButton_me on User {\n  id\n}\n\nfragment OrganizationButton_organization on Organization {\n  id\n  myRoles\n  name\n}\n\nfragment OrganizationCard_me on User {\n  id\n  ...OrganizationButton_me\n}\n\nfragment OrganizationCard_organization on Organization {\n  id\n  slug\n  name\n  ...OrganizationName_organization\n  myRoles\n  coverImages {\n    url\n    id\n  }\n  profileImages {\n    url\n    id\n  }\n  ...OrganizationButton_organization\n}\n\nfragment OrganizationName_organization on Organization {\n  id\n  name\n  isVerified\n}\n\nfragment YourOrganizations_query_1UbRgV on Query {\n  me {\n    id\n    ...OrganizationCard_me\n    orgsIAmMember: organizations(first: $first, search: $search, filters: {member: true}) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      totalCount\n      edges {\n        node {\n          id\n          ...OrganizationCard_organization\n          __typename\n        }\n        cursor\n      }\n    }\n    orgsIAmOwner: organizations(first: $first, search: $search, filters: {owner: true}) {\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n      totalCount\n      edges {\n        node {\n          id\n          ...OrganizationCard_organization\n          __typename\n        }\n        cursor\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '202190ce4fd06e944fe3be2c53920ca7';
export default node;
