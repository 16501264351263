import React, { useState, useRef } from 'react';
import styled, { css } from 'styled-components';
import { Editor, EditorState, convertToRaw, RichUtils, CompositeDecorator } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { stateFromHTML } from 'draft-js-import-html';

import 'draft-js/dist/Draft.css';

interface WrapperProps {
  hasContent: boolean;
  error?: string;
}
const Container = styled.div<WrapperProps>`
  border: 1px solid ${({ hasContent, error }) => (error ? 'red' : hasContent ? 'rgb(54, 54, 54)' : '#eee')};
  min-height: 250px;
  border-radius: 5px;
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.15008px;
  cursor: text;
  position: relative;
`;

const Wrapper = styled.div`
  padding: 14px;

  .public-DraftEditorPlaceholder-root {
    color: #a2a2a2;
  }
`;

const Toolbar = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;

  border-bottom: 1px solid #eee;
  padding-bottom: 8px;
  margin-bottom: 8px;
`;

const Button = styled.button`
  margin: 0 3px;
  background: none;
  border: 0;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Separator = styled.span`
  height: 20px !important;
  width: 1px;
  background-color: #eee;
  margin: 0 3px;
`;

const activeStyle = css`
  padding: 0 2px;
  transform: translate(-6px, -60px) scale(0.75);
  z-index: 5;
  background-color: white;
`;

interface PlaceholderAnimatedProps {
  isFocused: boolean;
  hasContent: boolean;
}
const PlaceholderAnimated = styled.label<PlaceholderAnimatedProps>`
  color: rgba(0, 0, 0, 0.54);
  position: absolute;

  ${({ isFocused }) =>
    isFocused &&
    css`
      ${activeStyle}
      color: #303f9f;
    `}

  ${({ hasContent }) => hasContent && activeStyle}
`;

const UrlInputWrapper = styled.div`
  position: absolute;
  transform: translate(0, 40px);
  border: 1px solid #eee;
  background: white;
  border-radius: 5px;
  padding: 5px;
  z-index: 10;
  display: flex;
`;

const UrlInput = styled.input`
  font-size: 14px;
`;

const ConfirmUrlButton = styled.button`
  margin-left: 5px;
  flex: 1;
`;

const EditorWrapper = styled.div`
  min-height: 200px;

  a {
    text-decoration: underline;
  }
`;

interface OwnProps {
  placeholder?: string;
  onChange: (raw: string, html: string) => void;
  value: string;
  error?: string;
  handleBlur: {
    (e: React.FocusEvent<any>): void;
    <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
  };
  name: string;
}

const RichTextInput = (props: OwnProps) => {
  const { value, error } = props;

  const decorator = new CompositeDecorator([
    {
      strategy: findLinkEntities,
      component: Link,
    },
  ]);

  const [editorState, setEditorState] = useState(
    value ? EditorState.createWithContent(stateFromHTML(value), decorator) : EditorState.createEmpty(decorator),
  );
  const [editorIsFocused, setEditorIsFocused] = useState(false);
  const [showURLInput, setShowURLInput] = useState(false);
  const [urlValue, setUrlValue] = useState('');

  const editorStateRef = useRef<EditorState>(editorState);

  const editorRef = React.useRef<Editor>();
  const urlInputRef = React.useRef<HTMLInputElement>();

  const hasContent = !!convertToRaw(editorState.getCurrentContent()).blocks[0].text;

  const getRawText = (state: EditorState) => {
    const blocks = convertToRaw(state.getCurrentContent()).blocks;
    return blocks.map(block => (!block.text.trim() && '\n') || block.text).join('\n');
  };

  const getHtml = (state: EditorState) => stateToHTML(state.getCurrentContent());

  const handleFocus = () => {
    editorRef.current!.focus();
    setEditorIsFocused(true);
  };
  const handleBlur = () => setEditorIsFocused(false);

  const handleChange = (value: EditorState) => {
    const { onChange } = props;

    setEditorState(value);
    editorStateRef.current = value;
    onChange(getRawText(value), getHtml(value));
  };

  // TODO: work with cursor position after style change
  const handleInlineStyle = (style: string) =>
    handleChange(EditorState.moveFocusToEnd(RichUtils.toggleInlineStyle(editorState, style)));

  const promptForLink = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setShowURLInput(!showURLInput);
    if (!showURLInput) {
      setTimeout(() => urlInputRef.current!.focus(), 0);
    }
  };

  const confirmLink = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity('LINK', 'MUTABLE', { url: urlValue });
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(editorState, { currentContent: contentStateWithEntity });

    handleChange(RichUtils.toggleLink(newEditorState, newEditorState.getSelection(), entityKey));
    setShowURLInput(false);
    setUrlValue('');
    setTimeout(() => editorRef.current!.focus(), 0);
  };

  const removeLink = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    const selection = editorState.getSelection();
    if (!selection.isCollapsed()) {
      handleChange(RichUtils.toggleLink(editorState, selection, null));
    }
  };

  return (
    <>
      <Container onBlur={handleBlur} hasContent={hasContent} error={error}>
        <Wrapper>
          <Toolbar>
            <Button onClick={() => handleInlineStyle('BOLD')}>
              <img src={require('../../assets/bold.svg')} alt="bold style" />
            </Button>
            <Button onClick={() => handleInlineStyle('ITALIC')}>
              <img src={require('../../assets/italic.svg')} alt="italic style" />
            </Button>
            <Button onClick={() => handleInlineStyle('UNDERLINE')}>
              <img src={require('../../assets/underline.svg')} alt="underline style" />
            </Button>
            <Separator />
            <Button onClick={promptForLink}>
              <img src={require('../../assets/link.svg')} alt="link" />
            </Button>
            <Button onClick={removeLink}>
              <img src={require('../../assets/unlink.svg')} alt="unlink" />
            </Button>
            {showURLInput && (
              <UrlInputWrapper>
                <UrlInput
                  type="text"
                  ref={input => (urlInputRef.current = input!)}
                  value={urlValue}
                  onChange={e => setUrlValue(e.target.value)}
                />
                <ConfirmUrlButton onClick={confirmLink}>Confirm</ConfirmUrlButton>
              </UrlInputWrapper>
            )}
          </Toolbar>
          <PlaceholderAnimated isFocused={editorIsFocused} hasContent={hasContent} className="MuiInputLabel-animated">
            {props.placeholder}
          </PlaceholderAnimated>
          <EditorWrapper onClick={handleFocus}>
            <Editor
              editorState={editorState}
              onChange={e => editorStateRef.current === editorState && handleChange(e)}
              ref={editor => (editorRef.current = editor!)}
              placeholder={editorIsFocused ? props.placeholder : undefined}
              onBlur={() => props.handleBlur(props.name)}
            />
          </EditorWrapper>
        </Wrapper>
      </Container>
      <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error">{error}</p>
    </>
  );
};

function findLinkEntities(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges(character => {
    const entityKey = character.getEntity();
    return entityKey !== null && contentState.getEntity(entityKey).getType() === 'LINK';
  }, callback);
}

const Link = props => {
  const { url } = props.contentState.getEntity(props.entityKey).getData();
  return <a href={url}>{props.children}</a>;
};

export default RichTextInput;
