import { useFormikContext } from 'formik';
import { useEffect } from 'react';

export function useFormikSwitch<TFormValues>(firstField: keyof TFormValues, secondField: keyof TFormValues) {
  const { setFieldValue, values } = useFormikContext<TFormValues>();

  const firstValue = values[firstField];
  const secondValue = values[secondField];

  useEffect(() => {
    firstValue ? setFieldValue(secondField as string, false) : setFieldValue(secondField as string, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstValue]);
  useEffect(() => {
    secondValue ? setFieldValue(firstField as string, false) : setFieldValue(firstField as string, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [secondValue]);
}
