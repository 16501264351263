/* tslint:disable */
/* @relayHash 915c0bcaa5b91a5950ed19146268e70f */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type router_App_QueryVariables = {};
export type router_App_QueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"App_query">;
};
export type router_App_Query = {
    readonly response: router_App_QueryResponse;
    readonly variables: router_App_QueryVariables;
};



/*
query router_App_Query {
  ...App_query
}

fragment App_query on Query {
  ...Header_query
  ...Footer_query
}

fragment CrispChat_user on User {
  name
  email
  profileImage {
    __typename
    ... on SocialFile {
      url
      id
    }
    ... on File {
      url
    }
    ... on Node {
      id
    }
  }
}

fragment Footer_query on Query {
  ...LanguageChangeDropdown_query
}

fragment Header_query on Query {
  me {
    id
    email
    name
    profileImage {
      __typename
      ... on SocialFile {
        url
        id
      }
      ... on File {
        url
      }
      ... on Node {
        id
      }
    }
    ...CrispChat_user
  }
}

fragment LanguageChangeDropdown_query on Query {
  me {
    lang
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "url",
    "args": null,
    "storageKey": null
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "router_App_Query",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "App_query",
        "args": null
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "router_App_Query",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "email",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "name",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "profileImage",
            "storageKey": null,
            "args": null,
            "concreteType": null,
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "__typename",
                "args": null,
                "storageKey": null
              },
              (v0/*: any*/),
              {
                "kind": "InlineFragment",
                "type": "SocialFile",
                "selections": (v1/*: any*/)
              },
              {
                "kind": "InlineFragment",
                "type": "File",
                "selections": (v1/*: any*/)
              }
            ]
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "lang",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "router_App_Query",
    "id": null,
    "text": "query router_App_Query {\n  ...App_query\n}\n\nfragment App_query on Query {\n  ...Header_query\n  ...Footer_query\n}\n\nfragment CrispChat_user on User {\n  name\n  email\n  profileImage {\n    __typename\n    ... on SocialFile {\n      url\n      id\n    }\n    ... on File {\n      url\n    }\n    ... on Node {\n      id\n    }\n  }\n}\n\nfragment Footer_query on Query {\n  ...LanguageChangeDropdown_query\n}\n\nfragment Header_query on Query {\n  me {\n    id\n    email\n    name\n    profileImage {\n      __typename\n      ... on SocialFile {\n        url\n        id\n      }\n      ... on File {\n        url\n      }\n      ... on Node {\n        id\n      }\n    }\n    ...CrispChat_user\n  }\n}\n\nfragment LanguageChangeDropdown_query on Query {\n  me {\n    lang\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '2c3e5c18cb4f9630d093490a0a432ebd';
export default node;
