const search = {
  Alphabetical: 'Alphabetical (A-Z)',
  ErrorAttendingEvent: 'Error attending event',
  ErrorCancelingEventAttend: 'Error canceling event attend',
  ErrorJoiningOrganization: 'Error joining organization',
  ErrorLeavingOrganization: 'Error leaving organization',
  Events: 'Events',
  Going: 'going',
  ImLookingFor: 'I’m looking for',
  Join: 'Join',
  Leave: 'Leave',
  Members: 'members',
  MostPopular: 'Most popular',
  MostRecent: 'Most recent',
  NewEvent: 'New event',
  OrderBy: 'Order by',
  SearchSomething: 'Search something',
};
export default search;
export type Keys = keyof typeof search;
