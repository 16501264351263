import { graphql } from 'react-relay';

import { renderRelayComponent } from '@confy/relay-ssr';

const OrganizationDetailQuery = graphql`
  query organization_OrganizationDetail_Query($first: Int!, $search: String, $slug: String) {
    ...OrganizationDetail_query @arguments(slug: $slug, first: $first, search: $search)
  }
`;

const OrganizationEditEventsUpcomingQuery = graphql`
  query organization_OrganizationEditEventsUpcoming_Query($first: Int!, $search: String, $slug: String) {
    ...OrganizationEditEventsUpcoming_query @arguments(slug: $slug, first: $first, search: $search)
  }
`;

const OrganizationEditEventsPastQuery = graphql`
  query organization_OrganizationEditEventsPast_Query($first: Int!, $search: String, $slug: String) {
    ...OrganizationEditEventsPast_query @arguments(slug: $slug, first: $first, search: $search)
  }
`;

const OrganizationMembersQuery = graphql`
  query organization_OrganizationMembers_Query($first: Int!, $search: String, $slug: String) {
    ...OrganizationMembers_query @arguments(slug: $slug, first: $first, search: $search)
  }
`;

const YourOrganizationsQuery = graphql`
  query organization_YourOrganizations_Query($first: Int!, $search: String) {
    ...YourOrganizations_query @arguments(first: $first, search: $search)
  }
`;

const PopularOrganizationsQuery = graphql`
  query organization_PopularOrganizations_Query($first: Int!, $search: String) {
    ...PopularOrganizations_query @arguments(first: $first, search: $search)
  }
`;

const OrganizationEditBasicQuery = graphql`
  query organization_OrganizationEditBasic_Query($slug: String) {
    ...OrganizationEditBasic_query @arguments(slug: $slug)
  }
`;

const OrganizationEditMediaQuery = graphql`
  query organization_OrganizationEditMedia_Query($slug: String) {
    ...OrganizationEditMedia_query @arguments(slug: $slug)
  }
`;

const OrganizationEditRolesQuery = graphql`
  query organization_OrganizationEditRoles_Query($first: Int!, $search: String, $slug: String) {
    ...OrganizationEditRoles_query @arguments(slug: $slug, first: $first, search: $search)
  }
`;

const OrganizationEditMembersQuery = graphql`
  query organization_OrganizationEditMembers_Query($first: Int!, $search: String, $slug: String) {
    ...OrganizationEditMembers_query @arguments(slug: $slug, first: $first, search: $search)
  }
`;

const organization = [
  {
    name: 'organization.add',
    path: 'organization/add',
    getComponent: () => import('../modules/organization/OrganizationAdd').then(m => m.default),
  },
  {
    name: 'organization',
    path: 'organization/:slug',
    getComponent: () => import('../modules/organization/OrganizationDetail').then(m => m.default),
    query: OrganizationDetailQuery,
    render: renderRelayComponent,
    prepareVariables: params => ({ ...params, first: 6 }),
    children: [
      {
        name: 'organization.detail',
        path: '',
        render: () => null,
      },
      {
        name: 'organization.detail.members',
        path: 'members',
        getComponent: () => import('../modules/organization/OrganizationMembers').then(m => m.default),
        query: OrganizationMembersQuery,
        prepareVariables: params => ({ ...params, first: 10 }),
        render: renderRelayComponent,
      },
    ],
  },
  {
    name: 'organization.edit',
    path: 'organization/:slug/edit',
    getComponent: () => import('../modules/organization/OrganizationEdit').then(m => m.default),
    children: [
      {
        name: 'organization.edit.details',
        path: 'details',
        getComponent: () => import('../modules/organization/OrganizationEditBasic').then(m => m.default),
        query: OrganizationEditBasicQuery,
        render: renderRelayComponent,
      },
      {
        name: 'organization.edit.media',
        path: 'media',
        getComponent: () => import('../modules/organization/OrganizationEditMedia').then(m => m.default),
        query: OrganizationEditMediaQuery,
        render: renderRelayComponent,
      },
      {
        name: 'organization.edit.roles',
        path: 'roles',
        getComponent: () => import('../modules/organization/OrganizationEditRoles').then(m => m.default),
        query: OrganizationEditRolesQuery,
        prepareVariables: params => ({ ...params, first: 10 }),
        render: renderRelayComponent,
      },
      {
        name: 'organization.edit.members',
        path: 'members',
        getComponent: () => import('../modules/organization/OrganizationEditMembers').then(m => m.default),
        query: OrganizationEditMembersQuery,
        prepareVariables: params => ({ ...params, first: 10 }),
        render: renderRelayComponent,
      },
      {
        name: 'organization.edit.events',
        path: 'events',
        getComponent: () => import('../modules/organization/OrganizationEditEvents').then(m => m.default),
        children: [
          {
            name: 'organization.edit.events.upcoming',
            path: 'upcoming',
            getComponent: () => import('../modules/organization/OrganizationEditEventsUpcoming').then(m => m.default),
            query: OrganizationEditEventsUpcomingQuery,
            render: renderRelayComponent,
            prepareVariables: params => ({ ...params, first: 10 }),
          },
          {
            name: 'organization.edit.events.past',
            path: 'past',
            getComponent: () => import('../modules/organization/OrganizationEditEventsPast').then(m => m.default),
            query: OrganizationEditEventsPastQuery,
            render: renderRelayComponent,
            prepareVariables: params => ({ ...params, first: 10 }),
          },
        ],
      },
    ],
  },
  {
    name: 'organizations',
    path: 'organizations',
    getComponent: () => import('../modules/organization/OrganizationsLists').then(m => m.default),
    children: [
      {
        name: 'organizations.yours',
        path: 'yours',
        getComponent: () => import('../modules/organization/YourOrganizations').then(m => m.default),
        query: YourOrganizationsQuery,
        prepareVariables: params => ({ ...params, first: 6 }),
        render: renderRelayComponent,
      },
      {
        name: 'organizations.popular',
        path: 'popular',
        getComponent: () => import('../modules/organization/PopularOrganizations').then(m => m.default),
        query: PopularOrganizationsQuery,
        prepareVariables: params => ({ ...params, first: 6 }),
        render: renderRelayComponent,
      },
    ],
  },
];

export default organization;
