import React from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';

import { Empty } from 'antd';

import CloseButton from '../common/CloseButton';
import cameraImagePicker from '../../assets/cameraImagePicker.png';

const Wrapper = styled.div<{ isRound?: boolean; borderRadius?: number }>`
  width: 100%;
  height: 100%;
  ${p => p.isRound && `border-radius: 50vw;`}
  background: #f5f5f5;
  position: relative;
  border: 1px dotted #cbcbcb;
  ${p => p.borderRadius && `border-radius: ${p.borderRadius}px;`}
`;

const Image = styled.img<{ isRound?: boolean; borderRadius?: number }>`
  width: 100%;
  height: 100%;
  object-fit: cover;
  ${p => p.isRound && `border-radius: 50vw;`}
  ${p => p.borderRadius && `border-radius: ${p.borderRadius}px;`}
`;

const Upload = styled.p`
  margin: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
`;

const PlaceholderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  justify-content: center;
  align-items: center;
`;

const CameraImage = styled.img.attrs({
  src: cameraImagePicker,
})`
  width: 72px;
  object-fit: cover;
`;

interface Source {
  type: string;
  data: string;
}
interface Props {
  setSource: (souce: Source) => void;
  value: string;
  isRound?: boolean;
  borderRadius?: number;
  descriptionText: string;
}

function ImageDropzone(props: Props) {
  const { setSource, value, isRound, borderRadius, descriptionText } = props;

  const onDrop = async files => {
    const [image] = files;

    const reader = new FileReader();
    reader.readAsDataURL(image);
    reader.onload = async event => {
      const source = {
        type: image.type,
        data: event.target.result,
      };

      setSource(source);
    };
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: 'image/*' });

  return (
    <Wrapper isRound={isRound} borderRadius={borderRadius}>
      {value ? (
        <>
          <CloseButton onClick={() => setSource(null)} />
          <Image src={value} isRound={isRound} borderRadius={borderRadius} />
        </>
      ) : (
        <div style={{ height: '100%', width: '100%', cursor: 'pointer' }} {...getRootProps()}>
          <input style={{ height: '100%', width: '100%' }} {...getInputProps()} />
          <Upload />
          <PlaceholderWrapper>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={descriptionText} />
          </PlaceholderWrapper>
        </div>
      )}
    </Wrapper>
  );
}

export default ImageDropzone;
