const auth = [
  {
    name: 'auth',
    path: 'auth',
    getComponent: () => import('../modules/auth/Auth').then(m => m.default),
    children: [
      {
        name: 'auth.login',
        path: 'login',
        getComponent: () => import('../modules/auth/Login').then(m => m.default),
      },
      {
        name: 'auth.register',
        path: 'register',
        getComponent: () => import('../modules/auth/Register').then(m => m.default),
      },
    ],
  },
  {
    name: 'auth.forgotPassword',
    path: 'auth/forgotPassword',
    getComponent: () => import('../modules/auth/ForgotPassword').then(m => m.default),
  },
  {
    name: 'auth.resetPassword',
    path: 'auth/resetPassword',
    getComponent: () => import('../modules/auth/ResetPassword').then(m => m.default),
  },
  {
    name: 'facebook',
    path: 'facebook',
    getComponent: () => import('../modules/auth/FacebookCallback').then(m => m.default),
  },
  {
    name: 'github',
    path: 'github',
    getComponent: () => import('../modules/auth/GithubCallback').then(m => m.default),
  },
  {
    name: 'emailConfirmation',
    path: 'emailConfirmation',
    getComponent: () => import('../modules/auth/EmailConfirmation').then(m => m.default),
  },
  {
    name: 'terms',
    path: 'terms',
    getComponent: () => import('../modules/auth/Terms').then(m => m.default),
  },
];

export default auth;
