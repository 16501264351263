/* tslint:disable */
/* @relayHash 26af4acf4cafaac4df056ce74fa84b89 */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type organization_OrganizationEditMedia_QueryVariables = {
    slug?: string | null;
};
export type organization_OrganizationEditMedia_QueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"OrganizationEditMedia_query">;
};
export type organization_OrganizationEditMedia_Query = {
    readonly response: organization_OrganizationEditMedia_QueryResponse;
    readonly variables: organization_OrganizationEditMedia_QueryVariables;
};



/*
query organization_OrganizationEditMedia_Query(
  $slug: String
) {
  ...OrganizationEditMedia_query_20J5Pl
}

fragment OrganizationEditMedia_query_20J5Pl on Query {
  organization(slug: $slug) {
    id
    name
    slug
    coverImages {
      id
      url
    }
    profileImages {
      id
      url
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "slug",
    "type": "String",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "slug"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = [
  (v2/*: any*/),
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "url",
    "args": null,
    "storageKey": null
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "organization_OrganizationEditMedia_Query",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "OrganizationEditMedia_query",
        "args": (v1/*: any*/)
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "organization_OrganizationEditMedia_Query",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "organization",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "name",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "slug",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "coverImages",
            "storageKey": null,
            "args": null,
            "concreteType": "File",
            "plural": false,
            "selections": (v3/*: any*/)
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "profileImages",
            "storageKey": null,
            "args": null,
            "concreteType": "File",
            "plural": false,
            "selections": (v3/*: any*/)
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "organization_OrganizationEditMedia_Query",
    "id": null,
    "text": "query organization_OrganizationEditMedia_Query(\n  $slug: String\n) {\n  ...OrganizationEditMedia_query_20J5Pl\n}\n\nfragment OrganizationEditMedia_query_20J5Pl on Query {\n  organization(slug: $slug) {\n    id\n    name\n    slug\n    coverImages {\n      id\n      url\n    }\n    profileImages {\n      id\n      url\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '63faa147f5e070af963637152f28cf07';
export default node;
