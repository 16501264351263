/* tslint:disable */
/* @relayHash e77945b1e25f354c582493ac3fa7e826 */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type event_EventDetail_QueryVariables = {
    slug: string;
};
export type event_EventDetail_QueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"EventDetail_query">;
};
export type event_EventDetail_Query = {
    readonly response: event_EventDetail_QueryResponse;
    readonly variables: event_EventDetail_QueryVariables;
};



/*
query event_EventDetail_Query(
  $slug: ID!
) {
  ...EventDetail_query_20J5Pl
}

fragment AttendButton_event on Event {
  id
  title
  userHasLiked
  slug
  isFull
  isExpired
  hasAttended
  positionInQueue
  eventForm {
    questions
    id
  }
}

fragment AttendButton_me on User {
  id
}

fragment EventDetail_query_20J5Pl on Query {
  me {
    id
    ...LikeButton_me
    ...AttendButton_me
  }
  event(id: $slug) {
    id
    slug
    isOwner
    title
    description
    descriptionHtml
    isExpired
    date
    coverImages {
      url
      id
    }
    address {
      fullAddress
      complement
      country
      zipcode
      timezone
    }
    organization {
      id
      slug
      coverImages {
        url
        id
      }
      ...OrganizationName_organization
    }
    removedAt
    attendees(first: 8) {
      totalCount
      edges {
        node {
          id
          ...ProfilePicturesSectionItem_user
          __typename
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
    ...LikeButton_event
    ...AttendButton_event
  }
}

fragment LikeButton_event on Event {
  id
  userHasLiked
}

fragment LikeButton_me on User {
  id
}

fragment OrganizationName_organization on Organization {
  id
  name
  isVerified
}

fragment ProfilePicturesSectionItem_user on User {
  id
  name
  profileImage {
    __typename
    ... on SocialFile {
      url
      id
    }
    ... on File {
      url
    }
    ... on Node {
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "slug",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "slug",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "url",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "coverImages",
  "storageKey": null,
  "args": null,
  "concreteType": "File",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    (v1/*: any*/)
  ]
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v6 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 8
  }
],
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "__typename",
  "args": null,
  "storageKey": null
},
v8 = [
  (v3/*: any*/)
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "event_EventDetail_Query",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "EventDetail_query",
        "args": [
          {
            "kind": "Variable",
            "name": "slug",
            "variableName": "slug"
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "event_EventDetail_Query",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          (v1/*: any*/)
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "event",
        "storageKey": null,
        "args": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "slug"
          }
        ],
        "concreteType": "Event",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "isOwner",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "title",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "description",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "descriptionHtml",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "isExpired",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "date",
            "args": null,
            "storageKey": null
          },
          (v4/*: any*/),
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "address",
            "storageKey": null,
            "args": null,
            "concreteType": "Address",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "fullAddress",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "complement",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "country",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "zipcode",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "timezone",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "organization",
            "storageKey": null,
            "args": null,
            "concreteType": "Organization",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "isVerified",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "removedAt",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "attendees",
            "storageKey": "attendees(first:8)",
            "args": (v6/*: any*/),
            "concreteType": "UserConnection",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "totalCount",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "UserEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "User",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      (v5/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "profileImage",
                        "storageKey": null,
                        "args": null,
                        "concreteType": null,
                        "plural": false,
                        "selections": [
                          (v7/*: any*/),
                          (v1/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "type": "SocialFile",
                            "selections": (v8/*: any*/)
                          },
                          {
                            "kind": "InlineFragment",
                            "type": "File",
                            "selections": (v8/*: any*/)
                          }
                        ]
                      },
                      (v7/*: any*/)
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "cursor",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "pageInfo",
                "storageKey": null,
                "args": null,
                "concreteType": "PageInfoExtended",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "endCursor",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "hasNextPage",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          },
          {
            "kind": "LinkedHandle",
            "alias": null,
            "name": "attendees",
            "args": (v6/*: any*/),
            "handle": "connection",
            "key": "EventDetail_attendees",
            "filters": []
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "userHasLiked",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "isFull",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "hasAttended",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "positionInQueue",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "eventForm",
            "storageKey": null,
            "args": null,
            "concreteType": "EventForm",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "questions",
                "args": null,
                "storageKey": null
              },
              (v1/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "event_EventDetail_Query",
    "id": null,
    "text": "query event_EventDetail_Query(\n  $slug: ID!\n) {\n  ...EventDetail_query_20J5Pl\n}\n\nfragment AttendButton_event on Event {\n  id\n  title\n  userHasLiked\n  slug\n  isFull\n  isExpired\n  hasAttended\n  positionInQueue\n  eventForm {\n    questions\n    id\n  }\n}\n\nfragment AttendButton_me on User {\n  id\n}\n\nfragment EventDetail_query_20J5Pl on Query {\n  me {\n    id\n    ...LikeButton_me\n    ...AttendButton_me\n  }\n  event(id: $slug) {\n    id\n    slug\n    isOwner\n    title\n    description\n    descriptionHtml\n    isExpired\n    date\n    coverImages {\n      url\n      id\n    }\n    address {\n      fullAddress\n      complement\n      country\n      zipcode\n      timezone\n    }\n    organization {\n      id\n      slug\n      coverImages {\n        url\n        id\n      }\n      ...OrganizationName_organization\n    }\n    removedAt\n    attendees(first: 8) {\n      totalCount\n      edges {\n        node {\n          id\n          ...ProfilePicturesSectionItem_user\n          __typename\n        }\n        cursor\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n    ...LikeButton_event\n    ...AttendButton_event\n  }\n}\n\nfragment LikeButton_event on Event {\n  id\n  userHasLiked\n}\n\nfragment LikeButton_me on User {\n  id\n}\n\nfragment OrganizationName_organization on Organization {\n  id\n  name\n  isVerified\n}\n\nfragment ProfilePicturesSectionItem_user on User {\n  id\n  name\n  profileImage {\n    __typename\n    ... on SocialFile {\n      url\n      id\n    }\n    ... on File {\n      url\n    }\n    ... on Node {\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '5d211dc8a0cb2890b28e84dcf79b8d8d';
export default node;
