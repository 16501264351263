/* tslint:disable */
/* @relayHash b2a43f2c550b815de32718eb04f801b1 */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type event_EventForm_QueryVariables = {
    slug: string;
};
export type event_EventForm_QueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"EventForm_query">;
};
export type event_EventForm_Query = {
    readonly response: event_EventForm_QueryResponse;
    readonly variables: event_EventForm_QueryVariables;
};



/*
query event_EventForm_Query(
  $slug: ID!
) {
  ...EventForm_query_20J5Pl
}

fragment AttendButton_event on Event {
  id
  title
  userHasLiked
  slug
  isFull
  isExpired
  hasAttended
  positionInQueue
  eventForm {
    questions
    id
  }
}

fragment AttendButton_me on User {
  id
}

fragment EventForm_query_20J5Pl on Query {
  me {
    id
    ...AttendButton_me
  }
  event(id: $slug) {
    id
    slug
    isOwner
    isExpired
    eventForm {
      questions
      id
    }
    ...AttendButton_event
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "slug",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "event_EventForm_Query",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "EventForm_query",
        "args": [
          {
            "kind": "Variable",
            "name": "slug",
            "variableName": "slug"
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "event_EventForm_Query",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          (v1/*: any*/)
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "event",
        "storageKey": null,
        "args": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "slug"
          }
        ],
        "concreteType": "Event",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "slug",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "isOwner",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "isExpired",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "eventForm",
            "storageKey": null,
            "args": null,
            "concreteType": "EventForm",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "questions",
                "args": null,
                "storageKey": null
              },
              (v1/*: any*/)
            ]
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "title",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "userHasLiked",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "isFull",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "hasAttended",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "positionInQueue",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "event_EventForm_Query",
    "id": null,
    "text": "query event_EventForm_Query(\n  $slug: ID!\n) {\n  ...EventForm_query_20J5Pl\n}\n\nfragment AttendButton_event on Event {\n  id\n  title\n  userHasLiked\n  slug\n  isFull\n  isExpired\n  hasAttended\n  positionInQueue\n  eventForm {\n    questions\n    id\n  }\n}\n\nfragment AttendButton_me on User {\n  id\n}\n\nfragment EventForm_query_20J5Pl on Query {\n  me {\n    id\n    ...AttendButton_me\n  }\n  event(id: $slug) {\n    id\n    slug\n    isOwner\n    isExpired\n    eventForm {\n      questions\n      id\n    }\n    ...AttendButton_event\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '759d11c5340c14dc9c3ff583dfdec985';
export default node;
