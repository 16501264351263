const user = {
  BasicDetails: 'Detalhes básicos',
  ChangeCoverImage: 'Alterar imagem de capa',
  ChangeProfileImage: 'Alterar imagem de perfil',
  EditProfile: 'Editar Perfil',
  EmailIsRequired: 'Email é obrigatório',
  ErrorEditingCoverImage: 'Erro ao editar imagem de capa',
  ErrorEditingProfile: 'Erro ao editar perfil',
  ErrorEditingProfileImage: 'Erro ao editar imagem de perfil',
  Events: 'Eventos',
  Help: 'Ajuda',
  LastName: 'Sobrenome',
  LastNameIsRequired: 'Sobrenome é obrigatório',
  Liked: 'Curtidos',
  LimitOfXCharacters: 'Limite de {{characters}} caracteres',
  ManageProfile: 'Administrar perfil',
  Media: 'Mídia',
  Name: 'Nome',
  NameIsRequired: 'Nome é obrigatório',
  Occupation: 'Ocupação',
  OccupationMustBeChanged: 'Ocupação precisa ser alterada',
  Participants: 'Participantes',
  Participating: 'Participando',
  Past: 'Passados',
  Return: 'Voltar',
  SaveChanges: 'Salvar alterações',
  SuccessEditingCoverImage: 'Sucesso ao editar imagem de capa',
  SuccessEditingProfile: 'Sucesso ao editar perfil',
  SuccessEditingProfileImage: 'Sucesso ao editar imagem de perfil',
  Upcoming: 'Próximos',
};
export default user;
export type Keys = keyof typeof user;
