/* tslint:disable */
/* @relayHash a970ed0b316213e6aeb14ef86dbad9da */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type DirectionEnum = "ASC" | "DESC" | "%future added value";
export type LocationInput = {
    readonly lng?: number | null;
    readonly lat?: number | null;
};
export type EventFilters = {
    readonly past?: boolean | null;
    readonly future?: boolean | null;
    readonly startDate?: string | null;
    readonly endDate?: string | null;
    readonly orderBy?: ReadonlyArray<EventOrderByFilter | null> | null;
};
export type EventOrderByFilter = {
    readonly sort?: string | null;
    readonly direction?: DirectionEnum | null;
};
export type search_Events_QueryVariables = {
    first: number;
    search?: string | null;
    location?: LocationInput | null;
    filters?: EventFilters | null;
};
export type search_Events_QueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"Events_query">;
};
export type search_Events_Query = {
    readonly response: search_Events_QueryResponse;
    readonly variables: search_Events_QueryVariables;
};



/*
query search_Events_Query(
  $first: Int!
  $search: String
  $location: LocationInput
  $filters: EventFilters
) {
  ...Events_query_4wGORM
}

fragment EventCard_event on Event {
  id
  slug
  title
  date
  coverImages {
    url
    id
  }
  address {
    shortAddress
    complement
    timezone
  }
}

fragment Events_query_4wGORM on Query {
  events(first: $first, search: $search, location: $location, filters: $filters) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      node {
        id
        ...EventCard_event
        __typename
      }
      cursor
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "search",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "location",
    "type": "LocationInput",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "filters",
    "type": "EventFilters",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "filters",
    "variableName": "filters"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "location",
    "variableName": "location"
  },
  {
    "kind": "Variable",
    "name": "search",
    "variableName": "search"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "search_Events_Query",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "Events_query",
        "args": (v1/*: any*/)
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "search_Events_Query",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "events",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "EventConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "pageInfo",
            "storageKey": null,
            "args": null,
            "concreteType": "PageInfoExtended",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "hasNextPage",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "endCursor",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "EventEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "Event",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "slug",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "title",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "date",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "coverImages",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "File",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "url",
                        "args": null,
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ]
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "address",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Address",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "shortAddress",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "complement",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "timezone",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "__typename",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "cursor",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      },
      {
        "kind": "LinkedHandle",
        "alias": null,
        "name": "events",
        "args": (v1/*: any*/),
        "handle": "connection",
        "key": "Events_events",
        "filters": []
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "search_Events_Query",
    "id": null,
    "text": "query search_Events_Query(\n  $first: Int!\n  $search: String\n  $location: LocationInput\n  $filters: EventFilters\n) {\n  ...Events_query_4wGORM\n}\n\nfragment EventCard_event on Event {\n  id\n  slug\n  title\n  date\n  coverImages {\n    url\n    id\n  }\n  address {\n    shortAddress\n    complement\n    timezone\n  }\n}\n\nfragment Events_query_4wGORM on Query {\n  events(first: $first, search: $search, location: $location, filters: $filters) {\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n    edges {\n      node {\n        id\n        ...EventCard_event\n        __typename\n      }\n      cursor\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '09e60171f40309bff3cde737b06bbd3b';
export default node;
