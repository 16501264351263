/* tslint:disable */
/* @relayHash c69bbe363de5e3251196806419bae79f */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type user_ProfileOrganizations_QueryVariables = {
    first: number;
    last?: number | null;
    id: string;
    search?: string | null;
};
export type user_ProfileOrganizations_QueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"ProfileOrganizations_query">;
};
export type user_ProfileOrganizations_Query = {
    readonly response: user_ProfileOrganizations_QueryResponse;
    readonly variables: user_ProfileOrganizations_QueryVariables;
};



/*
query user_ProfileOrganizations_Query(
  $first: Int!
  $last: Int
  $id: ID!
) {
  ...ProfileOrganizations_query_2ub9Zy
}

fragment OrganizationButton_me on User {
  id
}

fragment OrganizationButton_organization on Organization {
  id
  myRoles
  name
}

fragment OrganizationCard_me on User {
  id
  ...OrganizationButton_me
}

fragment OrganizationCard_organization on Organization {
  id
  slug
  name
  ...OrganizationName_organization
  myRoles
  coverImages {
    url
    id
  }
  profileImages {
    url
    id
  }
  ...OrganizationButton_organization
}

fragment OrganizationName_organization on Organization {
  id
  name
  isVerified
}

fragment ProfileOrganizations_query_2ub9Zy on Query {
  me {
    id
    ...OrganizationCard_me
  }
  user(id: $id) {
    organizations(first: $first, last: $last) {
      pageInfo {
        hasNextPage
        endCursor
        hasPreviousPage
        startCursor
      }
      edges {
        node {
          id
          ...OrganizationCard_organization
          __typename
        }
        cursor
      }
    }
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "last",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "id",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "search",
    "type": "String",
    "defaultValue": null
  }
],
v1 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v2 = {
  "kind": "Variable",
  "name": "id",
  "variableName": "id"
},
v3 = {
  "kind": "Variable",
  "name": "last",
  "variableName": "last"
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v5 = [
  (v1/*: any*/),
  (v3/*: any*/)
],
v6 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "url",
    "args": null,
    "storageKey": null
  },
  (v4/*: any*/)
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "user_ProfileOrganizations_Query",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "ProfileOrganizations_query",
        "args": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "Variable",
            "name": "search",
            "variableName": "search"
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "user_ProfileOrganizations_Query",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          (v4/*: any*/)
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "user",
        "storageKey": null,
        "args": [
          (v2/*: any*/)
        ],
        "concreteType": "User",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "organizations",
            "storageKey": null,
            "args": (v5/*: any*/),
            "concreteType": "OrganizationConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "pageInfo",
                "storageKey": null,
                "args": null,
                "concreteType": "PageInfoExtended",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "hasNextPage",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "endCursor",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "hasPreviousPage",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "startCursor",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "OrganizationEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Organization",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "slug",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "name",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "isVerified",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "myRoles",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "coverImages",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "File",
                        "plural": false,
                        "selections": (v6/*: any*/)
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "profileImages",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "File",
                        "plural": false,
                        "selections": (v6/*: any*/)
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "__typename",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "cursor",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          },
          {
            "kind": "LinkedHandle",
            "alias": null,
            "name": "organizations",
            "args": (v5/*: any*/),
            "handle": "connection",
            "key": "ProfileOrganizations_organizations",
            "filters": []
          },
          (v4/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "user_ProfileOrganizations_Query",
    "id": null,
    "text": "query user_ProfileOrganizations_Query(\n  $first: Int!\n  $last: Int\n  $id: ID!\n) {\n  ...ProfileOrganizations_query_2ub9Zy\n}\n\nfragment OrganizationButton_me on User {\n  id\n}\n\nfragment OrganizationButton_organization on Organization {\n  id\n  myRoles\n  name\n}\n\nfragment OrganizationCard_me on User {\n  id\n  ...OrganizationButton_me\n}\n\nfragment OrganizationCard_organization on Organization {\n  id\n  slug\n  name\n  ...OrganizationName_organization\n  myRoles\n  coverImages {\n    url\n    id\n  }\n  profileImages {\n    url\n    id\n  }\n  ...OrganizationButton_organization\n}\n\nfragment OrganizationName_organization on Organization {\n  id\n  name\n  isVerified\n}\n\nfragment ProfileOrganizations_query_2ub9Zy on Query {\n  me {\n    id\n    ...OrganizationCard_me\n  }\n  user(id: $id) {\n    organizations(first: $first, last: $last) {\n      pageInfo {\n        hasNextPage\n        endCursor\n        hasPreviousPage\n        startCursor\n      }\n      edges {\n        node {\n          id\n          ...OrganizationCard_organization\n          __typename\n        }\n        cursor\n      }\n    }\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '85bd6384d008f7b9b1a2b7830793ea0c';
export default node;
