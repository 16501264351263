import React from 'react';
import { Dropdown as AntdDropdown, Button, Icon } from 'antd';

interface Props {
  overlay: React.ReactElement<any>;
  placeholder: string;
}
const Dropdown: React.FC<Props> = ({ overlay, placeholder }) => {
  return (
    <AntdDropdown overlay={overlay}>
      <Button>
        {placeholder} <Icon type="down" />
      </Button>
    </AntdDropdown>
  );
};

export default Dropdown;
