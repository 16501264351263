import React from 'react';
import styled, { css, ThemeProvider } from 'styled-components';

declare module 'styled-components' {
  export interface DefaultTheme {
    primaryBackground: string;
    secondaryBackground: string;
    success: string;
    danger: string;
    warn: string;
    primary: string;
    darkPrimary: string;
    secondary: string;
    primaryTitle: string;
    secondaryTitle: string;
    primaryText: string;
    secondaryText: string;
    placeholderText: string;
    inverseText: string;
    disabled: string;
    darkGrey: string;
    subtitleSize: number;
    sectionSize: number;
    subsectionSize: number;
    titleSize: number;
    textSize: number;
    subtextSize: number;
    screenPadding: number;
    breakpoint_450: string;
    breakpoint_600: string;
    breakpoint_800: string;
    breakpoint_1600: string;
    topShadow: string;
    shadow: string;
    shadow5: string;
  }
}

const theme = {
  primaryBackground: '#ffffff',
  secondaryBackground: '#464646',
  success: '#00C77E',
  danger: '#E93F4B',
  warn: '#FF8A00',
  accent: '#276EF1',
  primary: '#6100F3',
  darkPrimary: '#5404CC',
  secondary: '#FFFFFF',
  primaryTitle: '#363636',
  strongTitle: '#191919',
  disabledText: 'rgba(0, 0,0,0.25)',
  helperText: 'rgba(0, 0,0,0.45)',
  text: 'rgba(0, 0,0,0.65)',
  labelSize: 12,
  secondaryTitle: '#6100F3',
  primaryText: '#808080',
  secondaryText: '#363636',
  placeholderText: '#AFAFAF',
  inverseText: '#000',
  disabled: '#C4C4C4',
  darkGrey: '#29262F',
  footerSize: 14,
  subtitleSize: 18,
  supportTextSize: 12,
  sectionSize: 16,
  subsectionSize: 25,
  titleSize: 50,
  textSize: 20,
  subtextSize: 16,
  screenPadding: 30,
  breakpoint_450: '450px',
  breakpoint_600: '600px',
  breakpoint_800: '800px',
  breakpoint_1600: '1600px',
  topShadow: '0 -5px 5px -5px rgba(0,0,0,0.12), 0 -5px 5px -5px rgba(0,0,0,0.24)',
  shadow: '0px 0px 40px rgba(0, 0, 0, 0.06)',
  shadow5: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
};

export default theme;
