import React, { Component } from 'react';
import styled from 'styled-components';
import { Button, Row, Col as AntCol, Divider } from 'antd';

import { ButtonProps } from 'antd/lib/button';

import { TypedFunction } from '@confy/types';

import { ColProps } from 'antd/lib/col';

import Space from './Space';

import Loading from './Loading';

const OutWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
`;

const ItemWrapper = styled.div<{ flatItems?: boolean }>`
  display: flex;
  justify-content: center;
  padding: ${p => (p.flatItems ? 0 : 20)}px;
`;

const Col = (styled(AntCol)`
  width: 100%;
  ${p => p.css};
` as unknown) as React.ComponentType<ColProps & { css: any }>;

export interface Props {
  offset: number;
  children: React.ReactNode | null;
  edges: any[];
  renderItem: TypedFunction<any, JSX.Element>;
  loadMore: TypedFunction;
  hasNextPage: boolean;
  isLoading: ButtonProps['loading'];
  loadMoreOnClick?: boolean;
  column?: boolean;
  flatItems?: boolean;
  loadMoreText?: string;
  innerColumnCss?: any;
}

class InfinityVerticalScroll extends Component<Props> {
  static defaultProps = {
    offset: 25,
    children: null,
  };

  constructor(props) {
    super(props);
    this.timeout = null;
  }

  timeout: NodeJS.Timeout | null;

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    const { loadMoreOnClick } = this.props;

    if (loadMoreOnClick) {
      return;
    }

    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    this.timeout = setTimeout(() => {
      const visibleArea = window.scrollY + window.innerHeight;
      const toReach = document.body.scrollHeight - this.props.offset;

      if (visibleArea >= toReach) {
        this.props.loadMore();
      }
    }, 100);
  };

  render() {
    const {
      edges,
      renderItem,
      loadMore,
      hasNextPage,
      isLoading,
      loadMoreOnClick,
      column,
      flatItems,
      loadMoreText = null,
      innerColumnCss,
    } = this.props;

    if (!edges || !edges[0]) {
      return null;
    }

    return (
      <OutWrapper>
        {column ? (
          <Col css={innerColumnCss}>
            {edges.map(({ node }, index) => {
              return (
                <div key={`innerColumn_${index}`}>
                  <ItemWrapper flatItems={flatItems}>{renderItem(node)}</ItemWrapper>
                  <Divider />
                </div>
              );
            })}
          </Col>
        ) : (
          <Row type="flex" justify="space-around">
            {edges.map(({ node }, index) => {
              return (
                <ItemWrapper flatItems={flatItems} key={`ItemWrapper_${index}`}>
                  {renderItem(node)}
                </ItemWrapper>
              );
            })}
          </Row>
        )}
        {loadMoreOnClick
          ? hasNextPage && (
              <>
                <Space height={20} />
                <Button onClick={loadMore} loading={isLoading}>
                  {loadMoreText}
                </Button>
              </>
            )
          : isLoading && <Loading />}
      </OutWrapper>
    );
  }
}

export default InfinityVerticalScroll;
