import React from 'react';

import { NotFound } from '@confy/ui-web';

import { useTranslation } from 'react-i18next';

const notFound = [
  {
    name: 'notFound',
    path: '*',
    // TODO - fix this to improve code splitting
    // eslint-disable-next-line react/display-name
    Component: () => {
      const [t] = useTranslation();

      return (
        <NotFound pageNotFoundText={t('common::PageNotFound')} pageDoesNotExistText={t('common::PageDoesNotExist')} />
      );
    },
  },
];

export default notFound;
