const events = {
  About: 'Sobre',
  AbsorbFees: 'Absorver {{percentage}}% de taxa (A taxa de emissão de ingressos é descontada do seu lucro)',
  AddAReply: 'Adicionar uma resposta',
  AddPost: 'Adicionar Post',
  Address: 'Endereço',
  All: 'Todos',
  AllDataAndInformationForThisEventWillBePermanentlyDeleted:
    'Todos os dados e informações para esse evento serão permanentemente deletados.',
  AttendEvent: 'Confirmar presença',
  Attendees: 'Participantes',
  Back: 'Voltar',
  BasicInfos: 'Informações básicas',
  BeforeAttendingWventAnswerQuestionsCreatedByStaff:
    'Antes de confirmar presença no evento, você precisa responder algumas perguntas criadas pela Organização',
  BuyerTotal: 'Total dos compradores: ',
  Cancel: 'Cancelar',
  CancelAttend: 'Cancelar presença',
  ChooseADay: 'Em um dia específico',
  Communication: 'Comunicação',
  Complement: 'Complemento',
  Confirmed: 'Confirmados',
  CoverImage: 'Imagem de capa',
  Create: 'Criar',
  CreateEvent: 'Criar Evento',
  CreateOrg: 'Criar Org',
  CreateTicket: 'Criar ingresso',
  CreatedBy: 'Criado por',
  CustomQuestions: 'Perguntas customizadas',
  Date: 'Data',
  DateAndTime: 'Data e Hora',
  DateFormat: 'Data (mm/dd/yyyy)',
  DateIsRequired: 'Data é obrigatória',
  DeleteEvent: 'Deletar Evento',
  DeleteTicket: 'Deletar ingresso',
  Description: 'Descrição',
  DescriptionIsRequired: 'Descrição é obrigatória',
  Discuss: 'Discussão',
  DoYouWantToDeleteEvent: 'Você quer deletar esse evento?',
  EditTicket: 'Editar ingresso',
  EnableLocation: 'Habilitar localização',
  ErrorAttendingEvent: 'Erro ao participar do evento',
  ErrorCancelingEventAttend: 'Erro ao cancelar a presença no evento',
  ErrorCreatingEvent: 'Erro ao criar evento',
  ErrorCreatingTicket: 'Erro ao criar ingresso',
  ErrorDeletingEvent: 'Erro ao deletar evento',
  ErrorEditingEvent: 'Erro ao editar evento',
  ErrorEditingEventForm: 'Erro ao editar o formulário do evento',
  ErrorEditingEventMedia: 'Erro ao editar as mídias do evento',
  EventQuestions: 'Perguntas do Evento',
  EventWasDeleted: 'Evento foi deletado',
  EventsImInterested: 'Eventos que estou interessado',
  ExportAllParticipantsAsCSV: 'Exportar todos os participantes como .CSV',
  FreeTicket: 'Ingresso grátis',
  GetsVisibleWhenSalesStarts: 'Fica visível quando as vendas iniciarem',
  HasNoEnd: 'Não termina',
  Hidden: 'Invisível',
  HiddenEvenAfterSalesStart: 'Invisível mesmo depois do início das vendas',
  IfNotProvidedDefaultWillBeX: 'Se não informado, será {{x}}',
  InitialInformation: 'Informações iniciais',
  Interested: 'Interessados',
  IsARequiredField: 'é um campo obrigatório',
  IsNotInCorrectFormat: '{{field}} não está no formato correto',
  ItCantBeBiggerThanMaxAttendeesAndTicketsAvailable:
    'Não pode ser maior do que o número de participantes e a quantidade de ingressos disponíveis',
  JoinWaitlist: 'Entrar na lista de espera',
  LeaveWaitlist: 'Sair da lista de espera',
  LimitOfCharacters: 'Limite de {{characters}} caracteres',
  LimitOfXCharacters: 'Limite de {{characters}} caracteres',
  ListAll: 'Listar todos',
  LiveNewExperiencesWithoutLeavingYourCity:
    'Viva novas experiências sem sair da sua cidade! Compartilhe sua localização para que possamos encontrar os melhores eventos próximos de você.',
  ManageEvent: 'Administrar Evento',
  MaxAttendees: 'Máximo de participantes',
  MaxTicketsPerOrder: 'Max ingressos por venda',
  MediaAndSEO: 'Mídia & SEO',
  MinTicketsPerOrder: 'Min ingressos por venda',
  MinimumOfXCharacters: 'Mínimo de {{characters}} caracteres',
  MoneyPrefix: 'R$',
  MustBeAtLeastXCharacters: 'precisa ter pelo menos {{characters}} caracteres',
  Name: 'Nome',
  NameIsRequired: 'Nome é obrigatório',
  NearEvents: 'Eventos Próximos',
  NewComment: 'Novo comentário...',
  NewEvents: 'Novos Eventos',
  OopsPleaseTryAgainLater: 'Oops, por favor tente novamente mais tarde',
  PaidTicket: 'Ingresso pago',
  Participants: 'Participantes',
  PastEvents: 'Eventos passados',
  PopularEvents: 'Eventos Populares',
  Pricing: 'Tarifação',
  Question: 'Pergunta',
  Replies: 'resposta(s)',
  Sales: 'Vendas',
  SalesEnds: 'Término das vendas',
  SalesStarts: 'Início das vendas',
  Save: 'Salvar',
  SearchEvents: 'Encontrar eventos',
  SearchForAnAttendee: 'Procurar por um participante',
  SearchForAttendees: 'Procurar por participantes',
  SelectATicket: 'Selecione um ingresso',
  ShowActions: 'Mostrar ações',
  SoldOut: 'ESGOTADO',
  SoldTotal: 'Vendidos/Total',
  SorryThereIsntEventsNearYouYet: 'Desculpa! Ainda não há eventos perto de você...',
  Status: 'Estado',
  SuccessAttendingEvent: 'Sucesso ao participar do evento',
  SuccessCreatingEvent: 'Sucesso ao criar evento',
  SuccessCreatingTicket: 'Sucesso ao criar ingresso',
  SuccessEditingEvent: 'Sucesso ao editar evento',
  SuccessEditingEventForm: 'Sucesso ao editar o formulário do evento',
  SuccessEditingEventMedia: 'Sucesso ao editar as mídias do evento',
  SuccessOnAddingNewComment: 'Successo ao adicionar novo comentário',
  SuccessOnReplying: 'Sucesso ao responder',
  ThanksForHavingInterest: 'Obrigado por demonstrar interesse',
  TheCurrentDate: 'a data atual',
  TheDateOfTheEvent: 'a data de realização do evento',
  TicketInfos: 'Informações do ingresso',
  TicketPrice: 'Preço do ingresso',
  Tickets: 'Ingressos',
  TimeFormat: 'Hora (24h)',
  TimeIsRequired: 'Hora é obrigatória',
  Title: 'Título',
  TitleIsRequired: 'Título é obrigatório',
  TooShort: 'Muito curto',
  TotalQuantity: 'Quantidade total',
  TotalQuantityIsRequired: 'Quantidade total é obrigatória',
  UpcomingEvents: 'Próximos eventos',
  Visible: 'Visível',
  WhatsHappeningAroundYou: 'O que está acontecendo à sua volta?',
  WhenSalesEndFor: 'Quando as vendas de ... acabarem',
  Where: 'Onde',
  YouCanCreateFirstEventNearYou:
    'Você pode criar o primeiro evento mais próximo ou procurar por eventos com diferentes filtros!',
  YouCancelledEventAttend: 'Você cancelou a presença no evento',
  YouCancelledYourEventInterest: 'Você cancelou seu interesse no evento',
  Yours: 'Seu',
};
export default events;
export type Keys = keyof typeof events;
