import React from 'react';
import styled from 'styled-components';

import minusIcon from '../../assets/minus-icon.png';

const Wrapper = styled.div`
  width: 32px;
  height: 30px;
  border-radius: 30px;
  background-color: ${p => p.theme.danger};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  ${p => p.css};
`;

const Image = styled.img`
  height: 12px;
  object-fit: cover;
`;

const Button = props => {
  return (
    <Wrapper {...props}>
      <Image src={minusIcon} />
    </Wrapper>
  );
};

export default Button;
