/* tslint:disable */
/* @relayHash a1bdce1e69abbe43b8a8a11c34e21369 */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type organization_OrganizationEditBasic_QueryVariables = {
    slug?: string | null;
};
export type organization_OrganizationEditBasic_QueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"OrganizationEditBasic_query">;
};
export type organization_OrganizationEditBasic_Query = {
    readonly response: organization_OrganizationEditBasic_QueryResponse;
    readonly variables: organization_OrganizationEditBasic_QueryVariables;
};



/*
query organization_OrganizationEditBasic_Query(
  $slug: String
) {
  ...OrganizationEditBasic_query_20J5Pl
}

fragment OrganizationEditBasic_query_20J5Pl on Query {
  me {
    id
  }
  organization(slug: $slug) {
    id
    slug
    name
    description
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "slug",
    "type": "String",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "slug"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "organization_OrganizationEditBasic_Query",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "OrganizationEditBasic_query",
        "args": (v1/*: any*/)
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "organization_OrganizationEditBasic_Query",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "organization",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "slug",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "name",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "description",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "organization_OrganizationEditBasic_Query",
    "id": null,
    "text": "query organization_OrganizationEditBasic_Query(\n  $slug: String\n) {\n  ...OrganizationEditBasic_query_20J5Pl\n}\n\nfragment OrganizationEditBasic_query_20J5Pl on Query {\n  me {\n    id\n  }\n  organization(slug: $slug) {\n    id\n    slug\n    name\n    description\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '02922ea92123dc7363d99b4c55b3fe4b';
export default node;
